<template>
  <div>
    <v-dialog
      v-model="dialogpdf"
      hide-overlay
      fullscreen
      transition="dialog-bottom-transition"
      class="dialog-zindex"
      :content-class="isModeIntranetActive ? 'margin-top-max' : 'mt-10'"
    >
      <template #activator="{ on, attrs }">
        <CotizadorBtn
          v-if="CotizadorBtn"
          :bind="attrs"
          :on="on"
        >
          {{ $t("presupuesto.guardar") }}
        </CotizadorBtn>
        <v-btn
          v-else
          class="boton"
          color="#000"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("presupuesto.guardar") }}
        </v-btn>
      </template>
      <!-- <CotizadorBtn v-if="CotizadorBtn" :bind="attrs" :on="on"> {{ $t("presupuesto.guardar") }}</CotizadorBtn> -->
      <v-toolbar
        max-height="40px"
        height="40px"
        dark
        color="primary"
        class="container2"
      >
        <v-btn
          v-if="isModeIntranetActive"
          dark
          @click="dialogpdf = false"
        >
          {{ $t("general.Atras") }}<v-icon>mdi-step-backward</v-icon>
        </v-btn>
        <div
          class="center"
          :class="{ 'ml-15': isModeIntranetActive }"
        >
          <v-switch
            v-model="switch1"
            inset
            dark
            :label="$t('presupuesto.MostrarMisGanancias')"
          />
        </div>
        <v-spacer />
        <!-- <v-toolbar-title class="tituloModal"
                >{{ $t("presupuesto.preview") }}
              </v-toolbar-title>
              <v-spacer></v-spacer> -->
        <v-btn
          class="boton"
          color="#000"
          dark
          @click="generateReport"
        >
          <v-fade-transition>
            <v-overlay
              v-if="cargandoPDF"
              absolute
            >
              <v-progress-circular
                indeterminate
                color="gray"
              />
            </v-overlay>
          </v-fade-transition>
          {{ $t("presupuesto.imprimir") }}
        </v-btn>
      </v-toolbar>
      <div class="maximaAnchura">
        <v-card class="maximaAnchura maxAltura">
          <div class="maxAltura">
            <previewpdf
              id="GeneradorPDFPresupuesto"
              :load="dialogpdf"
              class="maxAltura"
              :espdf="false"
              :title="title"
              :titulostabla1="$t('presupuesto.fichaReprocesos.Referencia')"
              :titulostabla2="$t('presupuesto.fichaReprocesos.Nombre')"
              :titulostabla3="$t('presupuesto.fichaReprocesos.Precio')"
              :titulostabla4="$t('presupuesto.fichaReprocesos.Cantidad')"
              :titulostabla5="$t('presupuesto.fichaReprocesos.Total')"
              :titulostabla6="$t('presupuesto.fichaReprocesos.MiPrecio')"
              :titulostabla7="$t('presupuesto.fichaReprocesos.Coste')"
              :mis-precios="MisPrecios"
              :mostrar-ganancias="switch1"
            />
          </div>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import previewpdf from "./preview.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import CotizadorBtn from "../basic/CotizadorBtn.vue";
import { tryGenerateMergeZones } from "@/utils/fabricUtils.js";
import { noImgUrl } from "@/utils/imageUtils.js";

export default {
  props: {
    marca: {
      type: String,
      default: "ROLY",
      cargando: false,
    },
    MisPrecios: {
      type: Object,
      default: null,
      cargando: false,
    },
    CotizadorBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogpdf: false,
      switch1: false,
      title2: this.$t("presupuesto.titulo2"),
      mssage: "",
      cargandoPDF: false,
      mostrarDescarga: false,
      urlgenerada: "",
    };
  },
  methods: {
    ...mapActions("carrito", ["presupuesto"]),
    imgUrl: function (zonaSelect) {
      let defaultImg = noImgUrl;
      if (
        this.modelInfoFromCatalog &&
        this.modelInfoFromCatalog.image_print &&
        this.carrito &&
        this.colorCode
      ) {
        let zona = this.modelInfoFromCatalog.image_print.find(
          (u) => u.typeInt == zonaSelect.Posicion && this.colorCode == u.color
        );
        if (zona) return zona.url;
      }
      return defaultImg;
    },
    generateReport: async function () {
      this.cargandoPDF = true;
      if (this.carrito.reprocesos.length <= 0) {
        alert(this.$t("presupuesto.Minimo"));
        this.cargandoPDF = false;
        return;
      }
      // Añadimos toda la información que debemos guardar del pedido
      var objetoPedido = new Object();
      // objetoPedido.OrderCode = "";
      // objetoPedido.DesignID = 0;
      // objetoPedido.ClientID = "";
      objetoPedido.Productos = [];
      this.carrito.productos.forEach((element) => {
        var obj = new Object();
        obj.CODE = element.id;
        let name = this.modelPrice?.productTranslation?.find(
          (p) => p.code == obj.CODE
        )?.translate;
        obj.NAME =
          name ??
          this.$i18n.t(`MODELS.${this.carrito.modelo}.NAME`) +
            "+" +
            this.$i18n.t(`SIZES.${element.size}`) +
            "+" +
            this.$i18n.t(`COLORS.${element.color}`);
        obj.quantity = element.cantidad;
        obj.MODEL = this.carrito.modelo;
        // obj.COLOR = element.color;
        // var prodSeleccionado = this.productos.find((e) => {
        //   return e.color == this.colorCode;
        // });
        // obj.FONDO = prodSeleccionado.productos[0].FONDO;
        // obj.FONDO = this.coloresFondo[obj.COLOR];

        // obj.SIZE = element.talla;
        objetoPedido.Productos.push(obj);
      });
      // objetoPedido.prendas = this.totalUnidades;
      // objetoPedido.modelo = this.carrito.modelo;
      // objetoPedido.color = this.colorCode;
      // objetoPedido.incrementoPrendasExactas = false;
      // objetoPedido.entregaExacta = false; //TODO: rellenar
      // objetoPedido.mercanciaCliente = false;
      // objetoPedido.isCustomer = true;
      objetoPedido.Reprocesos = [];
      this.carrito.reprocesos.forEach((element) => {
        var obj = new Object();
        obj.tecnica = element.isExtraCantidadExacta
          ? "Cantidad Exacta"
          : element.tecnica;
        obj.zona = element.zona;
        // obj.parametros = new Object();
        if (!element.isExtraCantidadExacta) {
          if (
            !element.tecnica.includes("Etiquetas") &&
            !element.tecnica.includes("Manipulacion") &&
            !element.tecnica.includes("Planchado")
          ) {
            obj.temporalId = element.id;
          } else {
            obj.temporalId = -1;
          }
        }
        // obj.parametros.pedidorepetido = false;
        obj.params = element.params;
        // obj.lineas = [];
        // obj.modelo = this.carrito.modelo;
        // element.lineas.forEach((linea) => {
        //   var obj1 = new Object();
        //   obj1.referencia = linea.id;
        //   obj1.nombre = linea.nombre;
        //   obj1.cantidad = linea.cantidad;
        //   obj1.precio = linea.precio;
        //   obj1.total = linea.total;
        //   obj.lineas.push(obj1);
        // });
        // obj.total = element.total;
        // var totales = new Object();
        // if (!element.isExtraCantidadExacta && element.tecnica.includes("Etiquetas")) {
        //   totales.data = element.data;
        //   totales.info = element.info;
        //   totales.sap = element.sap;
        // } else if (!element.isExtraCantidadExacta && element.tecnica.includes("Manipulados")) {
        //   totales.data = element.data;
        //   totales.sap = element.sap;
        //   totales.info = new Object();
        // } else {
        //   totales.info = new Object();
        // }

        // if (element.info != null && element.info["esRepeticion"] != null) {
        //   obj.parametros.pedidorepetido = element.info.esRepeticion;
        //   totales.info.esRepeticion = element.info.esRepeticion;
        // }
        // obj.Totales = totales;
        objetoPedido.Reprocesos.push(obj);
      });

      // Información del diseño aplicado
      var objDesign = new Object();
      objDesign.listDesign = [];
      this.filteredListDesignInCart.forEach((design) => {
        let actualDesign = {};

        actualDesign.temporalId = design.id;
        actualDesign.zone = design.zona;
        actualDesign.posicion = design.posicion;

        // Si solo tenemos una imagen, creamos el array, y guardamos la única imagen, sino, guardamos el array de imágenes
        actualDesign.design = Array.isArray(design.design)
          ? design.design.map((design, index) => {
              return { index, design };
            })
          : [{ index: 0, design: design.design }];

        // Debemos parametrizar el json, para luego sustituir la imagen en base64 por su url cuando se haya subido la(s) imagen(es)
        let resultJson = JSON.stringify(design.canvasJson);
        actualDesign.design.forEach((design) => {
          // SI es una url no debemos hacer nada
          try {
            new URL(design.design);
          } catch (e) {
            resultJson = resultJson.replace(
              design.design,
              `[IMAGE_REPROCESO_${actualDesign.temporalId}_${design.index}]`
            );
          }
        });
        actualDesign.json = resultJson;

        // Guardamos el svg como preview
        actualDesign.preview = design.svg;

        // Guardamos las observaciones que se han puesto en la zona
        actualDesign.observation = design.observaciones;

        // Guardamos la personalización
        objDesign.listDesign.push(actualDesign);
      });
      // objDesign.name = "REPROCESO ";
      // objDesign.model = this.carrito.modelo;
      // objDesign.color = this.colorCode;
      // objDesign.observation = this.savedDesign != null && this.savedDesign != "" && !this.savedDesign.startsWith("data:image") ? this.savedDesign : "";
      // objDesign.preview = this.savedDesign != null && this.savedDesign.startsWith("data:image") ? this.savedDesign : null;
      objDesign.preview = await tryGenerateMergeZones(
        this.numDesignForEachPosition,
        this.numDesignForEachPreviewRule,
        this.filteredListDesignInCart
      );

      if (this.previewGlobalDesign.hasZoneDesign || this.previewGlobalDesign.hasPreviewRule)
      {
        objDesign.zonaPreview = this.previewGlobalDesign.zones[0].Zona;
        objDesign.previewRule = this.previewGlobalDesign.zones[0].PreviewRule;
      }
      objDesign.colorPreview = this.colorSelected;
      

      let printConfiguration = new FormData();
      printConfiguration.append("OrderForm", JSON.stringify(objetoPedido));
      printConfiguration.append("NewDesignDataForm", JSON.stringify(objDesign));
      printConfiguration.append(
        "ModificationForm",
        JSON.stringify(this.MisPrecios)
      );

      await this.presupuesto(printConfiguration).catch(() => {
        alert("error occured");
        this.cargandoPDF = false;
        return;
      });
      // await this.downloadWithAxios(response.data.URL);
      this.cargandoPDF = false;
    },
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "presupuesto.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    async downloadWithAxios(response) {
      var reintentar = true;
      var intentos = 0;
      this.urlgenerada = response;
      while (reintentar && intentos < 10) {
        axios({
          method: "get",
          url: response,
          responseType: "arraybuffer",
        })
          .then((response) => {
            this.forceFileDownload(response);
            reintentar = false;
          })
          .catch(() => {
            reintentar = true;
          });
        await this.sleep(1000);
        intentos++;
      }
      if (intentos >= 10) this.mostrarDescarga = true;
    },
    async sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    getPrimeraZona() {
      let defaultImg = noImgUrl;
      if (this.carrito && this.colorCode) {
        let colores = this.modelInfoFromCatalog.image_views.find(
          (u) => u.color == this.colorCode
        );
        if (colores) {
          defaultImg = colores.url;
        }
      }
      return defaultImg;
    },
    cierraAlerta: function () {
      this.mostrarDescarga = false;
    },
    titleGanancias: function () {
      return this.title + " " + this.title2;
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setTextColor(150);
            pdf.text(
              "Page " + i + " of " + totalPages,
              pdf.internal.pageSize.getWidth() * 1,
              pdf.internal.pageSize.getHeight() - 0
            );
          }
        })
        .save();
    },
  },
  computed: {
    ...mapGetters("modelo", [
      "codigosModelos",
      "modelPrice",
      "modelInfoFromCatalog",
      "zonas",
    ]),
    ...mapGetters("carrito", [
      "carrito",
      "colorCode",
      "savedDesign",
      "filteredListDesignInCart",
      "numDesignForEachPosition",
      "numDesignForEachPreviewRule",
      "previewGlobalDesign"
    ]),
    ...mapGetters("config", ["key", "isModeIntranetActive", "colorSelected"]),
    title: function () {
      return (
        this.$t("presupuesto.titulo") +
        " " +
        this.$i18n.t(`MODELS.${this.carrito.modelo}.NAME`)
      );
    },
    titledoc: function () {
      return (
        this.$t("presupuesto.fichaReprocesos.titulop1") +
        this.$t("presupuesto.titulo") +
        " " +
        this.$i18n.t(`MODELS.${this.carrito.modelo}.NAME`)
      );
    },
  },

  components: {
    previewpdf,
    CotizadorBtn,
  },
};
</script>
<style>
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 5%;
  -ms-transform: translate(-0%, -20%);
  transform: translate(-0%, -20%);
}
.container2 {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 10;
}
.maximaAnchura {
  width: 100% !important;
}
.maxAltura {
  height: 100% !important;
}
.dialog-zindex {
  z-index: 1000;
}
.tituloModal {
  font-size: 34px !important;
}
.boton {
  font-size: 1rem !important;
  max-height: 30px !important;
}

.margin-top-max {
  margin-top:  83px;

}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
