<template>
  <div>
    <v-fade-transition>
      <v-overlay v-if="cargando" absolute>
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>

    <v-row class="py-1">
      <v-col cols="12" md="9" sm="12">
          <v-row class="text-h4">
            <v-col cols="12" class="d-flex flex-wrap">
              <Productos
                :modelo="carrito.modelo"
                :color="color"
                :cantidad-inicial="cantidad"
              />
              <ReprocesosGenerales
               :disabled="
                 !( carrito != null &&
                    carrito.modelo != null)
                "
                :modelo="carrito.modelo"
                :prendas="carrito.cantidad"
              ></ReprocesosGenerales>
            </v-col>
          </v-row>
          <!-- Colores -->
          <v-row>
            <v-col cols="12" class="d-flex">
              <div  class="color-list d-flex justify-start flex-wrap ml-2 mt-1">
                  <ColorBall v-for="c in sortColorAvalibleAndWithQuantity" :key="c.code" circle-class="" :hexcode="c.url ? c.url : c.hexcode" :quantity="c.quantity" tooltip :selected="color && color.code && color.code == c.code" :label="c.code" @changeColor="selectColor(c.code)">
                    <span><u>{{ $t(`COLORS.${c.code}`).toUpperCase() }}</u></span>
                    <div v-for="sizeQuantity in quantityByColorAndProduct[c.code]" :key="sizeQuantity.size">
                      <span>{{ $t(`SIZES.${sizeQuantity.size}`) }}: {{ sizeQuantity.quantity }}</span>
                    </div>
                </ColorBall>
              </div>
            </v-col>
          </v-row>

      </v-col>
      <v-col cols="12" sm="3" class="d-flex align-end"> </v-col>
    </v-row>
    <v-row class="py-1 mt-1">
      <!-- Zonas, selección de técnicas, etc para calcular los precios con la API -->
      <v-col cols="12" md="9" sm="12">
        <div>
          <Zonas
            :cantidad="cantidad"
            :cantidad-carrito="carrito.cantidad"
            :color="color"
          ></Zonas>
        </div>
      </v-col>
      <!-- End Zonas -->
      <!-- Resumen, todos sus datos vienen del store, realmente el prop no se usa -->
      <v-col cols="12" md="3" sm="12">
        <Cotizacion id="carrito" :cantidad="cantidad" @reload="reiniciar"></Cotizacion>
      </v-col>
      <!-- End Resumen -->
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Cotizacion from "../components/cotizadorClientes/Cotizacion.vue";
import Zonas from "../components/cotizadorClientes/Zonas.vue";
import Productos from "../components/cotizadorClientes/Productos.vue";
import ColorBall from "../components/basic/ColorBall.vue"
import ReprocesosGenerales from "../components/tecnicas/ReprocesosGenerales.vue";
import { isEmpty } from "lodash"

export default {
  name: "Cotizador",
  components: {
    Cotizacion,
    Zonas,
    Productos,
    ColorBall,
    ReprocesosGenerales
  },
  props: {
    modeloId: {
      type: String,
      default: "",
      cargando: false,
    },
  },
  data() {
    return {
      cargando: false,
      cantidad: 200,
      zona: "",
      tecnica: "",
      color: null,
      modeloSeleccionado: "default",
      articulos: [
        {
          id: 1,
          name: "Pecho - Serigrafia: 100€",
          children: [
            { id: 2, name: "Coste uni : app" },
            { id: 3, name: "Chrome : app" },
            { id: 4, name: "Webstorm : app" },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters("modelo", ["colorAvalible", "isReprocesosStm"]),
    ...mapGetters("carrito", ["carrito", "quantityByColor", "quantityByColorAndProduct", "sortColorAvalibleAndWithQuantity"]),
    ...mapGetters("config", ["key", "colorSelected"]),
  },
  methods: {
    reiniciar() {
      this.$emit("reload");
    },
    getHexcodeByColorcode(colorcode) {
      let color = this.colorAvalible.find((c) => c.code === colorcode);
      if (color) return color.hexcode ? color.hexcode : color.url;
      else return null;
    },
    selectColor(colorCode)
    {
      let color = this.colorAvalible.find((c) => c.code === colorCode);
      this.color = color;
      this.setColor(colorCode);
    },
    ...mapActions("carrito", ["restoreCarrito"]),
    ...mapActions("config", ["setColor"]),
  },
  watch: {
    colorSelected: function (newValue, oldVal) {
      if (newValue == oldVal) return;

      if (newValue) {
        this.color = this.colorAvalible.find((c) => c.code === newValue);
      }
    },
    sortColorAvalibleAndWithQuantity: function (newValue) {
      if (newValue.length > 0 && (!this.color?.CODE || !newValue.some(c => c.code == this.color.CODE) ) )
      {
        this.color = newValue[0]
        this.selectColor(this.color.code);
      }
    },
    quantityByColor: function(newModelo, oldModelo)
    {
      // Si oldModelo era falso, o vacío y newModelo tiene algo, es que se ha cargado el modelo por primera vez
      if ((!oldModelo || isEmpty(oldModelo) || this.color == null) && !isEmpty(newModelo)) {
        if (this.sortColorAvalibleAndWithQuantity.length > 0) {
          this.color = this.sortColorAvalibleAndWithQuantity[0]
          this.selectColor(this.color.code);
        }
      }
    }
  },
};
</script>

<style scoped>
/*.color-selected {
  border: 3px solid #00796b;
  border-radius: 15px;  
  width: 30px;
}*/
#boton-reprocesos {
  margin-top: 0%;
}
#carrito {
  margin-top: -10px;
}
</style>
