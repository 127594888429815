<template>
  <v-tooltip :disabled="!tooltip" bottom>
    <template #activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="circle-selector-parent"
        :class="{ 'disabled-color': disabled }"
        v-on="on"
      >
        <div
          class="colorBall color-img-container"
          :class="{ 'selected-color': selected }"
          @click="changeColor()"
        >
          <!-- En caso de que el color no sea un color, sino una imagen -->
          <div
            v-if="colorImageURL"
            class="color-img"
            :class="{
              white: colorList[0] == '#FFFFFF',
              'white-ball': whiteBorder
            }"
            :style="`background-image: url('${colorImageURL}'); background-size: cover; width: ${squareWidth}px; height: ${squareHeight}px;`"
          >
            <span
              class="spanColor"
              :style="{
                color: pickTextColorBasedOnBgColorSimple,
                ' font-size': `${squareWidth * 0.6}px`
              }"
            ></span>
          </div>
          <div
            v-else-if="colorList.length === 1"
            class="colorSquare filtro-colores animsition-link"
            href="javascript:;"
            :style="`display:block;background-color:${colorList[0]}; width: ${squareWidth}px; height: ${squareHeight}px;`"
            :class="[circleClass, whiteBorder ? 'white-ball' : undefined]"
          >
            <span
              class="spanColor"
              :style="{
                color: pickTextColorBasedOnBgColorSimple,
                ' font-size': `${squareWidth * 0.6}px`,
                'pointer-events': 'none'
              }"
            ></span>
          </div>
          <div
            v-else-if="colorList.length === 2"
            class="colorSquare multColorSquare filtro-colores animsition-link"
            href="javascript:;"
            :style="`display:block;background: linear-gradient(-45deg, ${colorList[1]} 0%, ${colorList[1]} 49%,${colorList[0]} 50%,${colorList[0]} 100% ); width: ${squareWidth}px; height: ${squareHeight}px; clip-path: circle(50%);`"
            :class="[circleClass, whiteBorder ? 'white-ball' : undefined]"
          >
            <span
              class="spanColor"
              :style="{
                color: pickTextColorBasedOnBgColorSimple,
                ' font-size': `${squareWidth * 0.6}px`,
                'pointer-events': 'none'
              }"
            ></span>
          </div>
          <div
            v-else-if="colorList.length === 3"
            class="colorSquare multColorSquare filtro-colores animsition-link"
            href="javascript:;"
            :style="`display:block;background: linear-gradient(-45deg, ${colorList[2]} 0%, ${colorList[2]} 32%,${colorList[1]} 33%, ${colorList[1]} 65%, ${colorList[0]} 66%,${colorList[0]} 100% ); width: ${squareWidth}px; height: ${squareHeight}px; clip-path: circle(50%);`"
            :class="[circleClass, whiteBorder ? 'white-ball' : undefined]"
          >
            <span
              class="spanColor"
              :style="{
                color: pickTextColorBasedOnBgColorSimple,
                ' font-size': `${squareWidth * 0.6}px`,
                'pointer-events': 'none'
              }"
            ></span>
          </div>
        </div>
        <div v-if="label" class="circle-label">
          <span>{{ label }}</span>
        </div>
        <span
          v-if="quantity"
          :key="`color ${quantity}`"
          class="circle-selector-tag"
        >
          {{ quantity > 99999 ? "99.999+" : quantity }}
        </span>
      </div>
    </template>
    <slot></slot>
  </v-tooltip>
</template>
<script>
export default {
  name: "ColorBall",
  props: {
    hexcode: String,
    squareWidth: {
      type: Number,
      default: 30
    },
    squareHeight: {
      type: Number,
      default: 30
    },
    circleClass: {
      type: String,
      default: ""
    },
    selected: {
      type: Boolean,
      default: false
    },
    quantity: {
      type: Number,
      default: 0
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledMessage: {
      type: String,
      default: ""
    }
  },
  computed: {
    ballStyle() {
      return {
        "background-color": this.hexcode
      };
    },
    colorList() {
      return this.hexcode
        ? this.hexcode.split("/").map(color => {
            return color === "#" ? "#000" : color;
          })
        : ["#FFFFFF"];
    },
    colorImageURL() {
      if (!this.hexcode.includes("#")) {
        return process.env.VUE_APP_STATIC_IMG_URL + this.hexcode;
      } else return null;
    },
    pickTextColorBasedOnBgColorSimple() {
      var color =
        this.hexcode.charAt(0) === "#"
          ? this.hexcode.substring(1, 7)
          : this.hexcode;
      var r = parseInt(color.substring(0, 2), 16); // hexToR
      var g = parseInt(color.substring(2, 4), 16); // hexToG
      var b = parseInt(color.substring(4, 6), 16); // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000" : "#fff";
    },
    whiteBorder() {
      return this.colorList.some(
        c =>
          c.includes("#FFFFFF") ||
          this.colorList.some(c => c.includes("blanco.PNG"))
      );
    }
  },
  methods: {
    changeColor() {
      if (!this.disabled) this.$emit("changeColor");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.span {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin: 2px;
}
.color-img {
  position: relative;
  object-fit: cover;
  border-radius: 100%;
  /* border: 1px solid transparent; */
  cursor: pointer;
}

.color-img-container {
  width: 42px;
  height: 100%;
}

.colorSquare {
  display: block;
  position: relative;
  border-radius: 50% !important;
  border: 0 !important;
  cursor: pointer;
}

.spanColor {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translateY(-50%) translateX(-50%);
  color: #fff;
  display: none;
  overflow: visible;
  pointer-events: none;
  background: none !important;
}

.colorBall {
  padding-right: 5px;
  padding-left: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
}

.white-ball {
  border: 1px solid #666666 !important;
}

.selected-color {
  border: 1px solid #222 !important;
  border-radius: 50%;
}

.circle-selector-tag {
  padding: 0px 2px;
  color: white;
  background-color: #878686;
  font-size: 9px;
  position: absolute;
  left: 0px;
  top: -3px;
  line-height: 1.6;
}

.circle-selector-parent {
  position: relative;
  cursor: pointer;
  max-height: 56px;
}

.circle-label {
  font-size: 11px;
  text-align: center;
  width: 100%;
  max-height: 16px;
  display: block;
  padding-bottom: 10px;
  line-height: initial;
  margin-top: 4px;
}

.disabled-color {
  opacity: 0.5;
  cursor: not-allowed !important;

  .circle-selector-parent,
  .color-img,
  .colorSquare {
    cursor: not-allowed !important;
  }
}
</style>
