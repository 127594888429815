<template>
    <div>
        <v-fade-transition>
            <v-overlay v-if="cargando" absolute>
                <v-progress-circular
                    indeterminate
                    color="gray"
                ></v-progress-circular>
            </v-overlay>
        </v-fade-transition>
        <div>

            <GenericCheck v-model="numerosDobles" :checkbox-label="$t(`configuracion.Numeros.NumDobles`)"></GenericCheck>
        </div>
        <div>
            <v-select
                v-model="selectedOption"
                hide-details="true"
                :items="filteredOptions"
                item-text="label"
                item-value="value"
                filled
                color="primary darken-2"
                prepend-inner-icon="mdi-tape-measure"
                :label="$t('general.Tamaño')"
            ></v-select>
        </div>
        <div>
            <v-checkbox
                v-model="especificarDiseno"
                hide-details="true"
                :label="$t(`configuracion.NumerosDtf.DisenoEspecial`)"
            ></v-checkbox>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GenericCheck from "../basic/GenericCheck.vue";

export default {
    components: {
        GenericCheck
    },
    props: {
        zona: {
            type: Object,
            required: true
        },
        id: Number
    },
    data() {
        return {
            cargando: false,
            selectedOption: null,
            options: [],
            especificarDiseno: false,
            numerosDobles: true
        };
    },
    created() {
        this.fetchOptions();
    },
    computed: {
        ...mapGetters("carrito", [
            "medidasMaximas",
            "cartHasProducts",
            "totalUnidades"
        ]),
        ...mapGetters("modelo", ["modelCode"]),
        actualMaxValidSize() {
            if (this.cartHasProduct == 0) return null;

            let maxValue = null;
            this.filteredOptions.forEach(tam => {
                let isPrimerValor = !maxValue;

                let firstValueOrIsBigger =
                    isPrimerValor ||
                    (tam.Ancho >= maxValue.Ancho && tam.Alto >= maxValue.Alto);

                let numExcededSize = this.medidasMaximas(
                    this.zona.Posicion,
                    tam.Ancho,
                    tam.Alto
                );

                if (firstValueOrIsBigger && numExcededSize.length == 0)
                    maxValue = tam;
            });

            return maxValue;
        },
        filteredOptions() {
            if (this.numerosDobles) {
                return this.options.filter(option => option.isDobleNumero);
            } else {
                return this.options.filter(option => !option.isDobleNumero);
            }
        },
        ancho() {
            const selectedOptionAncho = this.filteredOptions.find(
                option => option.value === this.selectedOption
            )?.Ancho;
            return selectedOptionAncho || 0;
        },
        alto() {
            const selectedOptionAlto = this.filteredOptions.find(
                option => option.value === this.selectedOption
            )?.Alto;
            return selectedOptionAlto || 0;
        }
    },
    watch: {
        zona: function () {
            this.calcularPrecio();
        },
        numerosDobles: function () {
            if (this.filteredOptions.length > 0) {
                this.selectedOption = this.filteredOptions[0].value;
            }
            this.calcularPrecio();
        },
        especificarDiseno: function () {
            this.calcularPrecio();
        },
        totalUnidades: function () {
            this.calcularPrecio();
        },
        selectedOption: function () {
            this.calcularPrecio();
        }
    },
    methods: {
        ...mapActions("modelo", ["fetchSizeNumerosDtf", "addAlert"]),
        ...mapActions("carrito", ["calcularPrecios"]),
        async fetchOptions() {
            this.cargando = true;
            try {
                const options = await this.fetchSizeNumerosDtf();
                this.options = options.data.map(option => ({
                    value: option.code,
                    Ancho: option.ancho,
                    Alto: option.alto,
                    isDobleNumero: option.doble,
                    label: this.$t(`configuracion.NumerosDtf.${option.code}`),
                    minimum: option.minUnit
                }));
                this.selectedOption = this.actualMaxValidSize
                    ? this.actualMaxValidSize.value
                    : this.filteredOptions[0].value;
            } catch (error) {
                console.error(error);
            }
            this.cargando = false;
        },
        async calcularPrecio() {
            if (this.cartHasProduct == 0) return;
            var excedido = this.medidasMaximas(
                this.zona.Posicion,
                this.ancho,
                this.alto
            );

            if (excedido.length > 0) {
                this.addAlert({
                    title:
                        this.$t("alert.errorTamEstampacionTitleDialog") +
                        " (" +
                        this.$t("Tecnicas.NUMEROS-DTF") +
                        ")",
                    listProduct: excedido,
                    code: "errorTamEstampacion",
                    tamEstX: this.ancho,
                    tamEstY: this.alto,
                    zone: this.zona,
                    tamEstampacion: this.tamaño
                });
            }

            // Comprobamos si cumple las unidades mínimas
            // Buscamos en las opciones el valor seleccionado
            let option = this.options.find(
                option => option.value === this.selectedOption
            );
            if (option.minimum > this.totalUnidades) {
                this.addAlert({
                    code: "errorMin",
                    minUnit: option.minimum ?? 0
                });
                return;
            }

            let parametros = {
                tecnica: "DTFNumeros",
                codigo: this.modelCode,
                posicion: this.zona.Posicion,
                zona: this.zona.Zona,
                unidades: this.totalUnidades,
                isRepeticion: this.repeticion,

                atributos: JSON.stringify([
                    {
                        esPackUno: this.id == 0 ? this.hasPackUno : false,
                        isProveedor: this.isProveedor,
                        tamanoEstampadoNumerosDTF: this.selectedOption,
                        maxWidth: this.ancho,
                        maxHeight: this.alto,
                        disenoEspecial: this.especificarDiseno
                    }
                ]),

                id: this.id
            };
            this.cargando = true;
            try {
                await this.calcularPrecios(parametros);
            } catch (error) {
                if(error != 'errorColorBanned')
          {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
            }

            this.cargando = false;
        }
    }
};
</script>
