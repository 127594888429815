export default {
  carrito: {
    modelo: "",
    color: null,
    colorCode: '',
    reprocesos: [],
    lineas: {

    },
    productos: [
    ],
    cantidad: 0,
    total: 0.0,
    nreprocesos: [],
  },
  loading: 0,
  sobrecosteCantidadExacta: 0,
  paramsCantidadExacta: null,
  calcularPlanchado: [],
  comercialCart: [],
  clientCart: [],
  completeCart: '',
  lastCart: [],
  savedDesign: null,
  actualDesign: [
    // {
    //   id: 1,
    //   tecnica: "SerigrafiaStm",
    //   zona: "FRONTAL",
    //   posicion: 1,
    //   design: "base64",
    //   observaciones: "",
    //   svg: "",
    //   canvasJson: ""
    // }
  ],
  bocetosRepeticion: [
    // {
      // id: identificador zona,
      // value: fichero pdf
    // }
  ],
  shippingAddress: []
};
