<template>
  <div>
    <v-fade-transition>
      <v-overlay v-if="cargando" absolute>
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
    <v-row v-if="!tieneSublimacionParcial && !tieneSublimacionTotal">
      <v-col
        v-if="!tieneSublimacionParcial && !tieneSublimacionTotal"
        cols="12"
        class="py-0"
      >
      <v-alert
        v-if="!isBlanco"
        border="top"
        elevation="8"
        text
        type="error"
        dense
      >
        {{$t("configuracion.Sublimacion.sublimacionBlanco")}}
        </v-alert>
        </v-col
      >
    </v-row>
    <v-row v-else>
      <v-col>
        <v-alert
        v-if="!isBlanco"
        class="mx-5"
        border="top"
        color="red"
        elevation="8"
        text
        type="error"
        dense
      >{{$t("alert.alertSublimacionZonaNoBlanca")}}</v-alert>
      <v-row
        v-if="isSublimacionSoloBlanco"
        cols="12"
        class="py-0"
      >
        <v-col>
          <v-alert
          border="bottom"
            elevation="8"
            text
            type="warning"
            dense
        >
          {{$t("configuracion.Sublimacion.sublimacionSoloBlanco")}}
        </v-alert>
        </v-col>
        </v-row
      >
      <v-row v-if="tieneSublimacionParcial" cols="12">
        <v-col class="py-0">
          <generic-check v-model="parcial" :checkbox-label="$t('configuracion.Sublimacion.parcial')" :disabled="parcial" icon="mdi-information" :tooltip-text="$t('configuracion.Sublimacion.info tamaño estampado')" class-span="mb-0"/>
        </v-col>
      </v-row>
      <v-row v-if="tieneSublimacionTotal" cols="12" class="py-0">
        <v-col class="py-0">
          <generic-check v-model="total" :checkbox-label="$t('configuracion.Sublimacion.total')" :disabled="total" class-span="mb-0"/>
        </v-col>
      </v-row>
      <v-row v-if="total" cols="12" class="py-0">
        <v-col class="py-0">
          <v-select
          v-model="numcaras"
          :items="caras"
          filled
          :label="$t('configuracion.Sublimacion.Nº de caras')"
          :hint="$t('configuracion.Sublimacion.hintcaras')"
          persistent-hint
          color="primary darken-2"
          prepend-inner-icon="mdi-asterisk"
          hide-details="true"
        >
        </v-select>
        </v-col>
      </v-row>
      <v-row v-if="parcial" cols="12" class="py-0">
        <v-col>
          <span class="text-subtitle-2">{{
          $t("configuracion.Seleccione un tamaño de la lista")
        }}</span>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{
            $t(
              "configuracion.El tamaño máximo para esta combinación de tallas es"
            ) + ": " +(actualMaxValidSize ? actualMaxValidSize.text : $t("configuracion.No hay tamaños válidos"))
          }}</span>
        </v-tooltip>
        <v-select
          v-model="tamano"
          :items="listaTamaños"
          filled
          :label="$t('configuracion.Sublimacion.Tamaño estampado')"
          color="primary darken-2"
          prepend-inner-icon="mdi-format-size"
          hide-details="true"
          @change="actualGetMedidasByTamano"
        >
        </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="parcial" cols="12" class="py-0">
          <v-row>
            <v-col cols="6">
            <v-text-field
              v-model="ancho"
              :label="$t('configuracion.Sublimacion.Ancho')"
              hide-details="true"
              type="number"
              color="primary darken-2"
              min="1"
              filled
              @change="getTamanoByMedida"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="alto"
              :label="$t('configuracion.Sublimacion.Alto')"
              hide-details="true"
              type="number"
              color="primary darken-2"
              min="1"
              filled
              @change="getTamanoByMedida"
            ></v-text-field>
          </v-col>
          </v-row>
        </v-col>
      </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GenericCheck from "../basic/GenericCheck.vue";

export default {
  name: "Sublimacion",
  components: {
    GenericCheck,
  },
  props: {
    color: Object,
    cantidad: Number,
    zona: Object,
    id: Number
  },
  data() {
    return {
      parcial: false,
      total: false,
      tamano: "",
      ancho: 0,
      alto: 0,
      listaTamaños: [],
      numcaras: 1,
      cargando: false,
      debounceCalcularPrecio: null,
    };
  },
  async created() {
    await this.obtenerTamanos();
    if (this.modelInfoFromCatalog.code == "BR9004" || this.tipoPrendaSublimacion == 8) {
      this.numcaras = 2;
    }
    if (this.modelInfoFromCatalog.code == "CP9001") this.numcaras = 1;
    this.parcial = this.tieneSublimacionParcial;
    this.total = this.tieneSublimacionTotal;
    this.tamano = this.actualMaxValidSize?.text ?? "A8";
    this.ancho = this.actualMaxValidSize?.Ancho;
    this.alto = this.actualMaxValidSize?.Alto;
    await this.calcularPrecioActual();
  },
  computed: {
    ...mapGetters("carrito", ["carrito", "hasPackUno", "medidasMaximas"]),
    ...mapGetters("modelo", ["key", "zonesFondoByZone", "sublimacionStmInfo", 'modelPrice', "maxValidSize", "tipoPrendaSublimacion", "modelInfoFromCatalog"]),
    ...mapGetters("config", ["isProveedor", "getMedidasByTamano", "allSizes", "key"]),
    caras() {
      if (this.modelInfoFromCatalog.code == "CP9001" ) return [{ value: 1, text: 1 }];
      else return [{ value: 2, text: 2 }];
    },
    tieneSublimacionTotal: function () {
      return this.modelInfoFromCatalog.code == "BR9004" || this.modelInfoFromCatalog.code == "CP9001" || this.tipoPrendaSublimacion == 8;
    },
    actualFondo()
    {
      return this.zonesFondoByZone(this.zona.Posicion)?.AvalibleColors;
    },
    isBlanco()
    {
      let fondoBlanco = false;
      const soloBlancoEnCarrito = this.carrito.productos.length >= 1 && this.carrito.productos.every(p => p.color == "01");

      if (soloBlancoEnCarrito)
      {
        // Si solo hay blanco en el carrito no es necesario mirar más
        fondoBlanco = true;
      }
      else if (this.actualFondo)
      {
        // Si no todos los productos son blancos, buscamos si tiene definido un fondo especial
        fondoBlanco = this.carrito.productos.length >= 1 && this.carrito.productos.every(p => {
          if (p.color == "01") return true;

          let actualFondoProduct = this.actualFondo.find(c => c.Color == p.color);
          return actualFondoProduct && actualFondoProduct.isBlanco;
        });
      }

      return fondoBlanco;
    },
    tieneSublimacionParcial: function () {
      if (this.zona) {
        let infoZonasFondo = this.zonesFondoByZone(this.zona.Posicion);

        let hasSublimacionByColorAndZone =
          infoZonasFondo &&
          infoZonasFondo.AvalibleColors &&
          infoZonasFondo.AvalibleColors.length > 0;

        return (
            !this.tieneSublimacionTotal &&
            (this.carrito.productos.length >= 1 && this.carrito.productos.some(p =>  p.color == "01" || (hasSublimacionByColorAndZone && (infoZonasFondo.AvalibleColors.find(c => c.Color == p.color)?.isBlanco ?? false))))
          );
      }
          

      return (
        !this.tieneSublimacionTotal
      );
    },
    numPredasBlancas() {
      return this.carrito.productos.reduce((total, p) => p.color == "01" || (this.zonesFondoByZone(this.zona.Posicion)?.AvalibleColors.find(c => c.Color == p.color)?.isBlanco ?? false) ? total + Number.parseInt(p.cantidad) : total, 0);
    },
    isSublimacionSoloBlanco() {
      if (!this.tieneSublimacionParcial && !this.tieneSublimacionTotal) return false;

      let sublimacionInfo = this.sublimacionStmInfo(this.zona.Posicion);

      if (!sublimacionInfo) return false;

      return sublimacionInfo?.SoloBlanco ?? false;
    },
    actualMaxValidSize() {
      if (this.carrito.productos.length == 0) return null;
      let listTamText = this.listaTamaños.map(t => t.value);
      let customSizes = Object.keys(this.allSizes).filter(s => listTamText.includes(s)).map(s => {
        return {
          ...this.allSizes[s],
          text: s
        }
      })
      let result = this.maxValidSize(customSizes, this.zona.Posicion);
      return result
    }
  },
  methods: {
    ...mapActions("carrito", [
      "calcularPrecios",
      "deleteReproceso"
    ]),
    ...mapActions("modelo", ["gettamanosSublimacion", "getTamanoByMedidas", 'addAlert']),
    calcularPrecioActual: async function () {
      if (this.cantidad == 0 || this.tamano == 1) return;

      var excedido = this.medidasMaximas(this.zona.Posicion, this.ancho, this.alto); // Revisar aviso 
      // var excedido = [];
      if (excedido.length>0) {
        this.addAlert(
          { 
            title: this.$t("alert.errorTamEstampacionTitleDialog") + ' (' + this.$t("Tecnicas.SUBLIMACIÓN") + ")",
            listProduct: excedido,
            code: "errorTamEstampacion",
            tamEstX: this.ancho,
            tamEstY: this.alto,
            zone: this.zona,
            tamEstampacion: this.tamano
          }
        );
      }

      if (this.debounceCalcularPrecio) {
        clearTimeout(this.debounceCalcularPrecio);
      }

      this.debounceCalcularPrecio = setTimeout(async () => {
        this.cargando = true;
        let parametros = {
          // tecnica: "Sublimacion",
          posicion: this.zona.Posicion,
          zona: this.zona.Zona,
          codigo: this.modelInfoFromCatalog.code,
          isRepeticion: false,
          unidades: this.numPredasBlancas,
          id: this.id
        };
        if (this.parcial) {
          parametros = {
            tecnica: "SublimacionParcial",
            atributos: JSON.stringify([{
              tamano: this.tamano,
              maxWidth: this.ancho,
              maxHeight: this.alto,
              esPrendaPackUno: (this.id == 0 ? this.hasPackUno : false),
              isProveedor: this.isProveedor,
            }]),
            ...parametros
          };
          try {
            await this.calcularPrecios(parametros);
          } catch (error) {
            if(error != 'errorColorBanned')
          {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
          }
        } else if (this.total) {
          parametros = {
            tecnica: "SublimacionTotal",
            atributos: JSON.stringify([{
              caras: this.numcaras,
              esPrendaPackUno: (this.id == 0 ? this.hasPackUno : false),
              isProveedor: this.isProveedor
            }]),
            ...parametros
          };
          try {
            await this.calcularPrecios(parametros);
          } catch (error) {
            if(error != 'errorColorBanned')
          {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
          }
        }
        this.cargando = false;
      }, 1000);
    },
    actualGetMedidasByTamano: function () {
      let tamano = this.listaTamaños.find((x) => x.value == this.tamano).text;
      var result = this.getMedidasByTamano(tamano)
      this.ancho = result.Ancho;
      this.alto = result.Alto;
    },
    // getTamanoByMedida: async function () {
    //   var param = {
    //     tecnica: 5,
    //     ancho: this.ancho,
    //     alto: this.alto,
    //     k: this.key,
    //   };
    //   var tam = await this.getTamanoByMedidas(param);
    //   if (tam.data && tam.data.status && this.tamano != tam.data.data.Nombre) {
    //     this.tamano = tam.data.data.Nombre;
    //     this.calcularPrecioActual();
    //   } else {
    //     alert(
    //       this.$t(
    //         "configuracion.ImpresionVinilo.Las medidas son superiores a la zona de marcado"
    //       )
    //     );
    //   }
    // },
    getTamanoByMedida() {
      var valido = true;
      var mejorMedida = null;
      var menorArea = null;
      this.listaTamaños.forEach((elem) => {
        let element = this.getMedidasByTamano(elem.value);
        if (element.Ancho < this.ancho || element.Alto < this.alto) valido;
        else {
          valido = true;
          let area = element.Ancho * element.Alto;
          if (mejorMedida == null) {
            mejorMedida = elem.value;
            menorArea = area;
          } else if (area < menorArea) {
            mejorMedida = elem.value;
            menorArea = area;
          }
        }
      });
      if (!valido) alert(
          this.$t(
            "configuracion.ImpresionVinilo.Las medidas son superiores a la zona de marcado"
          )
        );
      else {
        this.tamano = mejorMedida;
      }
    },
    obtenerTamanos: async function () {
      var tamanos = await this.gettamanosSublimacion({
        m: this.modelInfoFromCatalog.code,
      });
      this.listaTamaños = [];
      tamanos.data.tamanossublimacion.forEach((element) => {
        this.listaTamaños.push({ value: element, text: element });
      });
      this.tamano = this.listaTamaños[this.listaTamaños.length - 1].value;
    },
  },
  watch: {
    parcial: function (newParcial) {
      if (newParcial) {
        this.total = false;
        this.calcularPrecioActual();
      }
    },
    total: function (newTotal) {
      if (newTotal) {
        this.parcial = false;
        this.calcularPrecioActual();
      }
    },
    numcaras: function () {
      this.calcularPrecioActual();
    },
    modelo: function () {
      this.parcial = this.tieneSublimacionParcial;
      this.total = this.tieneSublimacionTotal;
      this.calcularPrecioActual();
    },
    tamano: function () {
      this.calcularPrecioActual();
    },
    zona: function () {
      this.calcularPrecioActual();
    },
    numPredasBlancas: function (newVal) {
      if (newVal > 0)
      {
        this.calcularPrecioActual();
      }
      else
      {
        this.deleteReproceso({
          id: this.id,
          modelo: this.modelInfoFromCatalog.code,
          zona: this.zona.Zona
        })
      }
    },
    isProveedor: function () {
      this.calcularPrecioActual();
    },
    key: function () {
      this.calcularPrecioActual();
    },
    tieneSublimacionParcial: function (newVal) {
      this.parcial = newVal;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checkbox {
  margin-top: -20px;
  width: 100%;
}
.infoP {
  margin-bottom: -25px;
}
</style>
