import axios from "axios";
import { nextTick } from 'vue'

export default {
    updateHeightFrame({ commit, state }, payload = undefined) {


        nextTick(() => {
            if (!payload || isNaN(payload)) {
                const productDialog = document.getElementById("productSelectorDialogBody")

                if (productDialog) {
                    const head = productDialog.getElementsByClassName('v-toolbar')?.[0]?.scrollHeight ?? 0;

                    const colorSelector = productDialog.getElementsByClassName('v-sheet')?.[0]?.scrollHeight ?? 0;

                    const alertSublimacionBlanco = productDialog.getElementsByClassName('alertSublimacionBlanco')?.[0]?.scrollHeight ?? 0;
                    console.log("🚀 ~ nextTick ~ alertSublimacionBlanco:", alertSublimacionBlanco)

                    const body = productDialog.getElementsByClassName('v-data-table')?.[0]?.scrollHeight ?? 0;

                    if (head && colorSelector && body)
                        payload = head + colorSelector + body + alertSublimacionBlanco + 80;
                }

                const searchDialog = document.getElementById("searchModelDialog")
                // Comprobamos si esta activo con la clase active-body-search
                if (searchDialog && searchDialog.classList.contains('active-body-search')) payload = 750
                if (!payload || isNaN(payload))
                    payload = document.getElementById("calculadoraContainer").scrollHeight ?? 0;
            }
            if (state.calculadoraHeight != payload) {
                commit("SET_HEIGHT_FRAME", payload);
                parent.postMessage({ type: 'resizeIframe', height: payload }, "*");
            }
        })
    },
    setProveedorCheck({ commit }, value) {
        commit("SET_IS_PROVEEDOR", value)
    },
    async setlocale({ commit }, key) {
        commit("SET_LOCALE", key);
    },
    async loginComercial({ commit }, payload) {
        let response = await axios.post(`${process.env.VUE_APP_EXTRANET_URL}/Comercial/Auth`, payload);

        if (response.status == 200) {
            commit("SET_COMERCIAL", response.data);

            return response.data;
        }

        return null;
    },
    async tryLoginWithToken({ commit }) {
        let token = window.localStorage.getItem('authToken-calculator');
        if (!token) {
            return;
        }

        try {
            let response = await axios.get(`${process.env.VUE_APP_EXTRANET_URL}/Comercial/Auth`, {
                headers: {
                    ComercialSession: token
                }
            });

            if (response.status === 200) {
                commit("SET_COMERCIAL", response.data);
                return true;
            }
        } catch (error) {
            // Avisamos y eliminamos el token
            console.error(error);
            window.localStorage.removeItem('authToken-calculator');
        }
    },

    async searchUserList({ commit, rootGetters }, payload) {
        let search = payload.search;
        let showAllClient = payload.showAllClient;

        let response = await axios.get(`${process.env.VUE_APP_EXTRANET_URL}/Comercial/UserList`, {
            params: {
                search: search,
                showAllClient: showAllClient
            },
            headers: {
                ComercialSession: rootGetters['config/comercialToken']
            }
        });

        if (response.status === 200) {
            commit("SET_USER_LIST", response.data);
            return response.status === 200;
        }

        return false;
    },

    async loginClient({ commit, rootGetters, dispatch }, payload) {
        let clientId = payload.clientId;

        let response = await axios.get(`${process.env.VUE_APP_EXTRANET_URL}/Comercial/Client/${clientId}`, {
            headers: {
                ComercialSession: rootGetters['config/comercialToken']
            }
        });

        if (response.status === 200) {
            commit("CLIENT_LOGIN", response.data);
            dispatch("setUserOfClient", response.data.users[0]);
            return response.status === 200;
        }

        return false;
    },

    setUserOfClient({ commit }, payload) {
        commit("SET_USER_OF_CLIENT", payload);
    },

    async setKey({ commit }, key) {
        commit("SET_KEY", key);
    },

    logout({ commit }) {
        commit("SET_COMERCIAL", null);
        commit("CLIENT_LOGIN", null);
        commit("SET_USER_OF_CLIENT", null);
        commit("SET_KEY", null);
        if (localStorage.getItem("authToken-calculator"))
            window.localStorage.removeItem('authToken-calculator');
    },

    setResetCalculadora({ commit }, payload) {
        commit("SET_RESET_CALCULADORA", payload);
    },

    setColor({ commit, dispatch }, payload) {
        commit("SET_COLOR", payload);

        // canvasJson.backgroundImage.src = new url
        dispatch("carrito/updateDesignColor", payload, { root: true })

    }
}