<template>
    <div>
        <v-fade-transition>
            <v-overlay v-if="cargando" absolute>
                <v-progress-circular
                    indeterminate
                    color="gray"
                ></v-progress-circular>
            </v-overlay>
        </v-fade-transition>
        <div class="d-flex mb-1">
            <GenericCheck
                v-model="usarDobleNumero"
                :checkbox-label="$t('configuracion.Numeros.NumDobles')"
            ></GenericCheck>
        </div>
        <v-row class="mb-3">
            <v-col class="py-0">
                <v-select
                v-model="selectedType"
                hide-details="true"
                prepend-inner-icon="mdi-format-color-fill"
                filled
                color="primary darken-2"
                :items="typeOptions"
                item-text="Label"
                item-value="Type"
                @change="updateAltoAndMaxColor"
            ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" class="py-0">
                <v-select
                    v-model="selectedAlto"
                    hide-details="true"
                    prepend-inner-icon="mdi-tape-measure"
                    filled
                    color="primary darken-2"
                    :items="altoOptions"
                    :label="$t('configuracion.Transfer.Alto')"
                ></v-select>
            </v-col>
            <v-col cols="6" class="pb-0 pt-0">
                <v-select
                    v-model="selectedMaxColor"
                    hide-details="true"
                    prepend-inner-icon="mdi-water-outline"
                    filled
                    color="primary darken-2"
                    :items="maxColorOptions"
                    :label="$t(`configuracion.Numeros.MaxColor`)"
                ></v-select>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GenericCheck from "../basic/GenericCheck.vue";

export default {
    components: {
        GenericCheck
    },
    props: {
        zona: {
            type: Object,
            required: true
        },
        id: Number
    },
    data() {
        return {
            cargando: false,
            usarDobleNumero: false,
            selectedType: "",
            selectedAlto: "",
            selectedMaxColor: "",
            avalibleOptions: []
        };
    },
    computed: {
        ...mapGetters("carrito", ["cartHasProducts", "totalUnidades"]),
        ...mapGetters("modelo", ["modelCode"]),
        typeOptions() {
            return this.avalibleOptions.filter(
                option => option.isDobleNumero === this.usarDobleNumero
            );
        },
        altoOptions() {
            return this.typeOptions
                .filter(option => option.Type === this.selectedType)
                .map(option => option.Alto);
        },
        maxColorOptions() {
            const maxColor =
                this.typeOptions.find(
                    option => option.Type === this.selectedType
                )?.MaxColor ?? 0;
            return maxColor <= 1
                ? [1]
                : Array.from({ length: maxColor }, (_, i) => i + 1);
        },
        selectedTarifa() {
            return this.avalibleOptions.find(
                option =>
                    option.Type == this.selectedType &&
                    option.Alto == this.selectedAlto &&
                    option.MaxColor == this.selectedMaxColor &&
                    this.usarDobleNumero == option.isDobleNumero
            )?.Code;
        }
    },
    methods: {
        ...mapActions("modelo", ["fetchSizeNumerosTransfer"]),
        ...mapActions("carrito", ["calcularPrecios"]),
        async fetchOptions() {
            this.cargando = true;
            let response = await this.fetchSizeNumerosTransfer();
            this.avalibleOptions = response.data.map(option => ({
                Code: option.code,
                Type: option.type,
                isDobleNumero: option.isDouble,
                Alto: option.height,
                MaxColor: option.maxColor,
                Label: this.$t("configuracion.Numeros." + option.type)
            }));

            this.selectedType = this.avalibleOptions.filter(
                option => option.isDobleNumero === this.usarDobleNumero
            )[0].Type;
            this.updateAltoAndMaxColor(this.selectedType);
            this.cargando = false;
        },
        updateAltoAndMaxColor(val) {
            this.selectedType = val;
            this.selectedAlto = this.altoOptions[0];
            this.selectedMaxColor = this.maxColorOptions[0];
        },
        async calcularPrecio() {
            if (this.cartHasProduct == 0) return;

            let parametros = {
                tecnica: "TransferNumeros",
                codigo: this.modelCode,
                posicion: this.zona.Posicion,
                zona: this.zona.Zona,
                unidades: this.totalUnidades,
                isRepeticion: this.repeticion,

                atributos: JSON.stringify([
                {
                    esPackUno: (this.id == 0 ? this.hasPackUno : false),
                    isProveedor: this.isProveedor,
                    typeColor: this.selectedType,
                    maxHeight: this.selectedAlto,
                    colores: this.selectedMaxColor,
                    dobleNumero: this.usarDobleNumero
                }]),

                id: this.id
            };
            this.cargando = true;
            try {
                await this.calcularPrecios(parametros);
            } catch (error) {
                if(error != 'errorColorBanned')
          {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
            }

            this.cargando = false;
        }
    },
    watch: {
        totalUnidades: function () {
            this.calcularPrecio();
        },
        zona: function () {
            this.calcularPrecio();
        },
        selectedTarifa: function () {
            this.calcularPrecio();
        }
    },
    beforeMount() {
        this.selectedType = this.typeOptions[0]?.Type;
        this.updateAltoAndMaxColor(this.selectedType);
    },
    created() {
        this.fetchOptions();
    }
};
</script>
