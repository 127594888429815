<template>
  <general-modal
    persistant
    :dialog="localeAddZoneDialog"
    width="438px"
    height="381px"
  >
    <v-card class="selector-zones productModal mt-0">
      <v-card-title class="pa-0">
        <v-toolbar dense extension-height="0"  flat>
          <v-toolbar-title
            class="productos-titulo d-flex justify-space-between"
          >
            <v-container fluid class="pa-0">
              <v-row>
                <v-col cols="8" class="d-flex align-center py-0 pt-6">
                  <br />
                  <span class="modelo-name">
                    {{ $t("general.Añadir zona") }}
                  </span>
                </v-col>

                <v-col cols="4" class="d-flex flex-row-reverse align-center pr-0 py-0">
                  <div @click="localeAddZoneDialog = false">
                    <v-btn icon>
                      <v-icon xl>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-select
          v-if="mode == 'listSelector'"
          v-model="selectedNewZone"
          :items="computedZones"
        >
          <template slot="selection" slot-scope="data">
            {{ $t("Zonas." + formatZona(data.item.Zona)) }}
          </template>
          <template slot="item" slot-scope="data">
            {{ $t("Zonas." + formatZona(data.item.Zona)) }}
          </template>
        </v-select>
        <v-container v-else-if="mode == 'grid'" fluid class="pa-0">
          <v-row class="grid-zones">
            <v-col
              v-for="zona in cachedZonas"
              :key="zona.Zona"
              cols="12"
              md="3"
              class="pa-0 pr-3"
            >
              <v-card
                :class="
                  zona.active
                    ? 'active'
                    : selectedNewZone != null
                      ? 'noActive'
                      : ''
                "
                @click="selectedNewZone = zona"
                @mouseover="showDescription = zona.Posicion"
                @mouseleave="showDescription = null"
              >
                <v-card-text
                  class="grid-item d-flex flex-column align-center pa-0"
                >
                  <v-img
                    class="mt-0"
                    width="82px"
                    contain
                    :src="zona.imgUrl"
                    :lazy-src="zona.imgUrl"
                    style="background-color: white"
                  ></v-img>
                  <span class="title-zone-selector">
                    {{ $t("Zonas." + formatZona(zona.Zona)) }}
                  </span>
                  <span v-if="isStaminaModel && showDescription == zona.Posicion" class="description-zone-selector">
                    {{ formatDescription(zona.Posicion) }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn class="mr-2" outlined tile @click="localeAddZoneDialog = false">
          {{ $t("general.Cancelar") }}
        </v-btn>
        <v-btn
          tile
          class="confirm-buttom"
          :disabled="!selectedNewZone"
          :color="selectedNewZone ? 'bannerBackground' : ''"
          @click="addZone"
        >
          {{ $t("general.Aceptar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </general-modal>
</template>

<script>
import { mapGetters } from "vuex";

import { noImgUrl } from "@/utils/imageUtils";

import generalModal from "@/components/modales/generalModal.vue";

export default {
  components: {
    generalModal
  },
  props: {
    value: Boolean
  },
  data() {
    return {
      localeAddZoneDialog: false,
      selectedNewZone: null,
      mode: "grid",
      cachedZonas: [],
      showDescription: false
    };
  },
  computed: {
    ...mapGetters("modelo", ["zonas", "newComputedImgUrl", "modelCode", "zonasDescription", "isStaminaModel"]),
    ...mapGetters("config", ["colorSelected"]),
    ...mapGetters("carrito", ["listColorInCart"]),
    someActive() {
      return this.selectedNewZone;
    },
    // Zonas habiendo eliminado aquellas que sean incompatibles con todos los colores del carrito
    computedZones() {
      return this.zonas.filter(zona => {
        const filteredZona = Object.entries(zona.Tecnicas).filter(([key, value]) => key !== 'Extra' && value);
        const extra = zona.Tecnicas.Extra ? Object.entries(zona.Tecnicas.Extra).filter(([, value]) => value) : [];

        if (extra.length > filteredZona.length) {
          return true;
        }

        if (extra.every(e => e[1].BannedColors)) {
          return extra.every(e => this.listColorInCart.every(color => !e[1].BannedColors.includes(color)));
        }

        return true;
      });
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value && !this.localeAddZoneDialog) {
          this.localeAddZoneDialog = true;
        }
      }
    },
    localeAddZoneDialog(value) {
      if (!value) {
        this.$emit("input", false);
      } else {
        this.selectedNewZone = null;
      }
    },
    zonas: {
      immediate: true,
      handler() {
        this.updateComputedZonas();
      }
    },
    colorSelected: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal) this.updateComputedZonas();
      }
    },
    selectedNewZone: {
      immediate: true,
      handler() {
        this.updateComputedZonas(true);
      }
    }
  },
  methods: {
    // Método para formatear una zona
    formatZona(value) {
      if (!value) return value;
      return value.toUpperCase().replace(/_/g, " ");
    },
    formatDescription(value) {
      return this.zonasDescription.find(z => z.Posicion == value && z.Descripcion)?.Descripcion ?? "";
    },
    addZone() {
      if (!this.someActive) return;

      this.$emit("add-zone", this.selectedNewZone);
      this.localeAddZoneDialog = false;
    },
    updateComputedZonas(soft = false) {
      // TODO: Actualizar método para evitar que se llame tantas veces
      this.cachedZonas = this.computedZones.map(zona => ({
        ...zona,
        active: this.selectedNewZone && zona.Zona === this.selectedNewZone.Zona,
        imgUrl: soft
          ? (this.cachedZonas.find(z1 => z1.Posicion == zona.Posicion)
              ?.imgUrl ??
            this.newComputedImgUrl(zona, this.colorSelected) ??
            noImgUrl)
          : (this.newComputedImgUrl(zona, this.colorSelected) ?? noImgUrl)
      }));
    }
  }
};
</script>

<style lang="scss">
.selector-zones {
  
    padding: 20px;
  .title-modal {
    font-size: 18px !important;
    font-weight: 300;
    font-family: $heading-font-family;
  }

  .grid-zones {
    max-height: 270px;
    overflow: scroll;
    margin: 20px 0;

    .title-zone-selector {
      color: var(--Color-Black, #121212);
      font-family: $heading-font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .v-card {
      border-radius: 8px;
      border: 1px solid var(--Color-Grey-300, #dcdcdc);
      background: var(--Color-White, #fff);
      min-height: 113px;
      max-height: 129px;
      padding: 8px;
      margin-bottom: 12px;
      
      // Forzamos que todos tengan el mismo tamaño
      max-height: 110px;
      height: 100%;

      &:hover {
        border: 1px solid var(--v-bannerBackground-base, black);
      }

      &.active {
        border: 2px solid var(--v-bannerBackground-base, black);
        // background: var(--v-bannerBackground-opacity, rgba(151, 67, 252, 0.10));
         background: var(--Color-White, #fff);
        filter: opacity(0.8) drop-shadow(0 0 0 var(--v-bannerBackground-base, rgba(151, 67, 252, 0.10)));

        .title-zone-selector {
          filter: none;
        }
      }

      &.noActive {
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }

      .grid-item {
        span {
          color: var(--Color-Grey-600, #787878);
          text-align: center;
          font-family: $heading-font-family;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .description-zone-selector {
           font-size: 8px;
          font-style: italic;
        }
      }
    }
  }

  .v-btn--outlined {
    border: 1px solid var(--Color-Grey-600, #787878);

    .v-btn__content {
      color: var(--Color-Grey-600, #787878);
      font-family: $heading-font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .v-btn__content {
    text-transform: capitalize;
  }
  .confirm-buttom {
    color: var(--v-colorTextBanner-base, black);
  }
}
</style>
