<template>
  <v-dialog ref="generalModal" v-model="localDialog" :persistent="persistant" :max-width="width" :max-height="height" class="dialog-z-index-general">
    <slot></slot>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "600px",
    },
    height: {
      type: String,
      default: "600px",
        },
    onOpen: {
      type: Function,
      default: () => {},
        },
    timeout: {
      type: Number,
      default: 0,
    },
    persistant: {
      type: Boolean,
      default: false
    }
  },
    data() {
        return {
            localDialog: this.dialog,
            loading: false,
        };
    },
    watch: {
        dialog(newVal) {
            if (newVal != this.localDialog) this.localDialog = newVal;
        },
        localDialog(newVal) {
            if (newVal != this.dialog) {
                this.$emit("cerrar");
            }

            if (newVal && this.localDialog) {
                this.onOpen();
          }

          if (newVal) {
            this.$nextTick(() => { this.$refs.generalModal.showScroll(); });
          }
        },
    },
    mounted() {
        if (this.timeout > 0) {
            setTimeout(() => {
                this.localDialog = false;
            }, this.timeout);
        }
    },
};
</script>

<style lang="scss">
.dialog-z-index-general {
  z-index: 9999 !important;
}
</style>