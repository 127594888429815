<template>
  <v-app v-if="initLoadCalculadora || initLoaderYouReprocesos">
    <!-- Container de carga -->
    <v-container fluid class="loader">
      <v-row>
        <v-col cols="12">
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="15"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
  <v-app v-else id="appWrapContainer">
    <!-- Modal para hacer el login como comercial -->
    <LoginComercialModal
      v-if="comercialLoginAvalible"
      :dialog="loginDialog"
      @cerrar="onCloseLogin"
    ></LoginComercialModal>
    <!-- Modal de busqueda de usuarios -->
    <UserSearchModal
      v-if="comercialLoginAvalible"
      :dialog="userDialog"
      @cerrar="onCloseUser"
    ></UserSearchModal>
    <!-- Modal de alerta para mostrar los distintos errores -->
    <alerta
      :dialog="alertActive"
      :titulo="alertError.title"
      :boton-cerrar="$t('confirm.Cerrar')"
      :cuerpo="alertError.body"
      :type-error="alertError"
      @cerrar="alertActive = false"
    ></alerta>

    <!-- Menu principal -->
    <v-app-bar
      hide-on-scroll
      color="bannerBackground"
      app
      height="85"
      class="menu-app-bar"
    >
      <v-container class="px-0 px-md-5" fluid>
        <v-row>
          <v-col cols="12" lg="3" md="4" class="d-flex">
            <v-img
              :max-height="isOk || isYouReprocesos || isStamina ? 60 : 40"
              max-width="150"
              :src="actualLogo"
              alt="Logo compañia"
              contain
              class="mr-6"
              :class="{ 'white-svg': whiteSvg && !isYouReprocesos }"
            />
            <div
              v-if="!isYouReprocesos"
              class="d-flex flex-column justify-center title-app-bar"
            >
              <h1><b>YOURCUSTOMIZER</b></h1>
              <span v-if="!isOk && !isYouReprocesos">by gorfactory</span>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="d-flex justify-center align-center">
            <model-search @empty-model="initModalSearch"></model-search>
            <!-- Modal para buscar un modelo o cargar un prediseño si no se ha especificado nada -->
            <v-dialog
              v-model="initialModal"
              max-width="555px"
              class="productModal youReprocesosProductModal"
              transition="dialog-bottom-transition"
              :content-class="'productModal youReprocesosProductModal inital-modal'"
            >
              <v-card color="bannerBackground">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <div class="head">
                        {{ $t("general.selectModelOrDraft") }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <model-search
                        @model-selected="initialModal = false"
                      ></model-search>
                    </v-col>
                  </v-row>
                  <v-row v-if="!isYouReprocesos && activeBorradores">
                    <v-col class="w-100">
                      <cotizador-btn disabled outlined content-class="w-100">
                        {{ $t("draftLiteral.loadDraft") }}
                      </cotizador-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="3" md="2">

            <!-- Botón salir de la calculadora -->
            <div
              v-if="
                isInsideIframe && !isYouReprocesos && !comercialLoginAvalible
              "
              class="h-100 d-flex w-100 justify-end align-center"
            >
              <cotizador-btn
                sm
                dark
                color="black"
                content-class="d-flex justify-center align-center"
                :on="{
                  click: closeCotizador
                }"
              >
                <span class="mt-1">{{ $t("general.closeCotizador") }}</span>
                <v-icon class="ml-2">$fi-x</v-icon>
              </cotizador-btn>

              <v-dialog
                v-model="closeCotizadorDialog"
                persistent
                transition="dialog-bottom-transition"
                max-width="500"
                content-class="calculadora-dialog"
              >
                <v-card class="dialog-restart" color="bannerBackground">
                  <v-card-title class="d-flex justify-center">
                    <span>{{ $t("general.closeDialogTitle") }}</span>
                  </v-card-title>
                  <v-card-text class="d-flex flex-column align-center mt-5">
                    <v-progress-circular
                      :rotate="360"
                      :size="75"
                      :width="10"
                      indeterminate
                    ></v-progress-circular>
                    <span class="mt-3 align-self-left">
                      {{ $t("general.closeDialogMsg") }}
                    </span>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <template
        v-if="
          comercialLoginAvalible && comercialInfo &&
          !isYouReprocesos
        "
        #extension
      >
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col cols="12" md="6" class="d-flex pa-0">
              <div
                class="calculadora-comercialname"
              >
                <v-btn class="px-3" color="white"  @click.stop="drawerLanguage = !drawerLanguage">
                  <v-icon class="mt-1">$fi-globe</v-icon>{{ actualNameLanguage }}
                </v-btn>
                <v-menu auto offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn class="px-3 ml-3" v-bind="attrs" v-on="on">
                      {{ comercialInfo.name }} {{ comercialInfo.lastName }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item class="menu-options" @click="reiniciar">
                      <v-list-item-icon>
                        <v-icon>mdi-reload</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ $t("general.Reiniciar") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="menu-options" @click="onLogout">
                      <v-list-item-icon>
                        <v-icon>mdi-logout-variant</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ $t("login.Logout") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
            <v-col class="pa-0" cols="12" md="6">
              <div
                v-if="loggedClient &&
                userOfClient" class="calculadora-user">
                <v-btn class="mr-1" dark outlined @click="userDialog = true">
                  <v-icon dark>mdi-magnify</v-icon>
                  <span class="selectedClient ml-1">
                    {{ loggedClient.clientId }}
                    <br />
                    {{ loggedClient.clientName }}
                  </span>
                </v-btn>
                <v-select
                  v-model="localUserOfClient"
                  :items="loggedClient.users"
                  outlined
                  dense
                  hide-details
                  item-text="label"
                  item-value="user"
                  color="primary"
                  background-color="white"
                  class="selectableUser"
                ></v-select>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-app-bar>

    <v-main>
      <new-cotizador @reload="reiniciar" />
      <v-navigation-drawer
                v-model="drawerLanguage"
                absolute
                bottom
                temporary
              >
                <v-list
                  nav
                  dense
                >
                  <v-list-item-group
                    v-model="selectedLanguageIndex"
                    active-class="deep-purple--text text--accent-4"
                  >
                    <v-list-item v-for="lang in avalibleLanguages" :key="lang.code">
                      <v-list-item-title class="d-flex align-center" @click="setLocaleApp(lang.code)"> <v-img max-height="18px" max-width="18px" :src="lang.flagImg"/> <span class="ml-3">({{ lang.code }}) {{ lang.name }}</span></v-list-item-title>
                    </v-list-item>

                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getTextColor, lighterColor, hexToRGBA } from "@/utils/colorUtils";

import alerta from "@/components/modales/alert.vue";
import LoginComercialModal from "@/components/modales/loginComercialModal.vue";
import UserSearchModal from "@/components/modales/userSearchModal.vue";
import ModelSearch from "@/components/basic/modelSearch.vue";
import newCotizador from "@/components/cotizadorClientes/NewCotizador.vue";
import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";

export default {
  components: {
    LoginComercialModal,
    alerta,
    UserSearchModal,
    ModelSearch,
    newCotizador,
    CotizadorBtn
  },
  data() {
    return {
      cartDialog: false,
      loginDialog: false,
      userDialog: false,
      initLoadCalculadora: true,
      alertError: {},
      alertActive: false,
      loadingKey: false,
      initialModal: false,
      initReprocesos: false,
      localUserOfClient: null,
      closeCotizadorDialog: false,
      drawerLanguage: false,
    };
  },
  computed: {
    ...mapGetters("config", [
      "comercialLoginAvalible",
      "clientCartAvalible",
      "initLoaderYouReprocesos",
      "getLocaleFromLanguageRegion",
      "isYouReprocesos",
      "youReprocesosConfig",
      "isOk",
      "isStamina",
      "loggedClient",
      "userOfClient",
      "comercialInfo",
      "key",
      "activeBorradores",
      "actualLanguageRegion",
      "actualLanguage"
    ]),
    ...mapGetters("modelo", ["modelCode"]),
    // Logo actual dependiendo de que tienda este activa
    actualLogo() {
      return this.isYouReprocesos
        ? this.youReprocesosConfig?.bannerUrl
          ? this.youReprocesosConfig?.bannerUrl
          : this.youReprocesosConfig?.logourl
        : this.isOk
          ? "https://erolystorage.blob.core.windows.net/oktextil/LOGO_OK_BLANCO.svg"
          : this.isStamina
            ? "https://static.gorfactory.es/images/header/Logo_Stamina_200x56px.svg"
            : "https://static.gorfactory.es/images/header/logo_Roly_2025.svg";
    },
    // Calculamos is tenemos que poner el svg en blanco o en negro
    whiteSvg() {
      return (
        this.$vuetify.theme.currentTheme.colorTextBanner &&
        (this.$vuetify.theme.currentTheme.colorTextBanner == "#ffffff" ||
          this.$vuetify.theme.currentTheme.colorTextBanner == "white")
      );
    },
    isInsideIframe() {
      return window !== window.parent;
    },
    avalibleLanguages() {
      return this.$root.$i18n.extraInfoAvailableLocales;
    },
    selectedLanguageIndex() {
      
      return this.avalibleLanguages.findIndex(lang => lang.code === this.actualLanguage) !== -1 
        ? this.avalibleLanguages.findIndex(lang => lang.code === this.actualLanguage) 
        : 0;
    },
    actualNameLanguage() {
      return this.avalibleLanguages.find(lang => lang.code == this.actualLanguage)?.name
    }
  },
  watch: {
    isYouReprocesos(newVal) {
      if (newVal && !this.initReprocesos) {
        this.initYouReprocesos();
      }
    },
    loggedClient() {
      this.reiniciar();
    },
    async userOfClient(newVal, oldVal) {
      if (newVal != oldVal && newVal?.user && newVal?.reprocesosKey) {
        this.cargando = true;
        this.localUserOfClient = newVal.user;
        this.setKey(newVal.reprocesosKey);
        this.initialModal = true;
      }
    },
    localUserOfClient(newVal, oldVal) {
      if (newVal != oldVal && newVal != this.userOfClient?.user) {
        this.setUserOfClient(
          this.loggedClient.users.find(u => u.user == newVal)
        );
      }
    }
  },
  async beforeMount() {
    let actualCompany = this.$route.query.company;
    if (actualCompany) {
      this.setCompanyValue(actualCompany);
    }

    let actualShop = this.$route.query.shop ?? "ROL";
    if (actualShop) {
      this.setShopValue(actualShop);
    }

    await this.initCalculadora();
  },
  mounted() {
    let key = this.$route.query.k;
    let avalibleClientKey = !(key === undefined || key == null || key == "");
    if (!avalibleClientKey) {
      if (
        !this.comercialInfo &&
        this.comercialLoginAvalible &&
        !this.isYouReprocesos
      )
        this.loginDialog = true;
    } else {
      this.setKey(key);
      this.loadingKey = true;
    }
  },
  methods: {
    ...mapActions("modelo", ["InitCotizador"]),
    ...mapActions("config", [
      "setKey",
      "setlocale",
      "setUserOfClient",
      "logout",
      "setResetCalculadora",
      "setCompanyValue",
      "setShopValue"
    ]),
    ...mapActions("carrito", ["cleanAllDesign", "restoreCarrito"]),
    // Función para actualizar el color del texto dependiendo del tema
    updateFontColorByTheme(param) {
      let { colorTextBtn, colorTextBanner } = param || {};
      if (!this.$vuetify.theme.currentTheme) return;

      if (colorTextBtn) {
        this.$vuetify.theme.currentTheme.colorTextBtn = colorTextBtn;
      } else {
        this.$vuetify.theme.currentTheme.colorTextBtn = getTextColor(
          this.$vuetify.theme.currentTheme.colorBtn
        );
      }
      if (colorTextBanner) {
        this.$vuetify.theme.currentTheme.colorTextBanner = colorTextBanner;
      } else {
        this.$vuetify.theme.currentTheme.colorTextBanner = getTextColor(
          this.$vuetify.theme.currentTheme.bannerBackground
        );
      }
    },
    // Función para inicializar la calculadora
    async initCalculadora() {
      // Inicializamos el idioma
      await this.setLocaleApp();

      // Buscamos todos los modelos
      await this.InitCotizador();

      // Actualizamos youReprocesos si estamos en una sesión de YouReprocesos
      if (this.isYouReprocesos) {
        await this.initYouReprocesos();
      }

      if (this.isOk) {
        this.$vuetify.theme.currentTheme.primary = "#b74040";
        this.$vuetify.theme.currentTheme.bannerBackground = "#b74040";
        this.$vuetify.theme.currentTheme.colorBtn = "#b74040";

        this.updateFontColorByTheme({ colorTextBanner: "#fff" });
      }

      document.documentElement.style.setProperty(
        "--v-bannerBackground-opacity",
        hexToRGBA(this.$vuetify.theme.currentTheme.bannerBackground, 0.2)
      );

      // Cerramos el loader
      this.initLoadCalculadora = false;
      this.loadingKey = false;
    },
    // Función que pone el idioma a la calculadora
    async setLocaleApp(lang = null) {
      this.drawerLanguage = false
      if (lang == null) {
        lang = this.$route.query.lang ?? "en";
      }

      lang = this.getLocaleFromLanguageRegion(lang);
      this.setlocale(lang);
      await this.$root.$i18n.loadLanguageAsync(lang);
    },
    // Función para inicializar youReprocesos
    async initYouReprocesos() {
      this.$vuetify.theme.currentTheme.primary =
        this.youReprocesosConfig.accentColor;
      this.$vuetify.theme.currentTheme.colorBtn =
        this.youReprocesosConfig.accentColor;

      this.$vuetify.theme.currentTheme.bannerBackground =
        this.youReprocesosConfig.bannerColor;
      if (!this.modeloSeleccionado) this.searchModelDialogShow = true;

      if (this.youReprocesosConfig.logourl) {
        const favicon = document.getElementById("favicon-link");
        favicon.href = this.youReprocesosConfig.logourl;

        document.documentElement.style.setProperty(
          "--v-youReprocesos-logo-url",
          "url(" + this.youReprocesosConfig.logourl + ")"
        );
      }

      if (this.youReprocesosConfig.bannerUrl) {
        document.documentElement.style.setProperty(
          "--v-youReprocesos-banner-url",
          "url(" + this.youReprocesosConfig.bannerUrl + ")"
        );
      }

      await this.setLocaleApp(this.youReprocesosConfig.language);

      this.$vuetify.theme.currentTheme.primaryColorPdf =
        this.youReprocesosConfig.colorTypePdf == 1
          ? this.youReprocesosConfig.accentColor
          : this.youReprocesosConfig.colorTypePdf == 2
            ? this.youReprocesosConfig.bannerColor
            : "#7e7e7e";
      this.$vuetify.theme.currentTheme.primaryColorText =
        this.youReprocesosConfig.colorTypePdf == 0
          ? "white"
          : getTextColor(this.$vuetify.theme.currentTheme.primaryColorPdf);

      this.$vuetify.theme.currentTheme.secondaryColorPdf = lighterColor(
        this.$vuetify.theme.currentTheme.primaryColorPdf,
        85
      );

      this.$vuetify.theme.currentTheme.secondaryColorText = getTextColor(
        this.$vuetify.theme.currentTheme.secondaryColorPdf
      );

      this.updateFontColorByTheme();

      this.initReprocesos = true;
      this.initialModal = true;
    },
    // Función para cerrar el login y abrir el buscador de usuarios
    onCloseLogin() {
      this.loginDialog = false;
      this.userDialog = true;
    },
    // Función para cerrar el carrito
    onCloseCart() {
      this.cartDialog = false;
    },
    // Función para cerrar el dialogo de usuarios
    onCloseUser() {
      this.userDialog = false;
    },
    // Función para iniciar el modal de busqueda
    initModalSearch() {
      if (!this.key) return;
      this.initialModal = true;
    },
    // Función para reinicar completamente el cotizador
    async reiniciar() {
      this.restoreCarrito();
      // await this.InitCotizador();
      await this.cleanAllDesign();
      this.setResetCalculadora(false);
      this.initialModal = true;
    },
    // Función para cerrar sesión de comercial
    async onLogout() {
      await this.reiniciar();
      await this.logout();
      this.loginDialog = true;
      this.userDialog = false;
    },
    // Función para cerrar la calculadora
    async closeCotizador() {
      this.closeCotizadorDialog = true;
      setTimeout(() => {
        if (this.isInsideIframe) {
          if (this.modelCode) {
            parent.postMessage(
              { type: "navigationToModel", modelCode: this.modelCode },
              "*"
            );
          } else {
            parent.postMessage({ type: "closeCalculator" }, "*");
          }
        } else {
          console.warn(
            "No nos encontramos dentro de un iframe para cerrar la calculadora"
          );
        }
      }, 300);
    }
  }
};
</script>

<style lang="scss">
.loader {
  height: 100%;

  .row {
    height: 100%;

    .col {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.inital-modal {
  color: var(--v-colorTextBanner-base);
  overflow: visible;
  *:not(
      .searchInputModel,
      .listSerachModel,
      .cotizador-btn-success,
      .cotizador-btn
    ) {
    color: inherit !important;
  }

  .head {
    display: flex;
    justify-content: center;
    font: normal normal normal 19px/24px $heading-font-family;
    letter-spacing: 0px;
    margin-top: 15px;
    color: var(--v-colorTextBanner-base) !important;
  }
}

.menu-app-bar {
  font-family: $heading-font-family;
  color: var(--v-colorTextBanner-base) !important;

  .v-toolbar__content {
    z-index: 10000;
  }

  .calculadora-user {
    font-size: 15px;
    font-weight: 300;
    margin-right: 25px;
    /* position: absolute; */
    /* right: 10px; */
    /* height: 100%;  */
    /* margin-top: 5px; */
    /* background: white; */
    max-height: 45px;
    /* max-width: 250px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .selectableUser,
    .selectedClient {
      max-width: 250px;
      overflow: hidden;
    }
  }

  .calculadora-comercialname {
    font-size: 15px;
    font-weight: 300;
    margin-left: 25px;
    /* position: absolute; */
    /* left: 10px;
  height: 100%; */
    /* margin-top: 5px; */
  }

  .white-svg {
    filter: invert(1);
  }

  .title-app-bar {
    h1 {
      height: 28px;
      font-size: 25px;
    }

    span {
      font-weight: 100;
    }
  }
}

// .v-main {
//   padding-top: 91px !important;
// }

// @media screen and (max-width: 600px) {
//   .menu-app-bar {
//     height: 170px !important;

//     .v-toolbar__content {
//       height: 170px !important;
//     }
//   }

//   .v-main {
//     padding-top: 170px !important;
//   }
// }
</style>
