<template>
<div>
  <v-row>
    <v-col cols="12" class="mx-auto">
      {{ $t('configuracion.Consultar presupuesto con su comercial') }}
      </v-col>
  </v-row>
 
  </div>
</template>

<script>

export default {
  name: "Serigrafia",
  props: {
    color: Object,
    cantidad: Number,
    zona: Object,
    id: Number
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checkbox {
  margin-top: -20px;
  width: 100%;
}
</style>
