<template>
  <div>
    <v-fade-transition>
      <v-overlay v-if="cargando" absolute>
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>

    <v-row>
      <v-col class="py-0">
         <span class="text-subtitle-2">{{
          $t("configuracion.Seleccione un tamaño de la lista")
        }}</span>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{
            $t("configuracion.El tamaño máximo para esta combinación de tallas es" )+ ": " +(actualMaxValidSize ? actualMaxValidSize.text :$t("configuracion.No hay tamaños válidos"))
          }}</span>
        </v-tooltip>
        <v-select
          v-model="tamaño"
          :items="tamaños"
          filled
          :label="$t('configuracion.ImpresionDirecta.Tamaño del estampado')"
          color="primary darken-2"
          prepend-inner-icon="mdi-tape-measure"
        >
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ImpresionDirecta",
  props: {
    cantidad: Number,
    zona: Object,
    color: Object,
    id: Number
  },
  data() {
    return {
      tamaño: 4,
      colores: 1,
      repeticion: false,
      cargando: false,
    };
  },
  created() {
    this.tamaño = this.actualMaxValidSize?.value ?? 4;
    this.calcularPrecio();
  },
  computed: {
    ...mapGetters("carrito", ["carrito","hasPackUno", "medidasMaximas", "quantityByColor"]),
    ...mapGetters("modelo", ["productos", 'maxValidSize', 'zonesFondoByZone', 'modelInfoFromCatalog']),
    ...mapGetters("config", ["isProveedor", "key"]),
    tamaños() {
      return [
        {
          value: 1,
          Ancho: 400,
          Alto: 500,
          text: this.$t(
            "configuracion.ImpresionDirecta.Size1"
          ),
        },
        {
          value: 2,
          Ancho: 300,
          Alto: 400,
          text: this.$t(
            "configuracion.ImpresionDirecta.Size2"
          ),
        },
        {
          value: 3,
          Ancho: 250,
          Alto: 250,
          text: this.$t(
            "configuracion.ImpresionDirecta.Size3"
          ),
        },
        {
          value: 4,
          Ancho: 100,
          Alto: 100,
          text: this.$t(
            "configuracion.ImpresionDirecta.Size4"
          ),
        },
      ];
    },
    actualMaxValidSize() {
      if (this.carrito.productos.length == 0) return null;
      return this.maxValidSize(this.tamaños, this.zona.Posicion)
    }
  },
  methods: {
    ...mapActions("carrito", [
      "calcularPrecios",
    ]),
    ...mapActions("modelo", ["addAlert"]),
    calcularPrecio: async function () {
      //?codigoModelo=CA6502&impresiones=100&idTamImpresion=1&fondo=Claro&esPrendaPackUno=false
      if (this.cantidad == 0) return;

      let resultFind = this.tamaños.find(t => t.value == this.tamaño);
      let xaxis = resultFind.Ancho;
      let yaxis = resultFind.Alto;

      var excedido = this.medidasMaximas(this.zona.Posicion, xaxis, yaxis);
      if (excedido && excedido.length > 0) {
        this.addAlert({
          title:
            this.$t("alert.errorTamEstampacionTitleDialog") +
            " (" +
            this.$t("Tecnicas.IMPRESIÓN DIRECTA") +
            ")",
          listProduct: excedido,
          code: "errorTamEstampacion",
          tamEstX: xaxis,
          tamEstY: yaxis,
          zone: this.zona,
          tamEstampacion: this.tamaños.find((t) => t.value == this.tamaño)
            ?.text,
        });
      }
      // var prodSeleccionado = this.productos.find(
      //   (e) => e.color == this.color.CODE
      // );
      // const { FONDO } = prodSeleccionado.productos[0];
      // let fondo = FONDO === 0 ? "Claro" : "Oscuro";
      // const infoZonasFondo = this.zonesFondoByZone(this.zona.Posicion);
      // const { AvalibleColors } = infoZonasFondo || {};
      // const actualColor = AvalibleColors && AvalibleColors.find(
      //   (c) => c.Color == this.color.CODE
      // );
      // fondo = actualColor && (actualColor.isBlanco || actualColor.isClaro) ? "Claro" : actualColor && actualColor.isOscuro ? "Oscuro" : fondo;

      let fondo = "Claro"

      let atr = {
        esPrendaPackUno: (this.id == 0 ? this.hasPackUno : false),
        idTamImpresion: this.tamaño,
        isProveedor: this.isProveedor,
        fondo: fondo,
        coloresImprDir: JSON.stringify(this.quantityByColor)
      };

      let parametros = {
        // Generales
        tecnica: "Impresion",
        codigo: this.modelInfoFromCatalog.code,
        unidades: this.cantidad,
        posicion: this.zona.Posicion,
        zona: this.zona.Zona,

        atributos: JSON.stringify([atr]),

        //Identificador
        id: this.id
      };



      this.cargando = true;
      try {
        await this.calcularPrecios(parametros);
      } catch (error) {
        if(error != 'errorColorBanned')
          {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
      }

      this.cargando = false;
    },
  },
  watch: {
    tamaño: 'calcularPrecio',
    cantidad: 'calcularPrecio',
    repeticion: 'calcularPrecio',
    modelo: 'calcularPrecio',
    zona: 'calcularPrecio',
    isProveedor: 'calcularPrecio',
    key: 'calcularPrecio'
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
