import axios from "axios";

// Función para recuperar un carrito
export const GetCart = ({ comercialToken, reprocesosKey, type }) => {
    if ((!comercialToken && !reprocesosKey) || !type) return Promise.reject("GetCart: Missing parameters")

    // Si debemos pedir un comercial, tenemos que tener comercialToken, userLogin y clientId 
    const headers = comercialToken ? {
        ComercialSession: comercialToken
    } : {}

    // En caso contrario, pedimos el carrito con el reprocesosKey
    return axios
        .get(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Cart`, {
            params: {
                ReprocesosKey: reprocesosKey,
                Type: type
            },
            headers
        })
};

// Función para añadir al carrito
export const AddToCart = ({ type, payload, reprocesosKey, comercialToken }) => {
    if (!type || !payload) return Promise.reject("AddToCart: Missing parameters")

    const headers = comercialToken ? {
        ComercialSession: comercialToken
    } : {}

    return axios
        .post(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Cart`, payload, {
            params: {
                ReprocesosKey: reprocesosKey,
                Type: type
            },
            headers
        })
}

// Función para borrar una línea del carrito
export const DeleteLineFromCart = ({ lineId, comercialToken, reprocesosKey, type }) => {
    if (!lineId) return Promise.reject("DeleteLineFromCart: Missing parameters")

    const headers = comercialToken ? {
        ComercialSession: comercialToken
    } : {}

    return axios
        .delete(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Cart`, {
            params: {
                ReprocesosKey: reprocesosKey,
                Id: lineId,
                Type: type
            },
            headers
        })
}

// Función para borrar por completo el carrito
export const DeleteCart = ({ comercialToken, reprocesosKey, type }) => {
    const headers = comercialToken ? {
        ComercialSession: comercialToken
    } : {}

    return axios
        .delete(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Cart/All`, {
            params: {
                ReprocesosKey: reprocesosKey,
                Type: type
            },
            headers
        })
}

// Función para imprimir una línea del carrito
export const PrintReproceso = ({ lineId, comercialToken, reprocesosKey, lang }) => {
    if (!lineId || (!comercialToken && !reprocesosKey)) return Promise.reject("PrintReproceso: Missing parameters")

    if (comercialToken) {
        return axios.get(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Print/Comercial`,
            {
                params: {
                    id: lineId,
                    lang
                },
                headers: {
                    ComercialSession: comercialToken
                },
                responseType: 'blob',
                'Content-Type': 'application/pdf'
            })
    }

    return axios.get(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Print`,
        {
            params: {
                ReprocesosKey: reprocesosKey,
                Id: lineId,
                lang
            },
            responseType: 'blob',
            'Content-Type': 'application/pdf'
        })
}

export const PrintIncompleteReproceso = ({ payload, reprocesosKey, lang }) => {
    if (!payload || !reprocesosKey) return Promise.reject("PrintIncompleteReproceso: Missing parameters")

    return axios
        .post(
            `${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Print`,
            payload,
            {
                params: {
                    ReprocesosKey: reprocesosKey,
                    lang
                },
                responseType: 'blob',
                'Content-Type': 'multipart/form-data'
            }
        )
}

// Función para finalizar un carrito
export const FinishCart = ({ reprocesosKey, comercialToken, type, payload }) => {
    if (!reprocesosKey) return Promise.reject("FinishCart: Missing parameters")

    const headers = comercialToken ? {
        ComercialSession: comercialToken
    } : {}

    return axios
        .post(`${process.env.VUE_APP_EXTRANET_URL}/Reprocesos/Cart/Finish`, payload, {
            params: {
                ReprocesosKey: reprocesosKey,
                Type: type
            },
            headers
        })
}
