<template>
  <div>
    <v-fade-transition>
      <v-overlay v-if="cargando" absolute>
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>

    <v-row>
      <v-col cols="12" class="py-0">
        <span class="text-subtitle-2">{{
          $t("configuracion.Seleccione un tamaño de la lista")
        }}</span>
        <v-select
          v-model="tamaño"
          :items="tamaños"
          filled
          :label="$t('configuracion.Serigrafia.Tamaño del estampado')"
          color="primary darken-2"
          prepend-inner-icon="mdi-tape-measure"
          hide-details="true"
          @change="actualGetMedidasByTamano"
        >
        </v-select>
      </v-col>
      <v-col cols="6" class="py-0 mb-3">
        <v-text-field
          v-model="ancho"
          class="mt-2"
          suffix="mm"
          :label="$t('configuracion.Serigrafia.Ancho')"
          hide-details="true"
          type="number"
          color="primary darken-2"
          filled
          @change="getTamanoByMedidas"
        ></v-text-field>
        <span>{{ $t("configuracion.Serigrafia.Max: 297mm") }}</span>
      </v-col>
      <v-col cols="6" class="py-0 mb-3">
        <v-text-field
          v-model="alto"
          class="mt-2"
          suffix="mm"
          :label="$t('configuracion.Serigrafia.Alto')"
          hide-details="auto"
          type="number"
          color="primary darken-2"
          filled
          @change="getTamanoByMedidas"
        ></v-text-field>
        <span>{{ $t("configuracion.Serigrafia.Max: 420mm") }}</span>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <v-select
          v-model="colores"
          :items="coloresDisponibles"
          filled
          :label="$t('configuracion.Serigrafia.Colores')"
          color="primary darken-2"
          prepend-inner-icon="mdi-format-color-fill"
          class="py-0"
          hide-details="true"
        >
        </v-select>
        <span class="d-flex">
          <v-checkbox
            v-model="planchado"
            :label="$t('configuracion.Serigrafia.PreguntaPlanchado')"
            color="primary darken-2 white--text"
            class="mt-1 pt-1"
            hide-details="true"
          >
          </v-checkbox>
        </span>
        <span v-if="colores == 1" class="d-flex">
          <v-checkbox
            v-model="tintaNegra"
            :label="$t('configuracion.Serigrafia.Usar tinta negra')"
            color="primary darken-2 white--text"
            class="mt-1 pt-1"
            hide-details="true"
          >
          </v-checkbox>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon class="mt-1">mdi-information</v-icon>
              </v-btn>
            </template>
            <span>{{
              $t(
                "configuracion.Serigrafia.Si alguno de los colores contiene el color negro"
              )
            }}</span>
          </v-tooltip>
        </span>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <v-select
          v-model="tinta"
          :items="tintas"
          filled
          :label="$t('configuracion.Tintas.Tipo de tinta')"
          color="primary darken-2"
          prepend-inner-icon="mdi-water-outline"
          class="py-0"
          hide-details="true"
        >
        </v-select>
        <span>{{
          $t(
            "configuracion.Tintas.Si la prenda es elástica (elastano) o el tejido es de canalé, se ha de aplicar tinta para elastano"
          )
        }}</span>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <span class="subtitle-2">{{
          $t("configuracion.Otros parámetros")
        }}</span>
        <br />

        <check-repeticion v-model="repeticion" class="py-0" :id-zone="id"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CheckRepeticion from '@/components/basic/CheckRepeticion.vue';

export default {
  name: "Serigrafia",
  components: {
    CheckRepeticion,
  },
  props: {
    color: Object,
    cantidad: Number,
    zona: Object,
    tamaños: {
      type: Array,
      default: function () {
        return ["A3", "A4", "A5", "A6", "A7", "A8"];
      },
    },
    id: Number,
  },
  data() {
    return {
      tamaño: "A3",
      ancho: 52,
      alto: 74,
      colores: 1,
      maxColores: 8,
      tinta: 5,
      repeticion: false,
      tintaNegra: false,
      cargando: false,
      planchado: false,
      productos2: [],
      tintas: [],
    };
  },
  computed: {
    ...mapGetters("carrito", [
      "carrito",
      "hasSerigrafiaOTransfer",
      "hasPackUno",
      "numReprocesosSerigrafiaOrTransfer",
      "medidasMaximas",
    ]),
    ...mapGetters("modelo", [
      "productos",
      "zonesFondoByZone",
      "maxValidSize",
      "contieneElastano",
      "forzarTipoTinta",
      "modelInfoFromCatalog",
      "listColorFondo"
    ]),
    ...mapGetters("config", ["isProveedor", "getMedidasByTamano", "allSizes", "key"]),
    infoZonasFondo: function () {
      return this.zonesFondoByZone(this.zona.Posicion);
    },

    coloresDisponibles: function () {
      var coloresmaximos = this.maxColores;
     
      if (this.tintaNegra) {
        coloresmaximos = 1;
      }

      let colores = [];
      for (let i = 1; i <= coloresmaximos; i++) {
        colores.push(i);
      }
      return colores;
    },
    actualMaxValidSize() {
      if (this.carrito.productos.length == 0 || !this.zona) return null;
      let customSizes = Object.keys(this.allSizes)
        .filter((s) => this.tamaños.includes(s))
        .map((s) => {
          return {
            ...this.allSizes[s],
            text: s,
          };
        });
      return this.maxValidSize(customSizes, this.zona.Posicion);
    },
  },
  watch: {
    tamaño: function () {
      this.calcularPrecio();
    },
    colores: function () {
      this.calcularPrecio();
    },
    tinta: function (newTinta) {
      if (newTinta == 6) {
        if (this.colores > 1) this.colores = 1;
        this.maxColores = 1;
      } else {
        this.maxColores = 8;
      }

      this.calcularPrecio();
    },
    cantidad: async function () {
      await this.calcularPrecio();
      if (this.planchado)
      {
        this.deleteReproceso({
            modelo: this.modelInfoFromCatalog.code,
            zona: this.zona.Zona,
            id: "planchado-" + this.id
        });
        let parametros = {
          tecnica: "Planchado",
          codigo: this.modelInfoFromCatalog.code,
          posicion: this.zona.Posicion,
          zona: this.zona.Zona,
          unidades: this.cantidad,
          isRepeticion: false,
          // divisa: rootGetters["modelo/currency"],
          atributos: JSON.stringify([
            {
              listPlanchado: JSON.stringify([{
                Tecnica: "Serigrafia",
                Zona: this.zona.Posicion,
              }]),
            },
          ]),

          id: "planchado-" + this.id,
        };
         try {
            await this.calcularPrecios(parametros);
          } catch (error) {
            if(error != 'errorColorBanned')
          {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
          }
      }
    },
    repeticion: function () {
      this.calcularPrecio();
    },
    zona: async function (newVal, oldVal) {
      if (newVal == oldVal) return;
      this.tamaño = this.actualMaxValidSize?.text ?? "A8";

      this.ancho = this.allSizes[this.tamaño].Ancho;
      this.alto = this.allSizes[this.tamaño].Alto;
      this.calcularPrecio();
    },
    tintaNegra: function () {
      this.calcularPrecio();
    },
    key: function ()
    {
      this.calcularPrecio();
    },
    isProveedor: function () {
      this.calcularPrecio();
    },
    numReprocesosSerigrafiaOrTransfer: function () {
      if (this.hasSerigrafiaOTransfer) this.calcularPrecio();
    },
    modelo: function () {
      this.comprobarSiEsBaberoDummy();
      this.tintas = this.tintasDisponibles();
      this.tinta = this.tintaInicial();
      this.calcularPrecio();
    },
    planchado: async function (newVal, oldVal) {
      if (newVal == oldVal) return;
      this.cargando = true;
      this.calcularPrecio();
      this.cargando = false;
    },
    coloresDisponibles: function (newVal) {
      this.colores = newVal[0];
    },
  },
  created() {
    this.fetchColorFondo();
    this.comprobarSiEsBaberoDummy();
    this.actualGetMedidasByTamano();
    this.tintas = this.tintasDisponibles();
    this.tinta = this.tintaInicial();
    this.tamaño = this.actualMaxValidSize?.text ?? "A8";

    this.ancho = this.allSizes[this.tamaño].Ancho;
    this.alto = this.allSizes[this.tamaño].Alto;
    this.calcularPrecio();
  },
  methods: {
    ...mapActions("carrito", [
      "calcularPrecios",
      "deleteReproceso",
      "updateParamsHeightAndWidthWithoutUpdatePrice"
    ]),
    ...mapActions("modelo", ["addAlert", "fetchColorFondo"]),
    calcularPrecio: async function () {
      if (this.cantidad == 0) return;
      var excedido = this.medidasMaximas(this.zona.Posicion, this.ancho, this.alto); // Revisar aviso
      // var excedido = [];
      if (excedido.length > 0) {
        this.addAlert({
          title:
            this.$t("alert.errorTamEstampacionTitleDialog") +
            " (" +
            this.$t("Tecnicas.SERIGRAFÍA") +
            ")",
          listProduct: excedido,
          code: "errorTamEstampacion",
          tamEstX: this.ancho,
          tamEstY: this.alto,
          zone: this.zona,
          tamEstampacion: this.tamaño,
        });
      }
      let nreprocesos = this.numReprocesosSerigrafiaOrTransfer;
      let prendasBlanco = 0;
      let prendasClaro = 0;
      let prendasOscuro = 0;

      let hasSerigrafiaSpecificZone =
        this.infoZonasFondo &&
        this.infoZonasFondo.AvalibleColors &&
        this.infoZonasFondo.AvalibleColors.length > 0;

      this.carrito.productos.forEach((e) => {
        let productInfo = this.modelInfoFromCatalog.products[e.id]
        if (productInfo == null) return;

        if (hasSerigrafiaSpecificZone) {
          let actualColorZone = this.infoZonasFondo.AvalibleColors.find(
            (c) => c.Color == e.color
          );

          if (actualColorZone) {
            if (actualColorZone.isBlanco) {
              prendasBlanco += parseInt(e.cantidad);
            } else if (actualColorZone.isClaro) {
              prendasClaro += parseInt(e.cantidad);
            } else if (actualColorZone.isOscuro) {
              prendasOscuro += parseInt(e.cantidad);
            }
            return;
          }
        }

        if (productInfo.COLOR == "01") prendasBlanco += parseInt(e.cantidad);
        else if (!this.listColorFondo[productInfo.COLOR] == 0) prendasClaro += parseInt(e.cantidad);
        else prendasOscuro += parseInt(e.cantidad);
      });

      let parametros = {
        tecnica: "Serigrafia",
        codigo: this.modelInfoFromCatalog.code,
        posicion: this.zona.Posicion,
        zona: this.zona.Zona,
        unidades: this.cantidad,
        isRepeticion: this.repeticion,

        atributos: JSON.stringify([
          {
            prendasBlanco: prendasBlanco,
            prendasClaro: prendasClaro,
            prendasOscuro: prendasOscuro,
            esPackUno: (this.id == 0 ? this.hasPackUno : false),
            isProveedor: this.isProveedor,
            tintaNegra: this.tintaNegra,
            colores: this.colores,
            tamanoEstampado: this.tamaño,
            maxWidth: this.ancho,
            maxHeight: this.alto,
            idTipoTinta: this.tinta,
            nreprocesos: nreprocesos,
            hasPlanchado: this.planchado
          },
        ]),

        id: this.id,
      };
      this.cargando = true;
      try {
        await this.calcularPrecios(parametros);
      } catch (error) {
        if(error != 'errorColorBanned')
          {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
      }

      this.cargando = false;
    },
    actualGetMedidasByTamano: function () {
      let tamano = this.tamaño;
      var result = this.getMedidasByTamano(tamano);
      this.ancho = result.Ancho;
      this.alto = result.Alto;
      //AutoAJUSTE
      //comprobarMedidas();
    },
    getTamanoByMedidas: function () {
      const actualTamano = this.tamaño;
      if (this.alto <= 74 && this.ancho <= 52) {
        this.tamaño = "A8";
      } else if (this.alto <= 105 && this.ancho <= 74) {
        this.tamaño = "A7";
      } else if (this.alto <= 148 && this.ancho <= 105) {
        this.tamaño = "A6";
      } else if (this.alto <= 210 && this.ancho <= 148) {
        this.tamaño = "A5";
      } else if (this.alto <= 297 && this.ancho <= 210) {
        this.tamaño = "A4";
      } else if (this.alto <= 420 && this.ancho <= 297) {
        this.tamaño = "A3";
      } else {
        alert(
          this.$t(
            "configuracion.Serigrafia.Las medidas son superiores a la zona de marcado"
          )
        );
      }

      // Debemos actualizar los params aunque no haya cambio de tamaño
      if (actualTamano == this.tamaño) {
        this.updateParamsHeightAndWidthWithoutUpdatePrice({
          id: this.id,
          ancho: this.ancho,
          alto: this.alto
        })
      }
    },
    comprobarSiEsBaberoDummy: function () {
      // El babero dummy "RB9000" sólo admite un color
      if (this.modelInfoFromCatalog.code == "RB9000") {
        if (this.colores > 1) this.colores = 1;
        this.maxColores = 1;
      } else {
        this.maxColores = 8;
      }
    },
    tintaInicial() {
      let tinta = 5;

      if (this.contieneElastano) tinta = 2;
      if (
        this.forzarTipoTinta != null &&
        this.forzarTipoTinta > 0
      )
        tinta = this.getIdTintaForzada();

      return tinta;
    },
    getIdTintaForzada() {
      let tipoTinta = this.forzarTipoTinta;
      switch (tipoTinta) {
        case 1: // Elastano
          return 2;
        case 2: // Vinilo
          return 6;
        case 3: // Tinta al agua
          return 3;
        case 4: // Tinta de descarga
          return 4;
        case 5: // Tintas especiales (Oro, Plata, etc)
          return 1;
        default:
          return undefined;
      }
    },
    tintasDisponibles() {
      let tintas = [];
      // if modelo starts with "CB"
      if (this.modelInfoFromCatalog.code.startsWith("CB")) {
        tintas = [
          { text: this.$t("configuracion.Tintas.Tinta6"), value: 6 },
        ];
      } else if (!this.contieneElastano)
        tintas = [
          {
            text: this.$t("configuracion.Tintas.Tinta1"),
            value: 1,
          },
          {
            text: this.$t("configuracion.Tintas.Tinta2"),
            value: 2,
          },
          { text: this.$t("configuracion.Tintas.Tinta3"), value: 3 },
          {
            text: this.$t("configuracion.Tintas.Tinta5"),
            value: 5,
          },
          { text: this.$t("configuracion.Tintas.Tinta6"), value: 6 },
        ];
      else
        tintas = [
          {
            text: this.$t("configuracion.Tintas.Tinta2"),
            value: 2,
          },
          { text: this.$t("configuracion.Tintas.Tinta3"), value: 3 },
        ];

      if (
        this.forzarTipoTinta != null &&
        this.forzarTipoTinta > 0
      ) {
        let tinta = tintas.find((t) => t.value == this.getIdTintaForzada());
        if (tinta != null) {
          tintas = [tinta];
        }
      }
      return tintas;
    },
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
