import state from './ConfigState.js'
import mutations from './ConfigMutations.js'
import actions from './ConfigActions.js'
import getters from './ConfigGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
