<template>
  <v-container fluid class="px-0 pt-0 px-md-5">
    <alerta
      :dialog="alertActive"
      :titulo="alertError.title"
      :boton-cerrar="$t('confirm.Cerrar')"
      :cuerpo="alertError.body"
      :type-error="alertError"
      @cerrar="alertActive = false"
    ></alerta>
    <v-row>
      <v-col
        key="cotizador-main"
        class="scrollable-column"
        cols="12"
        md="5"
        lg="3"
      >
        <v-container fluid class="px-0 px-md-5 left-column">
          <v-row>
            <v-col class="d-flex d-md-block justify-center">
              <b class="model-title">
                <template v-if="modelCode">
                  {{ $t(`MODELS.${modelCode}.NAME`) }}
                  <span class="slug">{{ modelCode }}</span>
                </template>
                <template v-else>
                  {{ $t("general.Selecciona un modelo") }}
                </template>
              </b>
            </v-col>
          </v-row>
          <v-row v-if="false">
            <v-col
              class="d-flex justify-center pa-0 mt-3 mt-sm-0"
              cols="12"
              md="6"
            >
              <productos v-if="false" @close="openSelectorZone" />
            </v-col>
            <v-col
              class="d-flex justify-center pa-0 mt-3 mt-sm-0"
              cols="12"
              md="6"
            >
              <reprocesos-generales class="pl-md-3 w-100" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="product-list">
              <v-expansion-panels v-model="showProductsInput">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    :class="{
                      'animated-expandable-header': totalUnidades == 0
                    }"
                    :expand-icon="
                      showProductsInput != 0 ? '$fi-plus' : '$fi-minus'
                    "
                  >
                    {{ $t("general.sizeColorQuantity") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <list-product-input @changeColor="selectColor" @close="closeShowProducts" />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="reprocesos-generales-btn">
              <reprocesos-generales expandable-style-btn class="w-100" />
            </v-col>
          </v-row>
          <v-row v-if="modelPrice">
            <v-col class="d-flex flex-column px-md-0 px-8">
              <span class="color-selector-title">
                {{ $t("general.Color") }}:
                <span class="color-selected">
                  {{
                    hasColorSelected
                      ? $t(`COLORS.${color.code}`).toUpperCase()
                      : ""
                  }}
                </span>
              </span>
              <div class="d-flex justify-start mt-3">
                <ColorBall
                  v-for="c in sortColorAvalibleAndWithQuantity"
                  :key="'colorBallMain-' + c.code"
                  circle-class=""
                  :hexcode="c.url ? c.url : c.hexcode"
                  :quantity="c.quantity"
                  tooltip
                  :selected="hasColorSelected && color.code == c.code"
                  :label="c.code"
                  @changeColor="selectColor(c.code)"
                >
                  <span>
                    <u>{{ $t(`COLORS.${c.code}`).toUpperCase() }}</u>
                  </span>
                  <div
                    v-for="sizeQuantity in quantityByColorAndProduct[c.code]"
                    :key="sizeQuantity.size"
                  >
                    <span>
                      {{ $t(`SIZES.${sizeQuantity.size}`) }}:
                      {{ sizeQuantity.quantity }}
                    </span>
                  </div>
                </ColorBall>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-0">
              <span class="head-seleccionar-zona">
                {{ $t("general.selectZonaMarcaje") }}
              </span>
            </v-col>
          </v-row>
          <v-row class="scrollable-row">
            <v-col class="mh-100 pa-md-0">
              <zonas-tabs
                :init-zones="initZones"
                @updateSelectedZone="updateZone"
                @close-dialog="initZones = false"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        class="media-column pa-0"
        :class="{youReprocesosMedia: isYouReprocesos}"
        cols="12"
        md="7"
        :lg="showCotizacion ? 6 : 9"
      >
        <v-row v-if="!hasValidInitState" key="loading">
          <v-col
            class="d-flex justify-center align-center flex-column mt-12 text-uppercase"
          >
            <v-progress-circular
              v-if="waitExtranet"
              :rotate="360"
              :size="100"
              :width="10"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-icon
              v-else-if="!modelPrice && !waitExtranet"
              class="animated"
              size="85"
            >
              mdi-arrow-up-bold-circle-outline
            </v-icon>
            <v-icon v-else class="animated-left" size="85">
              mdi-arrow-left-bold-circle-outline
            </v-icon>

            <b>
              <span v-if="waitExtranet" class="mt-4">
                {{ $t("initMessages.conectWithExtranet") }}
              </span>
              <span v-else-if="!modelPrice && !waitExtranet" class="mt-4">
                {{ $t("initMessages.noModel") }}
              </span>
              <span v-else-if="modelPrice && totalUnidades == 0" class="mt-4">
                {{ $t("initMessages.noUnits") }}
              </span>
              <span
                v-else-if="modelPrice && totalUnidades > 0 && !hasColorSelected"
                class="mt-4"
              >
                {{ $t("initMessages.noColors") }}
              </span>
              <span
                v-else-if="
                  modelPrice && totalUnidades > 0 && hasColorSelected && !zona
                "
                class="mt-4"
              >
                {{ $t("initMessages.noZone") }}
              </span>
            </b>
          </v-col>
        </v-row>
        <v-row v-else key="designer-3d">
          <v-col>
            <mediaModel
              v-if="zona && color"
              :active="hasValidInitState"
              :color="color"
              :zona="zona"
            />
            <v-btn
              color="white"
              class="floating-toggle"
              icon
              @click="showCotizacion = !showCotizacion"
            >
              <v-icon>
                {{ !showCotizacion ? "$fi-arrow-left" : "$fi-arrow-right" }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        v-show="showCotizacion"
        key="cotizacion-column"
        class="mt-lg-0 mt-8"
        cols="12"
        lg="3"
      >
        <v-container fluid class="px-0 px-md-5 large-container-cotizador">
          <v-row v-if="!isYouReprocesos">
            <v-col>
              <cart-modal />
            </v-col>
          </v-row>
          <v-row v-if="!isYouReprocesos && activeBorradores">
            <v-col>
              <cotizador-btn disabled content-class="w-100">
                <v-icon size="25">$fi-plus</v-icon>
                {{ $t("draftLiteral.nuevoPresupuesto") }}
              </cotizador-btn>
            </v-col>
          </v-row>
          <v-row v-if="!isYouReprocesos && activeBorradores">
            <v-col cols="12" md="6">
              <cotizador-btn disabled content-class="w-100">
                {{ $t("draftLiteral.loadDraft") }}
              </cotizador-btn>
            </v-col>
            <v-col cols="12" md="6">
              <cotizador-btn disabled content-class="w-100">
                {{ $t("draftLiteral.saveDraft") }}
              </cotizador-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex flex-column">
              <cotizacion id="carrito" @reload="reiniciar" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Productos from "@/components/cotizadorClientes/Productos.vue";
import ReprocesosGenerales from "@/components/tecnicas/ReprocesosGenerales.vue";
import Cotizacion from "@/components/cotizadorClientes/Cotizacion.vue";
import mediaModel from "@/components/cotizadorClientes/mediaModel.vue";
import cartModal from "@/components/modales/cartModal.vue";
import zonasTabs from "@/components/cotizadorClientes/zonasTabs.vue";
import alerta from "@/components/modales/alert.vue";

import ColorBall from "@/components/basic/ColorBall.vue";
import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";
import listProductInput from "@/components/cotizadorClientes/listProductInput.vue";

export default {
  components: {
    Productos,
    ReprocesosGenerales,
    Cotizacion,
    ColorBall,
    mediaModel,
    CotizadorBtn,
    cartModal,
    zonasTabs,
    alerta,
    listProductInput
  },
  data() {
    return {
      color: null,
      zona: null,
      cartModalDialog: false,
      alertActive: false,
      alertError: {},
      initZones: false,
      showCotizacion: true,
      showProductsInput: 0
    };
  },
  computed: {
    ...mapGetters("modelo", [
      "modelPrice",
      "modelCode",
      "colorAvalible",
      "actualAlert",
      "waitExtranet"
    ]),
    ...mapGetters("carrito", [
      "totalUnidades",
      "sortColorAvalibleAndWithQuantity",
      "quantityByColorAndProduct",
      "listColorInCart"
    ]),
    ...mapGetters("config", ["isYouReprocesos", "activeBorradores"]),
    // Indica si se ha seleccionado un color o no
    hasColorSelected() {
      return this.color && this.color.code;
    },
    // Comprobación si debemos cargar el modelo 3D
    hasValidInitState() {
      return !(this.totalUnidades == 0 || !this.hasColorSelected || !this.zona);
    }
  },
  watch: {
    actualAlert(newVal) {
      this.alertError = newVal;
      this.alertActive = newVal && (newVal.body || newVal.code) ? true : false;
    },
    alertActive: function (newVal, oldVal) {
      if (!newVal && newVal != oldVal) {
        this.clearAlert();
      }
    },
    sortColorAvalibleAndWithQuantity(newVal) {
      if (newVal.length > 0 && !this.color) {
        this.color = newVal[0];
        this.setColor(newVal[0].code);
      }
    },
    totalUnidades(newVal, oldVal) {
      if (newVal <= 0 && oldVal > 0) {
        this.zona = null;
        if (this.totalUnidades <= 0) this.initZones = false;
        this.showProductsInput = 0;
      }
    },
    modelCode(newVal) {
      if (newVal) {
        this.color = null;
        this.zona = null;
        if (this.totalUnidades <= 0) this.initZones = false;
        this.showProductsInput = 0;
      }
    }
  },
  methods: {
    ...mapActions("config", ["setColor"]),
    ...mapActions("modelo", ["clearAlert"]),
    // Para reiniciar la calculadora
    reiniciar() {
      this.$emit("reload");
    },
    closeShowProducts() {

      this.showProductsInput = null;

      if(!this.zona) {
        this.initZones = true;
      }
    },
    // Para seleccionar un nuevo color para previsualizar
    selectColor(colorCode) {
      console.log(
        "🚀 ~ file: NewCotizador.vue:350 ~ selectColor ~ colorCode:",
        colorCode,
        this.listColorInCart
      );
      if (
        this.color?.code === colorCode ||
        !this.listColorInCart.includes(colorCode)
      )
        return;

      let color = this.colorAvalible.find(c => c.code === colorCode);
      if (!color) return;
      this.color = color;
      this.setColor(colorCode);
    },
    // Método para actualizar la zona
    updateZone(zone) {
      console.log("🚀 ~ updateZone ~ zone:", zone);
      this.zona = zone;
      this.showProductsInput = null;
    },
    openSelectorZone() {
      this.initZones = true;
      console.log("🚀 ~ openSelectorZone ~ this.initZones:", this.initZones);
    }
  }
};
</script>

<style lang="scss">

.container--fluid {
  max-width: 1900px !important;
}


.model-title {
  font-size: 30px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin-bottom: 1rem;
  font-family: $heading-font-family;

  .slug {
    font-size: 19px;
    font-weight: 400;
  }
}

.color-selector-title {
  font-size: 12px;
  font-weight: 400;
  .color-selected {
    font-weight: bold;
  }
}

.head-seleccionar-zona {
  color: #000;
  font-family: $heading-font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.v-icon.animated {
  animation: up-move 1s linear infinite;
  border-radius: 55px;

  &-left {
    animation: left-move 1s linear infinite;
  }
}

.media-column,
.cotizacion-column {
  transition: all 0.5s ease-in-out;
}

.product-list {
  padding-left: 0;
  padding-right: 0;

  .v-expansion-panel {
    border-radius: 8px;
    border: 1px solid var(--Color-Grey-400, #c9c9c9);

    button.v-expansion-panel-header {
      color: var(--Color-Grey-600, #787878);
      font-family: $heading-font-family;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      padding: 8px 12px;
      text-transform: uppercase;
      border-radius: 8px;

      transition: all 0.3s;

      &.v-expansion-panel-header--active {
        border-bottom: 0px;
        border-radius: 8px 8px 0px 0px;
      }

      &:not(.v-expansion-panel-header--active):hover {
        // background: var(--v-colorBtn-lighten5);
        // background: rgba(var(--v-colorBtn-base), 0.7);
        background: color-mix(in srgb, var(--v-colorBtn-base) 50%, #f9f9f9);
      }

      &.animated-expandable-header:not(.v-expansion-panel-header--active) {
        animation: blink-primary 2s linear infinite;
      }
    }

    .v-expansion-panel-content__wrap {
      padding: 12px 20px;

      .title-table-header {
        color: var(--Color-Grey-600, #787878);
        font-family: $heading-font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        height: 25px;
        border: none !important;
        text-align: left;
        padding-left: 0px;
      }
    }
  }
}

.reprocesos-generales-btn {
  padding: 0px;
}

.media-column {
  position: relative;
  &.youReprocesosMedia::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--v-youReprocesos-logo-url, "");
    background-position: center;
    opacity: 0.3;
    // z-index: -1;
  }
  .floating-toggle {
    position: absolute;
    top: 25px;
    right: 0px;
    background: black;

    .v-icon span {
      margin-bottom: 0px !important;
    }
  }
}

.large-container-cotizador {
  min-height: 641px;

  .col,
  .col-12 {
    padding: 0px;
    padding-top: 12px;

    &:not(:last-child) {
      padding-right: 12px;
    }
  }

  .cotizador-btn {
    min-height: 40px;
  }

  .cotizacion-cart {
    min-height: 461px;
  }
}

// Animación para mover el icono hacia arriba y hacia abajo
@keyframes up-move {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

// Animación para mover el icono hacia la izquierda y hacia la derecha
@keyframes left-move {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}

// .left-column {
//   display: flex;
//   flex-direction: column;
//   height: calc(100vh - 110px);

//   .row {
//     flex: 0 1 auto;
//   }

//   .row.scrollable-row {
//     flex: 1;
//     overflow: hidden;
//   }
// }
</style>
