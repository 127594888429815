import state from './CarritoState.js'
import mutations from './CarritoMutations.js'
import actions from './CarritoActions.js'
import getters from './CarritoGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
