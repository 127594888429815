const paises = [
    { id: '', label: '' },
    { id: 'Afghanistan', label: 'Afghanistan' },
    { id: 'Åland Islands', label: 'Åland Islands' },
    { id: 'Albania', label: 'Albania' },
    { id: 'Algeria', label: 'Algeria' },
    { id: 'American Samoa', label: 'American Samoa' },
    { id: 'Andorra', label: 'Andorra' },
    { id: 'Angola', label: 'Angola' },
    { id: 'Anguilla', label: 'Anguilla' },
    { id: 'Antarctica', label: 'Antarctica' },
    { id: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
    { id: 'Antilles', label: 'Antilles' },
    { id: 'Argentina', label: 'Argentina' },
    { id: 'Armenia', label: 'Armenia' },
    { id: 'Aruba', label: 'Aruba' },
    { id: 'Australia', label: 'Australia' },
    { id: 'Austria', label: 'Austria' },
    { id: 'Azerbaijan', label: 'Azerbaijan' },
    { id: 'Bahamas', label: 'Bahamas' },
    { id: 'Bahrain', label: 'Bahrain' },
    { id: 'Bangladesh', label: 'Bangladesh' },
    { id: 'Barbados', label: 'Barbados' },
    { id: 'Belarus', label: 'Belarus' },
    { id: 'Belgium', label: 'Belgium' },
    { id: 'Belize', label: 'Belize' },
    { id: 'Benin', label: 'Benin' },
    { id: 'Bermuda', label: 'Bermuda' },
    { id: 'Bhutan', label: 'Bhutan' },
    {
        id: 'Bolivia, Plurinational State of',
        label: 'Bolivia, Plurinational State of',
    },
    {
        id: 'Bonaire, Sint Eustatius and Saba',
        label: 'Bonaire, Sint Eustatius and Saba',
    },
    {
        id: 'Bosnia and Herzegovina',
        label: 'Bosnia and Herzegovina',
    },
    { id: 'Botswana', label: 'Botswana' },
    { id: 'Bouvet Island', label: 'Bouvet Island' },
    { id: 'Brazil', label: 'Brazil' },
    {
        id: 'British Indian Ocean Territory',
        label: 'British Indian Ocean Territory',
    },
    { id: 'Brunei Darussalam', label: 'Brunei Darussalam' },
    { id: 'Bulgaria', label: 'Bulgaria' },
    { id: 'Burkina Faso', label: 'Burkina Faso' },
    { id: 'Burundi', label: 'Burundi' },
    { id: 'Cambodia', label: 'Cambodia' },
    { id: 'Cameroon', label: 'Cameroon' },
    { id: 'Canada', label: 'Canada' },
    { id: 'Cape Verde', label: 'Cape Verde' },
    { id: 'Cayman Islands', label: 'Cayman Islands' },
    {
        id: 'Central African Republic',
        label: 'Central African Republic',
    },
    { id: 'Chad', label: 'Chad' },
    { id: 'Chile', label: 'Chile' },
    { id: 'China', label: 'China' },
    { id: 'Christmas Island', label: 'Christmas Island' },
    {
        id: 'Cocos (Keeling) Islands',
        label: 'Cocos (Keeling) Islands',
    },
    { id: 'Colombia', label: 'Colombia' },
    { id: 'Comoros', label: 'Comoros' },
    { id: 'Congo', label: 'Congo' },
    {
        id: 'Congo, the Democratic Republic of the',
        label: 'Congo, the Democratic Republic of the',
    },
    { id: 'Cook Islands', label: 'Cook Islands' },
    { id: 'Costa Rica', label: 'Costa Rica' },
    { id: "Côte d'Ivoire", label: "Côte d'Ivoire" },
    { id: 'Croatia', label: 'Croatia' },
    { id: 'Cuba', label: 'Cuba' },
    { id: 'Curaçao', label: 'Curaçao' },
    { id: 'Cyprus', label: 'Cyprus' },
    { id: 'Czech Republic', label: 'Czech Republic' },
    { id: 'Denmark', label: 'Denmark' },
    { id: 'Djibouti', label: 'Djibouti' },
    { id: 'Dominica', label: 'Dominica' },
    { id: 'Dominican Republic', label: 'Dominican Republic' },
    { id: 'Ecuador', label: 'Ecuador' },
    { id: 'Egypt', label: 'Egypt' },
    { id: 'El Salvador', label: 'El Salvador' },
    { id: 'Equatorial Guinea', label: 'Equatorial Guinea' },
    { id: 'Eritrea', label: 'Eritrea' },
    { id: 'Estonia', label: 'Estonia' },
    { id: 'Ethiopia', label: 'Ethiopia' },
    {
        id: 'Falkland Islands (Malvinas)',
        label: 'Falkland Islands (Malvinas)',
    },
    { id: 'Faroe Islands', label: 'Faroe Islands' },
    { id: 'Fiji', label: 'Fiji' },
    { id: 'Finland', label: 'Finland' },
    { id: 'France', label: 'France' },
    { id: 'French Guiana', label: 'French Guiana' },
    { id: 'French Polynesia', label: 'French Polynesia' },
    {
        id: 'French Southern Territories',
        label: 'French Southern Territories',
    },
    { id: 'Gabon', label: 'Gabon' },
    { id: 'Gambia', label: 'Gambia' },
    { id: 'Georgia', label: 'Georgia' },
    { id: 'Germany', label: 'Germany' },
    { id: 'Ghana', label: 'Ghana' },
    { id: 'Gibraltar', label: 'Gibraltar' },
    { id: 'Greece', label: 'Greece' },
    { id: 'Greenland', label: 'Greenland' },
    { id: 'Grenada', label: 'Grenada' },
    { id: 'Guadeloupe', label: 'Guadeloupe' },
    { id: 'Guam', label: 'Guam' },
    { id: 'Guatemala', label: 'Guatemala' },
    { id: 'Guernsey', label: 'Guernsey' },
    { id: 'Guinea', label: 'Guinea' },
    { id: 'Guinea-Bissau', label: 'Guinea-Bissau' },
    { id: 'Guyana', label: 'Guyana' },
    { id: 'Haiti', label: 'Haiti' },
    {
        id: 'Heard Island and McDonald Islands',
        label: 'Heard Island and McDonald Islands',
    },
    {
        id: 'Holy See (Vatican City State)',
        label: 'Holy See (Vatican City State)',
    },
    { id: 'Honduras', label: 'Honduras' },
    { id: 'Hong Kong', label: 'Hong Kong' },
    { id: 'Hungary', label: 'Hungary' },
    { id: 'Iceland', label: 'Iceland' },
    { id: 'India', label: 'India' },
    { id: 'Indonesia', label: 'Indonesia' },
    {
        id: 'Iran, Islamic Republic of',
        label: 'Iran, Islamic Republic of',
    },
    { id: 'Iraq', label: 'Iraq' },
    { id: 'Ireland', label: 'Ireland' },
    { id: 'Isle of Man', label: 'Isle of Man' },
    { id: 'Israel', label: 'Israel' },
    { id: 'Italy', label: 'Italy' },
    { id: 'Jamaica', label: 'Jamaica' },
    { id: 'Japan', label: 'Japan' },
    { id: 'Jersey', label: 'Jersey' },
    { id: 'Jordan', label: 'Jordan' },
    { id: 'Kazakhstan', label: 'Kazakhstan' },
    { id: 'Kenya', label: 'Kenya' },
    { id: 'Kiribati', label: 'Kiribati' },
    {
        id: "Korea, Democratic People's Republic of",
        label: "Korea, Democratic People's Republic of",
    },
    { id: 'Korea, Republic of', label: 'Korea, Republic of' },
    { id: 'Kuwait', label: 'Kuwait' },
    { id: 'Kyrgyzstan', label: 'Kyrgyzstan' },
    {
        id: "Lao People's Democratic Republic",
        label: "Lao People's Democratic Republic",
    },
    { id: 'Latvia', label: 'Latvia' },
    { id: 'Lebanon', label: 'Lebanon' },
    { id: 'Lesotho', label: 'Lesotho' },
    { id: 'Liberia', label: 'Liberia' },
    { id: 'Libya', label: 'Libya' },
    { id: 'Liechtenstein', label: 'Liechtenstein' },
    { id: 'Lithuania', label: 'Lithuania' },
    { id: 'Luxembourg', label: 'Luxembourg' },
    { id: 'Macao', label: 'Macao' },
    {
        id: 'Macedonia, the former Yugoslav Republic of',
        label: 'Macedonia, the former Yugoslav Republic of',
    },
    { id: 'Madagascar', label: 'Madagascar' },
    { id: 'Malawi', label: 'Malawi' },
    { id: 'Malaysia', label: 'Malaysia' },
    { id: 'Maldives', label: 'Maldives' },
    { id: 'Mali', label: 'Mali' },
    { id: 'Malta', label: 'Malta' },
    { id: 'Marshall Islands', label: 'Marshall Islands' },
    { id: 'Martinique', label: 'Martinique' },
    { id: 'Mauritania', label: 'Mauritania' },
    { id: 'Mauritius', label: 'Mauritius' },
    { id: 'Mayotte', label: 'Mayotte' },
    { id: 'Mexico', label: 'Mexico' },
    {
        id: 'Micronesia, Federated States of',
        label: 'Micronesia, Federated States of',
    },
    { id: 'Moldova, Republic of', label: 'Moldova, Republic of' },
    { id: 'Monaco', label: 'Monaco' },
    { id: 'Mongolia', label: 'Mongolia' },
    { id: 'Montenegro', label: 'Montenegro' },
    { id: 'Montserrat', label: 'Montserrat' },
    { id: 'Morocco', label: 'Morocco' },
    { id: 'Mozambique', label: 'Mozambique' },
    { id: 'Myanmar', label: 'Myanmar' },
    { id: 'Namibia', label: 'Namibia' },
    { id: 'Nauru', label: 'Nauru' },
    { id: 'Nepal', label: 'Nepal' },
    { id: 'Netherlands', label: 'Netherlands' },
    { id: 'New Caledonia', label: 'New Caledonia' },
    { id: 'New Zealand', label: 'New Zealand' },
    { id: 'Nicaragua', label: 'Nicaragua' },
    { id: 'Niger', label: 'Niger' },
    { id: 'Nigeria', label: 'Nigeria' },
    { id: 'Niue', label: 'Niue' },
    { id: 'Norfolk Island', label: 'Norfolk Island' },
    {
        id: 'Northern Mariana Islands',
        label: 'Northern Mariana Islands',
    },
    { id: 'Norway', label: 'Norway' },
    { id: 'Oman', label: 'Oman' },
    { id: 'Pakistan', label: 'Pakistan' },
    { id: 'Palau', label: 'Palau' },
    {
        id: 'Palestinian Territory, Occupied',
        label: 'Palestinian Territory, Occupied',
    },
    { id: 'Panama', label: 'Panama' },
    { id: 'Papua New Guinea', label: 'Papua New Guinea' },
    { id: 'Paraguay', label: 'Paraguay' },
    { id: 'Peru', label: 'Peru' },
    { id: 'Philippines', label: 'Philippines' },
    { id: 'Pitcairn', label: 'Pitcairn' },
    { id: 'Poland', label: 'Poland' },
    { id: 'Portugal', label: 'Portugal' },
    { id: 'Puerto Rico', label: 'Puerto Rico' },
    { id: 'Qatar', label: 'Qatar' },
    { id: 'Réunion', label: 'Réunion' },
    { id: 'Romania', label: 'Romania' },
    { id: 'Russian Federation', label: 'Russian Federation' },
    { id: 'Rwanda', label: 'Rwanda' },
    { id: 'Saint Barthélemy', label: 'Saint Barthélemy' },
    {
        id: 'Saint Helena, Ascension and Tristan da Cunha',
        label: 'Saint Helena, Ascension and Tristan da Cunha',
    },
    { id: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
    { id: 'Saint Lucia', label: 'Saint Lucia' },
    {
        id: 'Saint Martin (French part)',
        label: 'Saint Martin (French part)',
    },
    {
        id: 'Saint Pierre and Miquelon',
        label: 'Saint Pierre and Miquelon',
    },
    {
        id: 'Saint Vincent and the Grenadines',
        label: 'Saint Vincent and the Grenadines',
    },
    { id: 'Samoa', label: 'Samoa' },
    { id: 'San Marino', label: 'San Marino' },
    { id: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
    { id: 'Saudi Arabia', label: 'Saudi Arabia' },
    { id: 'Senegal', label: 'Senegal' },
    { id: 'Serbia', label: 'Serbia' },
    { id: 'Seychelles', label: 'Seychelles' },
    { id: 'Sierra Leone', label: 'Sierra Leone' },
    { id: 'Singapore', label: 'Singapore' },
    {
        id: 'Sint Maarten (Dutch part)',
        label: 'Sint Maarten (Dutch part)',
    },
    { id: 'Slovakia', label: 'Slovakia' },
    { id: 'Slovenia', label: 'Slovenia' },
    { id: 'Solomon Islands', label: 'Solomon Islands' },
    { id: 'Somalia', label: 'Somalia' },
    { id: 'South Africa', label: 'South Africa' },
    {
        id: 'South Georgia and the South Sandwich Islands',
        label: 'South Georgia and the South Sandwich Islands',
    },
    { id: 'South Sudan', label: 'South Sudan' },
    { id: 'Spain', label: 'Spain' },
    { id: 'Sri Lanka', label: 'Sri Lanka' },
    { id: 'Sudan', label: 'Sudan' },
    { id: 'Suriname', label: 'Suriname' },
    {
        id: 'Svalbard and Jan Mayen',
        label: 'Svalbard and Jan Mayen',
    },
    { id: 'Swaziland', label: 'Swaziland' },
    { id: 'Sweden', label: 'Sweden' },
    { id: 'Switzerland', label: 'Switzerland' },
    { id: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
    {
        id: 'Taiwan, Province of China',
        label: 'Taiwan, Province of China',
    },
    { id: 'Tajikistan', label: 'Tajikistan' },
    {
        id: 'Tanzania, United Republic of',
        label: 'Tanzania, United Republic of',
    },
    { id: 'Thailand', label: 'Thailand' },
    { id: 'Timor-Leste', label: 'Timor-Leste' },
    { id: 'Togo', label: 'Togo' },
    { id: 'Tokelau', label: 'Tokelau' },
    { id: 'Tonga', label: 'Tonga' },
    { id: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
    { id: 'Tunisia', label: 'Tunisia' },
    { id: 'Turkey', label: 'Turkey' },
    { id: 'Turkmenistan', label: 'Turkmenistan' },
    {
        id: 'Turks and Caicos Islands',
        label: 'Turks and Caicos Islands',
    },
    { id: 'Tuvalu', label: 'Tuvalu' },
    { id: 'Uganda', label: 'Uganda' },
    { id: 'Ukraine', label: 'Ukraine' },
    { id: 'United Arab Emirates', label: 'United Arab Emirates' },
    { id: 'United Kingdom', label: 'United Kingdom' },
    { id: 'United States', label: 'United States' },
    {
        id: 'United States Minor Outlying Islands',
        label: 'United States Minor Outlying Islands',
    },
    { id: 'Uruguay', label: 'Uruguay' },
    { id: 'Uzbekistan', label: 'Uzbekistan' },
    { id: 'Vanuatu', label: 'Vanuatu' },
    {
        id: 'Venezuela, Bolivarian Republic of',
        label: 'Venezuela, Bolivarian Republic of',
    },
    { id: 'Viet Nam', label: 'Viet Nam' },
    {
        id: 'Virgin Islands, British',
        label: 'Virgin Islands, British',
    },
    { id: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
    { id: 'Wallis and Futuna', label: 'Wallis and Futuna' },
    { id: 'Western Sahara', label: 'Western Sahara' },
    { id: 'Yemen', label: 'Yemen' },
    { id: 'Zambia', label: 'Zambia' },
    { id: 'Zimbabwe', label: 'Zimbabwe' },
];

export {
    paises
}