
/* Calcula el precio en función de la cantidad introducida */
const Shops = Object.freeze({
    ROLY: "ROL",
    STAMINA: "STA",
    ROLY_AND_STAMINA: "ROLSTA",


    //Antiguos códigos de tienda. Se han de reemplazar por los nuevos.
    STAMINA_LEGACY: "STM",
    ROLY_LEGACY: "GOR",
    ROLY_AND_STAMINA_LEGACY: "GORSTM"
});


/* Calcula el precio en función de la cantidad introducida */
export const dynamicPrice = (prices, currencyName, model, productCode, quantity, lang, priceNoIncr, aplicaPack, hasRangePrices) => {
    const params = [prices, currencyName, model, productCode, quantity, lang, priceNoIncr, aplicaPack, hasRangePrices];

    if (params.some(param => param == null || param == undefined)) {
        console.error("ERROR DYNAMICPRICE: Faltan parámetros o algunos son inválidos.");
        return undefined;
    }

    let isOutletProduct = model.products[productCode].OUTLET;
    console.log("🚀 ~ dynamicPrice ~ isOutletProduct:", isOutletProduct)
    const pricesGor = (model.originalShop ? model.originalShop == Shops.ROLY : model.shop == Shops.ROLY) || !hasRangePrices || isOutletProduct;
    console.log("🚀 ~ dynamicPrice ~ pricesGor:", pricesGor)
    const pricesStm = model.originalShop ? model.originalShop == Shops.STAMINA : model.shop == Shops.STAMINA;    

    if (pricesGor && model != null && prices != null && productCode != null && quantity != null) {
        console.log("🚀 ~ dynamicPrice ~ prices:", prices)
        if ((prices.PRICELIST)) {
            let extracost = 0;
            if (priceNoIncr == 0 && quantity) {

                if (aplicaPack == 1) {
                    if (quantity < model.products[productCode].PACK) {
                        extracost = prices.PRICELIST[productCode][currencyName].UNITSINCREMENT;
                    }
                }
                else {
                    if (quantity < model.products[productCode].PACK) {
                        extracost = prices.PRICELIST[productCode][currencyName].UNITSINCREMENT;
                    }
                    else if (model.products[productCode].PACK <= quantity && quantity < model.products[productCode].BOX) {
                        extracost = prices.PRICELIST[productCode][currencyName].PACKSINCREMENT;
                    }
                }
            }
            try {
                let actualPrice = prices.PRICELIST[productCode]?.[currencyName]?.PRICE ?? 0;
                console.log("🚀 ~ dynamicPrice ~ actualPrice:", actualPrice)
                if (actualPrice == 0) console.error("ERROR: Producto sin precios. ", productCode);
                return (actualPrice + actualPrice * (extracost / 100)).toLocaleString(lang, { minimumFractionDigits: 3, maximumFractionDigits: 4 });
            }
            catch (e) {
                console.error(productCode + " " + e,)
            }

        }
    }
    else if (pricesStm && prices.RANGEPRICE) {
        let actualRange = prices.RANGEPRICE[productCode];
        if (!actualRange) return null

        if (!quantity || quantity == 0)
            return parseFloat(actualRange.RANGES.RANGE_4.PRICE).toLocaleString(lang, { minimumFractionDigits: 3, maximumFractionDigits: 4 });
        if (productCode == "") {
            let firstProduct = Object.keys(model.products)[0];
            actualRange = prices.RANGEPRICE[firstProduct];
        }
        if (parseFloat(actualRange.RANGES.RANGE_1.MIN) <= quantity && quantity <= parseFloat(actualRange.RANGES.RANGE_1.MAX)) {
            return parseFloat(actualRange.RANGES.RANGE_1.PRICE).toLocaleString(lang, { minimumFractionDigits: 3, maximumFractionDigits: 4 });
        }
        if (parseFloat(actualRange.RANGES.RANGE_2.MIN) <= quantity && quantity <= parseFloat(actualRange.RANGES.RANGE_2.MAX)) {
            return parseFloat(actualRange.RANGES.RANGE_2.PRICE).toLocaleString(lang, { minimumFractionDigits: 3, maximumFractionDigits: 4 });
        }
        if (parseFloat(actualRange.RANGES.RANGE_3.MIN) <= quantity && quantity <= parseFloat(actualRange.RANGES.RANGE_3.MAX)) {
            return parseFloat(actualRange.RANGES.RANGE_3.PRICE).toLocaleString(lang, { minimumFractionDigits: 3, maximumFractionDigits: 4 });
        }
        return parseFloat(actualRange.RANGES.RANGE_4.PRICE).toLocaleString(lang, { minimumFractionDigits: 3, maximumFractionDigits: 4 });
    }
}


/* Obtiene los precios de un modelo */
export const modelPrices = (model, priceData, currencyName, hasRangePrices, outletOnly = false) => {
    if (!priceData) {
        return [];
    }

    let modelPrices = [];
    const pricesGor = (model.originalShop ? model.originalShop == Shops.ROLY : model.shop == Shops.ROLY) || !hasRangePrices;
    const pricesStm = model.originalShop ? model.originalShop == Shops.STAMINA : model.shop == Shops.STAMINA;
    if (outletOnly == null)
        outletOnly = false;

    Object.keys(model.products).filter(productCode => model.products[productCode].OUTLET == outletOnly).forEach(productCode => {
        if ((pricesGor && priceData.PRICELIST && priceData.PRICELIST[productCode]) || (pricesStm && priceData.PRICELIST && Object.keys(priceData.PRICELIST).length > 0)) {
            if (!priceData.PRICELIST[productCode]) console.error(`ERROR - No precio tarifa fija para el producto: ${productCode}`)
            let modelPrice = priceData.PRICELIST[productCode]?.[currencyName];
            if (modelPrice) {
                modelPrices.push({
                    product_code: productCode,
                    size: model.products[productCode].SIZE,
                    color: model.products[productCode].COLOR,
                    ...modelPrice,
                });
            }
        }
        else if (pricesStm && priceData.RANGEPRICE && priceData.RANGEPRICE[productCode]) {
            let actualRange = priceData.RANGEPRICE[productCode];
            if (actualRange) {
                modelPrices.push({
                    product_code: productCode,
                    size: model.products[productCode].SIZE,
                    color: model.products[productCode].COLOR,
                    PRICE: actualRange.RANGES.RANGE_4.PRICE,
                    discountSizeGroup: actualRange.RANGES.DSIZEGROUP || "",
                    discountColorGroup: actualRange.RANGES.DSIZEGROUP || "",
                    discountPercent: actualRange.RANGES.DPERCENT,
                    rangeColor: actualRange.RANGES.COLOR || "",
                    sizeGroup: actualRange.RANGES.SIZEGROUP || "",
                });
            }
        } else {
            console.error(`ERROR: Producto de ${(pricesGor ? 'Roly' : 'Stamina')} sin precios (talla: ${model.products[productCode].SIZE}) (color: ${model.products[productCode].COLOR}): ${model.code} ${productCode}`);
            modelPrices.push({
                product_code: productCode,
                size: model.products[productCode].SIZE,
                color: model.products[productCode].COLOR,
                PRICE: 0,
            });
        }
    })
    return modelPrices;
}

/* Obtiene los precios de adulto */
export const adultModelPrices = (modelPrices, sizeData) => {
    return modelPrices.filter((modelPrice) => {
        if (!sizeData[modelPrice.size]) console.error("ERROR - No existe la talla: ", modelPrice.size)
        return sizeData[modelPrice.size].CHILDREN === 0;
    });
}

/* Obtiene los precios de niño */
export const childModelPrices = (modelPrices, sizeData) => {
    return modelPrices.filter((modelPrice) => {
        if (!sizeData[modelPrice.size]) console.error("ERROR - No existe la talla: ", modelPrice.size)
        return sizeData[modelPrice.size].CHILDREN === 1;
    });
}

/* Obtiene el precio mínimo dado los precios de un modelo */
export const minModelPrice = (modelPrices) => {
    return Math.min(...modelPrices.map((p) => p.PRICE));
}

/* Obtiene el precio máximo dado precios de un modelo */
export const maxModelPrice = (modelPrices) => {
    return Math.max(...modelPrices.map((p) => p.PRICE));
}
/* Obtiene el precio mínimo de adulto */
export const minAdultModelPrice = (modelPrices, sizeData) => {
    return Math.min(...adultModelPrices(modelPrices, sizeData).map((p) => p.PRICE));
}
/* Obtiene el precio mínimo de niño */
export const minChildModelPrice = (modelPrices, sizeData) => {
    return Math.min(...childModelPrices(modelPrices, sizeData).map((p) => p.PRICE));
}
export const numProductByType = (model, rangePrices, modelProductQuantity, selectedAmounts) => {
    let numByType = new Map();
    const pricesStm = model.originalShop ? model.originalShop == Shops.STAMINA : model.shop == Shops.STAMINA;

    if (rangePrices && rangePrices.RANGEPRICE && pricesStm) {
        Object.keys(model.products).forEach(productCode => {
            let isOutletProduct = model.products[productCode].OUTLET;

            if (isOutletProduct) return;

            let actualRange = rangePrices.RANGEPRICE[productCode];
            let mapKey = [actualRange.RANGES.COLOR, actualRange.RANGES.SIZEGROUP].toString();
            if (actualRange && actualRange.RANGES) {
                if (numByType.has(mapKey)) {
                    numByType.set(mapKey, numByType.get(mapKey) + parseInt(selectedAmounts?.[productCode] ?? 0));
                }
                else {
                    numByType.set(mapKey, parseInt(selectedAmounts?.[productCode] ?? 0));
                }
            }
        });
        modelProductQuantity.forEach(modelProduct => {
            if (modelProduct.model == model.code) {
                let actualRange = rangePrices.RANGEPRICE[modelProduct.product];
                let mapKey = [actualRange.RANGES.COLOR, actualRange.RANGES.SIZEGROUP].toString();
                if (actualRange && actualRange.RANGES) {
                    if (numByType.has(mapKey)) {
                        numByType.set(mapKey, numByType.get(mapKey) + parseInt(modelProduct.quantity ?? 0));
                    }
                    else {
                        numByType.set(mapKey, parseInt(modelProduct.quantity ?? 0));
                    }
                }
            }
        })
    }
    return numByType;
}

export const groupedModelPrices = (modelPrices) => {
    // Agrupa los model prices por precio.
    let groupedModelPricesAux = {}
    modelPrices.forEach((modelPrice) => {
        //si no existe
        if (!groupedModelPricesAux[modelPrice.PRICE]) {
            groupedModelPricesAux[modelPrice.PRICE] = [modelPrice];
            //si existe 
        } else {
            groupedModelPricesAux[modelPrice.PRICE].push(modelPrice);
        }
    })
    return groupedModelPricesAux;
}

export const uniquePrices = (modelPrices) => {
    return Object.keys(groupedModelPrices(modelPrices))
}
export const uniquePricesCount = (modelPrices) => {
    return uniquePrices(modelPrices).length
}

export const uniqueChildPrices = (modelPrices, sizeData) => {
    return Object.keys(groupedModelPrices(childModelPrices(modelPrices, sizeData)))
}
export const uniqueChildPricesCount = (modelPrices, sizeData) => {
    return uniqueChildPrices(modelPrices, sizeData).length
}

export const uniqueAdultPrices = (modelPrices, sizeData) => {
    return Object.keys(groupedModelPrices(adultModelPrices(modelPrices, sizeData)))
}
export const uniqueAdultPricesCount = (modelPrices, sizeData) => {
    return uniqueAdultPrices(modelPrices, sizeData).length
}


