import Vue from 'vue'
import './plugins/axios'
import App3D from './App3D.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import i18n from './i18n'
import router from './router'
// import PerfectScrollbar from 'vue2-perfect-scrollbar'
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

// Importamos las clases de utilidad que hemos ido creando en scss
import "@/static/scss/_utilities.scss";
import "@/static/scss/_scrollbars.scss";

Vue.config.productionTip = false

function redondear(value, decimales) {
  if (!value) return (0).toFixed(decimales);
  var signo = (value >= 0 ? 1 : -1);
  value = value * signo;
  if (decimales === 0) //con 0 decimales
    return signo * Math.round(value);
  // round(x * 10 ^ decimales)
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + decimales) : decimales)));
  // x * 10 ^ (-decimales)
  value = value.toString().split('e');
  value = signo * (value[0] + 'e' + (value[1] ? (+value[1] - decimales) : -decimales));
  return value.toFixed(decimales);
}

Vue.filter('filtrarPrecios', function (value) {
  return redondear(value, 4);
});

Vue.filter('filtrarPreciosWithPad', function (value) {
  return redondear(value, 3);
});

Vue.filter('filtrarPreciosDosDecimales', function (value) {
  return redondear(value, 2);
});
// Vue.use(PerfectScrollbar)


Vue.directive('no-scroll-body', {
  inserted() {
    document.body.style.overflow = 'hidden';
  },
  unbind() {
    document.body.style.overflow = '';
  }
});

new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: h => h(App3D)
}).$mount('#app')
