<template>
  <div>
    <v-fade-transition>
      <v-overlay v-if="cargando" absolute>
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>

    <v-row>
      <v-col>
         <span class="text-subtitle-2">{{
          $t("configuracion.Seleccione un tamaño de la lista")
        }}</span>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{
            $t(
              "configuracion.El tamaño máximo para esta combinación de tallas es" 
            )+ ": " + (actualMaxValidSize ? actualMaxValidSize.text : $t("configuracion.No hay tamaños válidos"))
          }}</span>
        </v-tooltip>
        <v-select
          v-model="tamaño"
          :items="tamaños"
          filled
          :label="
            $t('configuracion.ImpresionVinilo.Tamaño del estampado')
          "
          color="primary darken-2"
          prepend-inner-icon="mdi-tape-measure"
          @change="actualGetMedidasByTamano"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="ancho"
          class="mt-2"
          suffix="mm"
          label="Ancho"
          hide-details="auto"
          type="number"
          color="primary darken-2"
          filled
          @change="getTamanoByMedidas"
        ></v-text-field>
        <span>{{ $t("configuracion.ImpresionVinilo.Max: 297mm") }}</span>
      </v-col>
      <v-col>
        <v-text-field
          v-model="alto"
          class="mt-2"
          suffix="mm"
          label="Alto"
          hide-details="auto"
          type="number"
          color="primary darken-2"
          filled
          @change="getTamanoByMedidas"
        ></v-text-field>
        <span>{{ $t("configuracion.ImpresionVinilo.Max: 420mm") }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <span class="subtitle-2">{{ $t("configuracion.Otros parámetros") }}</span>
      <br />

      <span class="d-flex">
        <v-checkbox
          v-model="planchado"
          :label="$t('configuracion.ImpresionVinilo.Planchado en prenda')"
          color="primary darken-2 white--text"
          class="mt-1 pt-1"
        >
        </v-checkbox>

        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon class="mt-1">mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{
            $t("configuracion.ImpresionVinilo.Planchado en prenda")
          }}</span>
        </v-tooltip>
      </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ImpresionVinilo",
  props: {
    color: Object,
    cantidad: Number,
    zona: Object,
    maxColores: {
      type: Number,
      default: 8,
    },
    id: Number
  },
  data() {
    return {
      tamaño: 11,
      ancho: 37,
      alto: 52,
      colores: 1,
      tinta: 5,
      planchado: false,
      cargando: false,
      productos2: [],
      tamaños: this.tamañosIniciales(),
    };
  },
  created() {
    this.tamaño = this.actualMaxValidSize?.value ?? 11;
    this.ancho = this.actualMaxValidSize?.Ancho;
    this.alto = this.actualMaxValidSize?.Alto;
    this.calcularPrecio();
  },
  computed: {
    ...mapGetters("carrito", ["carrito", "hasPackUno", "medidasMaximas"]),
    ...mapGetters("modelo", ["productos", "maxValidSize", "isReprocesosStm", "modelInfoFromCatalog"]),
    ...mapGetters("config", ["isProveedor", "getMedidasByTamano", "key"]),
    coloresDisponibles: function () {
      let colores = [];
      for (let i = 1; i <= this.maxColores; i++) {
        colores.push(i);
      }
      return colores;
    },
    actualMaxValidSize() {
      if (this.carrito.productos.length == 0) return null;
      return this.maxValidSize(this.tamaños, this.zona.Posicion)
    }
  },
  methods: {
    ...mapActions("carrito", [
      "calcularPrecios",
    ]),
    ...mapActions("modelo", ["addAlert"]),
    calcularPrecio: async function () {
      //process.env.VUE_APP_WEB_MARCADO/Reprocesos/getPreciosImpresionVinilo?codigoModelo=CA6502&impresiones=100&idTamImpresion=6&esPrendaPackUno=false&esPlanchado=true
      if (this.cantidad == 0) return;
      var excedido = this.medidasMaximas(this.zona.Posicion, this.ancho, this.alto);
      if (excedido && excedido.length > 0) {
        this.addAlert({
          title: this.$t("alert.errorTamEstampacionTitleDialog") + ' (' + this.$t("Tecnicas.IMPRESIÓN VINILO") + ")",
          listProduct: excedido,
          code: "errorTamEstampacion",
          tamEstX: this.ancho,
          tamEstY: this.alto,
          zone: this.zona,
          tamEstampacion: this.tamaños.find((t) => t.value == this.tamaño)
            ?.text,
        });
      }
      let parametros = {
        // Generales
        tecnica: "ImpresionVinilo",
        codigo: this.modelInfoFromCatalog.code,
        unidades: this.cantidad,
        posicion: this.zona.Posicion,
        zona: this.zona.Zona,
        isRepeticion: false,

        // Especificos

        atributos: JSON.stringify([
          {
            esPrendaPackUno: (this.id == 0 ? this.hasPackUno : false),
            esPlanchado: this.planchado,
            idTamImpresion: this.tamaño,
            isProveedor: this.isProveedor,
            maxWidth: this.ancho,
            maxHeight: this.alto,
          }
        ]),

        // identificador
        id: this.id
      };
      this.cargando = true;
      try {
        await this.calcularPrecios(parametros);
      } catch (error) {
        if(error != 'errorColorBanned')
          {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
      }

      this.cargando = false;
    },
    anchoInicial() {
      if (
        this.modelo != null &&
        this.modelo.info != null &&
        !this.isReprocesosStm
      )
        return 37;
      else return 37;
    },
    altoInicial() {
      if (
        this.modelo != null &&
        this.modelo.info != null &&
        !this.isReprocesosStm
      )
        return 52;
      else return 52;
    },
    tamañosIniciales: function () {
      if (
        this.modelo != null &&
        this.modelo.info != null &&
        !this.isReprocesosStm
      )
        return [
          { text: "A3", value: 5, Ancho: 297, Alto: 420 },
          { text: "A4", value: 6, Ancho: 210, Alto: 297 },
          { text: "A5", value: 7, Ancho: 148, Alto: 210 },
          { text: "A6", value: 8, Ancho: 105, Alto: 148 },
          { text: "A7", value: 9, Ancho: 74, Alto: 105 },
          { text: "A8", value: 10, Ancho: 52, Alto: 74 },
          { text: "A9", value: 11, Ancho: 37, Alto: 52 },
          //{ text: this.$t("configuracion.DTF.Metros lineales"), value: "AML" },
        ];
      else
        return [
          { text: "A5", value: 7, Ancho: 148, Alto: 210 },
          { text: "A6", value: 8, Ancho: 105, Alto: 148 },
          { text: "A7", value: 9, Ancho: 74, Alto: 105 },
          { text: "A9", value: 11, Ancho: 37, Alto: 52 },
        ];
    },
    tamañoInicial() {
      if (
        this.modelo != null &&
        this.modelo.info != null &&
        !this.isReprocesosStm
      )
        return 11;
      else return 11;
    },
    actualGetMedidasByTamano: function() {
      let tamano = this.tamaños.find((x) => x.value == this.tamaño).text;
      var result = this.getMedidasByTamano(tamano)
      this.ancho = result.Ancho;
      this.alto = result.Alto;
    },
    getTamanoByMedidas: function () {
      var valido = true;
      var mejorMedida = null;
      var menorArea = null;
      this.tamaños.forEach((element) => {
        if (element.Ancho < this.ancho || element.Alto < this.alto) valido;
        else {
          valido = true;
          let area = element.Ancho * element.Alto;
          if (mejorMedida == null) {
            mejorMedida = element.value;
            menorArea = area;
          } else if (area < menorArea) {
            mejorMedida = element.value;
            menorArea = area;
          }
        }
      });
      if (!valido) alert("Las medidas son superiores a la zona de marcado");
      else {
        this.tamaño = mejorMedida;
      }
    },
  },
  watch: {
    tamaño: function () {
      this.calcularPrecio();
    },
    cantidad: function () {
      this.calcularPrecio();
    },
    planchado: function () {
      this.calcularPrecio();
    },
    modelo: function () {
      this.calcularPrecio();
    },
    zona: function () {
      this.calcularPrecio();
    },
    isProveedor: function () {
      this.calcularPrecio();
    },
    key: function () {
      this.calcularPrecio();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
