export const avalibleLanguages = [{
    code: 'en',
    name: 'English',
    iso: 'en-GB',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/gb.svg`,
    file: 'en.json',
    // rolyDomain: 'roly.co.uk',
    // stmDomain: 'stamina-shop.co.uk'
},
{
    code: 'es',
    name: 'Español',
    iso: 'es-ES',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/es.svg`,
    file: 'es.json',
    // rolyDomain: 'www.roly.es',
    // stmDomain: 'stamina-shop.com'
},
{
    code: 'fr',
    name: 'Français',
    iso: 'fr-FR',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/fr.svg`,
    file: 'fr.json',
    // rolyDomain: 'rolyshop.fr',
    // stmDomain: 'stamina-shop.fr'
},
{
    code: 'ca',
    name: 'Catalá',
    iso: 'ca-ES',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/ca.svg`,
    file: 'ca.json',
    // rolyDomain: 'www.roly.es',
    // stmDomain: 'stamina-shop.com'
},
// {
//   code: 'cl',
//   name: 'Chileno',
//   iso: 'es-CL',
//   flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/cl.png`,
//   file: 'cl.json'
// },
// {
//   code: 'mx',
//   name: 'Mexicano',
//   iso: 'es-MX',
//   flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/mx.png`,
//   file: 'mx.json'
// },
// {
//   code: 'gl',
//   name: 'galego',
//   iso: 'gl-ES',
//   flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/gl.png`"
//   file: 'gl.json'
// },
{
    code: 'au',
    name: 'Australian',
    iso: 'en-AU',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/au.svg`,
    file: 'au.json',
},
{
    code: 'cz',
    name: 'Český',
    iso: 'cs-CZ',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/cz.svg`,
    file: 'cz.json',
},
{
    code: 'de',
    name: 'Deutsch',
    iso: 'de-DE',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/de.svg`,
    file: 'de.json',
    // rolyDomain: 'www.rolyshop.de',
    // stmDomain: 'stamina-shop.de'
},
{
    code: 'nl',
    name: 'Dutch',
    iso: 'nl-NL',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/nl.svg`,
    file: 'nl.json',
    rolyDomain: 'roly.nl', //no existe en new, cambiar por el original.
},
{
    code: 'hr',
    name: 'Hrvatski',
    iso: 'hr-HR',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/hr.svg`,
    file: 'hr.json',
},
{
    code: 'it',
    name: 'Italiano',
    iso: 'it-IT',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/it.svg`,
    file: 'it.json',
    // rolyDomain: 'roly.it',
    // stmDomain: 'stamina-shop.it'
},
{
    code: 'mk',
    name: 'Македонски',
    iso: 'mk-MK',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/mk.svg`,
    file: 'mk.json',
},
// {
//   code: 'no',
//   name: 'norsk',
//   iso: 'nb-NO',
//   flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/no.png`,
//   file: 'no.json',
// },
{
    code: 'lv',
    name: 'Latviešu',
    iso: 'lv-LV',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/lv.svg`,
    file: 'lv.json',
    rolyDomain: 'roly.lv', //no existe en new, cambiar por el original.
},
{
    code: 'lt',
    name: 'Lietuviškai',
    iso: 'lt-LT',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/lt.svg`,
    file: 'lt.json',
},
{
    code: 'hu',
    name: 'Magyar',
    iso: 'hu-HU',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/hu.svg`,
    file: 'hu.json',
},
{
    code: 'pl',
    name: 'Polski',
    iso: 'pl-PL',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/pl.svg`,
    file: 'pl.json',
    // rolyDomain: 'roly.pl',
},
{
    code: 'pt',
    name: 'Portugues',
    iso: 'pt-PT',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/pt.svg`,
    file: 'pt.json',
},
{
    code: 'ru',
    name: 'Pусский',
    iso: 'ru-RU',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/ru.svg`,
    file: 'ru.json',
},
{
    code: 'ro',
    name: 'Română',
    iso: 'ro-RO',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/ro.svg`,
    file: 'ro.json',
    // rolyDomain: 'roly.ro',
},
{
    code: 'si',
    name: 'Slovenščina',
    iso: 'sl-SI',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/si.svg`,
    file: 'si.json',
    // rolyDomain: 'roly.si',
},
{
    code: 'sk',
    name: 'Slovenský',
    iso: 'sk-SK',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/sk.svg`,
    file: 'sk.json',
},
{
    code: 'ua',
    name: 'Yкраїнський',
    iso: 'uk-UA',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/ua.svg`,
    file: 'ua.json',
},
{
    code: 'gr',
    name: 'Ελληνικά',
    iso: 'el-GR',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/gr.svg`,
    file: 'gr.json',
    // rolyDomain: 'roly.gr',
},
{
    code: 'dk',
    name: "Dansk",
    iso: 'da-DK',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/dk.svg`,
    file: 'dk.json'
},
{
    code: 'ee',
    name: "Eston",
    iso: 'et-EE',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/ee.svg`,
    file: 'ee.json',
    // rolyDomain: 'roly.ee',
    // stmDomain: 'stamina-shop.ee'
},
{
    code: 'bg',
    name: "Български",
    iso: 'bg-BG',
    flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/bg.svg`,
    file: 'bg.json',
}
// {
//   code: 'cn',
//   name: '中国人',
//   iso: 'zh-CN',
//   flagImg: `${process.env.VUE_APP_STATIC_IMG_URL}/images/flags/zh.png`,
//   file: 'cn.json'
    // },
]
      