<template>
  <div>
    <v-fade-transition>
      <v-overlay v-if="cargando" absolute>
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
    <v-row>
      <v-col  cols="12" class="py-0">
    <span class="text-subtitle-2 "
      >{{ $t("configuracion.Bordado.Puntadas") }} </span
    ><v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>
      <span>{{
        $t(
          "configuracion.Bordado.Siempre se cobrará un mínimo de 2500 puntadas"
        )
      }}</span>
    </v-tooltip>

    <v-text-field
      v-model="puntadas"
      class="py-0 mb-3"
      :suffix="$t('configuracion.Bordado.puntadas')"
      :label="$t('configuracion.Bordado.Introduce el número de puntadas')"
      hide-details="true"
      type="number"
      prepend-inner-icon="mdi-basket-fill"
      color="primary darken-2"
      filled
    ></v-text-field>

    <span class="subtitle-2 py-0 mb-3">{{ $t("configuracion.Otros parámetros") }}</span>

    <span class="d-flex py-0 mb-3">
      <check-repeticion v-model="repeticion" :id-zone="id" />
    </span>
    </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CheckRepeticion from "@/components/basic/CheckRepeticion.vue";

export default {
  name: "Bordado",
  components: {
    CheckRepeticion,
  },
  props: {
    cantidad: Number,
    zona: Object,
    id: Number,
  },
  data() {
    return {
      puntadas: 1,
      parametros: [],
      repeticion: false,
      cargando: false,
    };
  },
  computed: {
    ...mapGetters("carrito", ["carrito", "hasPackUno"]),
    ...mapGetters("config", ["isProveedor", "key"]),
  },
  methods: {
    ...mapActions("carrito", ["calcularPrecios"]),
    calcularPrecioBordado: async function () {
      if (this.cantidad == 0) return;
      let parametros = {
        // Generales
        tecnica: "Bordados",
        codigo: this.carrito.modelo,
        unidades: this.cantidad,
        posicion: this.zona.Posicion,
        zona: this.zona.Zona,
        isRepeticion: this.repeticion,

        // Especificos
        atributos: JSON.stringify([
          {
            puntadas: this.puntadas,
            mercanciaCliente: false,
            esPrendaPackUno: (this.id == 0 ? this.hasPackUno : false),
            isProveedor: this.isProveedor,
          },
        ]),

        // identificador
        id: this.id,
      };
      this.cargando = true;
      try {
        await this.calcularPrecios(parametros);
      } catch (error) {
        if(error != 'errorColorBanned')
          {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
      }
      this.cargando = false;
    },
  },
  watch: {
    puntadas: function () {
      this.calcularPrecioBordado();
    },
    cantidad: function () {
      this.calcularPrecioBordado();
    },
    repeticion: function () {
      this.calcularPrecioBordado();
    },
    zona: function () {
      this.calcularPrecioBordado();
    },
    isProveedor: function () {
      this.calcularPrecioBordado();
    },
    key: function () {
      this.calcularPrecioBordado();
    },
  },
  created() {
    this.calcularPrecioBordado();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
