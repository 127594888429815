<template>
  <div>
    <v-fade-transition>
      <v-overlay v-if="cargando" absolute>
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>

    <v-row>
      <v-col  cols="12" class="py-0">
        <span class="text-subtitle-2">{{
          $t("configuracion.Seleccione un tamaño de la lista")
        }}</span>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{
            $t(
              "configuracion.El tamaño máximo para esta combinación de tallas es"
            )+ ": " + actualMaxValidSizeText
          }}</span>
        </v-tooltip>
        <v-select
          v-model="tamaño"
          :items="tamaños"
          filled
          :label="$t('configuracion.Transfer.Tamaño del estampado')"
          color="primary darken-2"
          prepend-inner-icon="mdi-tape-measure"
          hide-details="true"
          @change="actualGetMedidasByTamano"
        >
        </v-select>
      </v-col>
      <v-col cols="6" class="py-0 mb-3">
        <v-text-field
          v-model="ancho"
          class="mt-2"
          suffix.="mm"
          :label="$t('configuracion.Transfer.Ancho')"
          hide-details="true"
          type="number"
          color="primary darken-2"
          filled
          @change="getTamanoByMedidas"
        ></v-text-field>
        <span></span>
      </v-col>
      <v-col  cols="6" class="py-0 mb-3">
        <v-text-field
          v-model="alto"
          class="mt-2"
          suffix="mm"
          :label="$t('configuracion.Transfer.Alto')"
          hide-details="true"
          type="number"
          color="primary darken-2"
          filled
          @change="getTamanoByMedidas"
        ></v-text-field>
        <span>{{ $t("configuracion.Transfer.Max: 420mm") }}</span>
      </v-col>
      <v-col  cols="12" class="py-0 mb-3">
        <v-select
          v-model="colores"
          :items="coloresDisponibles"
          filled
          :label="$t('configuracion.Transfer.Colores')"
          color="primary darken-2"
          prepend-inner-icon="mdi-format-color-fill"
          hide-details="true"
        >
        </v-select>
      </v-col>
      <v-col  cols="12" class="py-0 mb-3">
        <v-select
          v-model="tinta"
          :items="tintas"
          filled
          :label="$t('configuracion.Tintas.Tipo de tinta')"
          color="primary darken-2"
          prepend-inner-icon="mdi-water-outline"
          hide-details="true"
        >
        </v-select>
        <span>{{
          $t(
            "configuracion.Tintas.Si la prenda es elástica (elastano) o el tejido es de canalé, se ha de aplicar tinta para elastano"
          )
        }}</span>
      </v-col>
    <v-col>
    
      <span class="subtitle-2">{{ $t("configuracion.Otros parámetros") }}</span>
      <br />

      <check-repeticion v-model="repeticion" class="py-0 mb-3" :id-zone="id"/>
    </v-col>
    
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CheckRepeticion from '@/components/basic/CheckRepeticion.vue';
// TODO: Cuando la tinta es vinilo (id: 6), sólo aceptamos 1 color
// TODO: El babero dummy "RB9000" sólo admite un color

export default {
  name: "Transfer",
  components: {
    CheckRepeticion,
  },
  props: {
    cantidad: Number,
    zona: Object,
    maxColores: {
      type: Number,
      default: 7,
    },
    color: Object,
    id: Number
  },
  data() {
    return {
      tamaño: "A8",
      ancho: 37,
      alto: 52,
      colores: 1,
      tinta: 5,
      repeticion: false,
      cargando: false,
      tamaños: this.tamañosIniciales(),
      tintas: []
    };
  },
  created() {
    this.tintas = this.tintasDisponibles();
    this.tinta = this.tintaInicial();
    const smallestTamaño = this.tamaños[this.tamaños.length - 1];
    this.tamaño = this.actualMaxValidSize?.value ?? smallestTamaño.value;
    this.ancho = this.actualMaxValidSize?.Ancho ?? smallestTamaño.Ancho;
    this.alto = this.actualMaxValidSize?.Alto ?? smallestTamaño.Alto;
    this.calcularPrecio();
  },
  computed: {
    ...mapGetters("carrito", ["carrito", "hasSerigrafiaOTransfer", "numeroReprocesos", "hasPackUno", "numReprocesosSerigrafiaOrTransfer", "medidasMaximas", "quantityByColor"]),
    ...mapGetters("modelo", ["productos", "modelPrice", "maxValidSize", "isReprocesosStm", "contieneElastano", "modelCode", "actualAlert"]),
    ...mapGetters("config", ["isProveedor", "getMedidasByTamano", "key"]),
    coloresDisponibles: function () {
      
      var coloresmaximos = this.maxColores;
 
      let colores = [];
      for (let i = 1; i <= coloresmaximos; i++) {
        colores.push(i);
      }
      return colores;
    },
    actualMaxValidSize() {
      if (this.carrito.productos.length == 0) return null;
      return this.maxValidSize(this.tamaños, this.zona.Posicion)
    },
    actualMaxValidSizeText()
    {
      return this.actualMaxValidSize ? this.actualMaxValidSize.text : this.$t("configuracion.No hay tamaños válidos");
    }
  },
  methods: {
    ...mapActions("carrito", ["fetchCarrito", "calcularPrecios"]),
    ...mapActions("modelo", ["addAlert"]),
    calcularPrecio: async function() {
      if(this.cantidad==0) return;
      var excedido = this.medidasMaximas(this.zona.Posicion, this.ancho, this.alto);
      if (excedido && excedido.length > 0) {
        this.addAlert(
          { 
          title:  this.$t("alert.errorTamEstampacionTitleDialog") + ' (' + this.$t("Tecnicas.TRANSFER") + ")",
          listProduct: excedido,
          code: "errorTamEstampacion",
          tamEstX: this.ancho,
          tamEstY: this.alto,
          zone: this.zona,
          tamEstampacion: this.tamaños.find(t => t.value == this.tamaño)?.text
          }
        );
      }
      let nreprocesos = this.numReprocesosSerigrafiaOrTransfer;
      let parametros = {
        tecnica: "TransferTintas",
        posicion: this.zona.Posicion,
        zona: this.zona.Zona,
        codigo: this.modelCode,
        unidades: this.cantidad,
        isRepeticion: this.repeticion,

        atributos: JSON.stringify([{
          colores: this.colores,
          idTipoTinta: this.tinta,
          esPrendaPackUno: this.hasPackUno,
          tamano: this.tamaño ?? this.tamañosIniciales()[0].value,
          maxWidth: this.ancho,
          maxHeight: this.alto,
          tintas: "blanca", // TODO
          // productos: this.cantidad + "::" + this.color.CODE,
          productos: Object.keys(this.quantityByColor).reduce((acc, cur) => acc + this.quantityByColor[cur] + "::" + cur + ";", ""),
          nreprocesos: nreprocesos,
          isProveedor: this.isProveedor,
        }]),

        id: this.id,
      };
      this.cargando = true;
      try {
        await this.calcularPrecios(parametros);
      } catch (error) {
        if(!this.actualAlert)
        if(error != 'errorColorBanned')
          {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
      }

      this.cargando = false;
    },
    tamañosIniciales: function() {
      if (
        !this.modelCode &&
        !this.isReprocesosStm
      )
        return [
          { text: "A3", value: "A3", Ancho: 297, Alto: 420 },
          { text: "A4", value: "A4", Ancho: 210, Alto: 297 },
          { text: "A5", value: "A5", Ancho: 148, Alto: 210 },
          { text: "A6", value: "A6", Ancho: 105, Alto: 148 },
          { text: "A7", value: "A7", Ancho: 74, Alto: 105 },
          { text: "A8", value: "A8", Ancho: 52, Alto: 74 },
          { text: "A10", value: "A10", Ancho: 26, Alto: 37 },
          //{ text: this.$t("configuracion.DTF.Metros lineales"), value: "AML" },
        ];
      else
        return [
          { text: "A5", value: "A5", Ancho: 148, Alto: 210 },
          { text: "A6", value: "A6", Ancho: 105, Alto: 148 },
          { text: "A7", value: "A7", Ancho: 74, Alto: 105 },
          { text: "A10", value: "A10", Ancho: 26, Alto: 37 },
        ];
    },
    actualGetMedidasByTamano: function() {
      let tamano = this.tamaños.find((x) => x.value == this.tamaño).text;
      var result = this.getMedidasByTamano(tamano)
      this.ancho = result.Ancho;
      this.alto = result.Alto;
    },
    getTamanoByMedidas: function() {
      var valido = true;
      var mejorMedida = null;
      var menorArea = null;
      this.tamaños.forEach((element) => {
        if (element.Ancho < this.ancho || element.Alto < this.alto) valido;
        else {
          valido = true;
          let area = element.Ancho * element.Alto;
          if (mejorMedida == null) {
            mejorMedida = element.value;
            menorArea = area;
          } else if (area < menorArea) {
            mejorMedida = element.value;
            menorArea = area;
          }
        }
      });
      if (!valido) alert("Las medidas son superiores a la zona de marcado");
      else {
        this.tamaño = mejorMedida;
      }
      return valido;
    },
    tintaInicial() {
      if (!this.contieneElastano) return 5;
      else return 2;
    },
    tintasDisponibles() {
      if (!this.contieneElastano)
        return [
          {
            text: this.$t("configuracion.Tintas.Tinta1"),
            value: 1,
          },
          {
            text: this.$t("configuracion.Tintas.Tinta2"),
            value: 2,
          },
          {
            text: this.$t("configuracion.Tintas.Tinta5"),
            value: 5,
          },
          { text: this.$t("configuracion.Tintas.Tinta6"), value: 6 },
        ];
      else
        return [
          {
            text: this.$t("configuracion.Tintas.Tinta2"),
            value: 2,
          },
        ];
    },
  },
  watch: {
    tamaño: function() {
      this.calcularPrecio();
    },
    colores: function() {
      this.calcularPrecio();
    },
    tinta: function() {
      this.calcularPrecio();
    },
    cantidad: function() {
      this.calcularPrecio();
    },
    repeticion: function() {
      this.calcularPrecio();
    },
    zona: function() {
      this.calcularPrecio();
    },
    isProveedor: function() {
      this.calcularPrecio();
    },
    key: function() {
      this.calcularPrecio();
    },
    numReprocesosSerigrafiaOrTransfer: function(value){
      if (this.hasSerigrafiaOTransfer && value == 2)
        this.calcularPrecio();
    },
    modelo: function() {
      this.tintas = this.tintasDisponibles();
      this.tinta = this.tintaInicial();
      this.tamaños = this.tamañosIniciales();
      this.calcularPrecio();
    },
    modelCode: function () {
      this.tintas = this.tintasDisponibles();
      this.tinta = this.tintaInicial();
      this.tamaños = this.tamañosIniciales();
      this.calcularPrecio();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
