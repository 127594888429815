<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="dialog-zindex-presupuesto calculadora-dialog"
      content-class="dialog-zindex-presupuesto"
    >
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" @click="emitClick(true)">
          <CotizadorBtn id="generatePresupuesto"> {{ $t("general.Presupuesto") }}</CotizadorBtn>
        </div>
      </template>
      <v-card>
        <v-toolbar max-height="40px" height="40px" class="d-flex justify-center containerToolbacrPresupuesto"  dark color="#000">
          <div class="floatingBack">
             <v-btn dark @click="dialog = false" >
              {{ $t("general.Atras") }} <v-icon>mdi-step-backward</v-icon>
            </v-btn>
           <v-btn v-if="isInsideIframe" class="ml-3" @click="navigationBack">
                {{ carrito.modelo }} <v-icon>mdi-step-backward</v-icon>
              </v-btn>
          </div>
          <v-toolbar-title class="tituloModal2"
            >{{ $t("presupuesto.fichaReprocesos.titulop1") }}
            {{ $t("presupuesto.titulo") }}
            {{ $t(`MODELS.${carrito.modelo}.NAME`)}}</v-toolbar-title
          >
        </v-toolbar>
        <v-toolbar max-height="40px" height="40px" dark color="primary" class="container2">
          <v-spacer></v-spacer>
          <v-btn dark color="#000" text>
            <pdf :mis-precios="GenerarPDF()" />
          </v-btn>
        </v-toolbar>
        <v-row class="presupuestoBody mt-6 ml-3 mr-3">
          <v-col>
            <h2 class="title1">{{ $t('presupuesto.incrementoTitle') }}</h2>
            <v-divider></v-divider>
            <h3 class="subtitle1 mt-2">{{ $t('presupuesto.incrementoSubtitle') }}</h3>

            <v-row class="mt-3">
              <v-col>
                <span>{{ $t('presupuesto.porcproductos') }}</span>
                <v-text-field
                  v-model="misPrecios.porcentajeProductos"
                  class="mt-1"
                  type="number"
                  min="0"
                  step="1"
                  filled
                  dense
                  hide-details
                />
              </v-col>
              <v-col>
                <span>{{ $t('presupuesto.porcreprocesos') }}</span>
                <v-text-field
                  v-model="misPrecios.porcentajeReprocesos"
                  class="mt-1"
                  type="number"
                  min="0"
                  step="1"
                  filled
                  dense
                  hide-details
                />
              </v-col>
              <v-col>
                <span>{{ $t('presupuesto.costesextras') }}</span>
                <v-text-field
                  v-model="misPrecios.costeExtra"
                  class="mt-1"
                  type="number"
                  min="0"
                  step="1"
                  filled
                  dense
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col>
                <span>{{ $t('presupuesto.Observaciones') }}</span>
                <v-textarea
                  v-model="misPrecios.Observaciones"
                  class="mt-1"
                  filled
                  dense
                />
              </v-col>
            </v-row>

            <h2 class="title1">{{  $t("presupuesto.MisDatos") }}</h2>
            <v-divider></v-divider>
            <v-row class="mt-2">
              <v-col cols="7" class="encabezado">
                <b>{{ $t('presupuesto.encabezadoTitle') }}</b><br>
                <span>
                  {{  $t('presupuesto.encabezadoHelper') }}
                </span>
                <v-btn
                  :loading="isSelecting" 
                  class="mt-3" large @click="handleFileImport">
                  <v-icon color="primary" class="mr-3">
                    mdi-plus-circle
                  </v-icon>
                  {{ $t('presupuesto.encabezadoUpload') }}
                </v-btn>
                <input 
                    ref="uploader" 
                    class="d-none" 
                    type="file" 
                    accept="image/*"
                    @change="selectFile"
                >
              </v-col>
              <v-col cols="5">
                <v-row>
                  <v-col v-if="idImg">
                    <v-img
                      :id="idImg"
                      class="centerImg"
                      :src="misPrecios.CustomLogoPath"
                      width="85"
                      height="120"
                    />
                    <div class="centerContainer">
                      <v-btn @click="resetImg">
                        {{ $t('configuracion.ReprocesosGenerales.Eliminar') }}
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col>
                     <v-img 
                      src="@/assets/Page-encabezado.svg"
                      width="85"
                      height="120"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="inLineInput">
                  <span>{{ $t('presupuesto.Empresa') }}</span>
                  <v-text-field 
                    v-model="misPrecios.Empresa"
                    hide-details
                    clearable
                    dense
                    class="ml-2"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7">
                <div class="inLineInput">
                  <span>{{ $t('presupuesto.Correo') }}</span>
                  <v-text-field 
                    v-model="misPrecios.correoContacto"
                    hide-details
                    clearable
                    dense
                    class="ml-2"
                    type="email"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="5">
                <div class="inLineInput">
                  <span>{{ $t('presupuesto.Teléfono') }}</span>
                  <v-text-field 
                    v-model="misPrecios.telefono"
                    hide-details
                    clearable
                    dense
                    class="ml-2"
                    type="tel"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7">
                <div class="inLineInput">
                  <span>{{ $t('presupuesto.DireccionCalle') }}</span>
                  <v-text-field 
                    v-model="misPrecios.calle"
                    hide-details
                    clearable
                    dense
                    class="ml-2"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="5">
                <div class="inLineInput">
                  <span>{{ $t('presupuesto.CP') }}</span>
                  <v-text-field 
                    v-model="misPrecios.cp"
                    hide-details
                    clearable
                    dense
                    class="ml-2"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="inLineInput">
                  <span>{{ $t('presupuesto.Poblacion') }}</span>
                  <v-text-field 
                    v-model="misPrecios.poblacion"
                    hide-details
                    clearable
                    dense
                    class="ml-2"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col>
                <div class="inLineInput">
                  <span>{{ $t('presupuesto.Ciudad') }}</span>
                  <v-text-field 
                    v-model="misPrecios.ciudad"
                    hide-details
                    clearable
                    dense
                    class="ml-2"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col>
                <div class="inLineInput">
                  <span>{{ $t('presupuesto.Pais') }}</span>
                  <v-text-field 
                    v-model="misPrecios.pais"
                    hide-details
                    clearable
                    dense
                    class="ml-2"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="4" class="disenoBody">
            <h2 class="title1">{{  $t("presupuesto.diseñoTitle") }}</h2>
            <v-divider></v-divider>
            <v-row class="mt-1">
              <v-col>
                <div class="subtitle">
                  {{  $t("presupuesto.diseñoSubtitle") }}
                </div>
                <div class="infoDesign mt-2">
                  {{  $t("presupuesto.diseñoHelper") }}
                </div>
                <div>
                  <v-btn class="mt-3" large>
                    <v-icon color="primary" class="mr-3">
                      mdi-plus-circle
                    </v-icon>
                    {{  $t("presupuesto.diseñoUpload") }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col>
                <h3>
                  {{  $t("presupuesto.diseñoAlternative") }}
                </h3>
                <v-textarea
                  class="mt-3"
                  filled
                  dense
                />
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Colores from "../cotizadorClientes/Colores.vue";
//import Confirmdlg from "../modales/ConfirmDlg.vue";
import pdf from "./generadorPDFPresupuesto.vue";
// import uploaderImg from "../modales/uploadDesign.vue";
import CotizadorBtn from "../basic/CotizadorBtn.vue";

export default {
  name: "Presupuesto",
  components: {
    // Colores,
    // uploaderImg,
    pdf,
    CotizadorBtn,
  },
  props: {},
  data() {
    return {
      dialog: false,
      misPrecios: {},
      colorSeleccionado: null,
      isSelecting: false,
      idImg: 0
    };
  },
  computed: {
    ...mapGetters("carrito", [
      "carrito",
      "preciosPrendas",
      "preciosReprocesos",
      "colorCode"
    ]),
    ...mapGetters("modelo", ["codigosModelos", "modelPrice", "currencySymbol", "modelInfoFromCatalog"]),
    ...mapGetters("config", ["defaultPdfConfig"]),
    logo() {
      return this.customImg != null 
                ? this.customImg 
                : this.modelPrice && this.modelInfoFromCatalog && (this.modelInfoFromCatalog.originalShop == "STM"  || this.modelInfoFromCatalog.originalShop == "STA" )
                    ? "https://static.gorfactory.es/b2b/Calculadora/logo_stamina.png"
                    : "https://static.gorfactory.es/b2b/Calculadora/logo_roly.png" 
    },
    isInsideIframe()
    {
      return window !== window.parent
    }
  },
  watch: {
  },
  created() {
    this.misPrecios = this.defaultPdfConfig;
  },
  methods: {
    navigationBack() {
      parent.postMessage({ type: "navigationToModel", modelCode: this.carrito.modelo }, "*")
    },
    emitClick(payload)
    {
      this.dialog = payload;
      parent.postMessage({ type: "scrollTop" }, "*")
      this.$emit("click", payload);
    },
    resetImg() {
      this.misPrecios.CustomLogo = null;
      this.misPrecios.CustomLogoPath = null;
      this.idImg = 0;
    },
    handleFileImport() {
        this.isSelecting = true;

        window.addEventListener('focus', () => {
            this.isSelecting = false
        }, { once: true });
        
        // Trigger click on the FileInput
        this.$refs.uploader.click();
    },
    selectFile(event)
    {
      const file = event.target.files[0];

      if (file) {
        var reader = new FileReader();
        var image = new Image();
        reader.onload = function() {
            
            image.onload = function () {
              // you can check the image width and height here
              var width = this.width;
              var height = this.height;
              
              if (width > 500 && height > 500) {
                throw "La imagen debe ser menor de 500x500";
              }
            };
            
            image.src = this.result;   
        }

        reader.addEventListener('loadend', () => {
          const base64 = reader.result;
          // this.$set(this.misPrecios, "CustomLogo", file);
          this.misPrecios.CustomLogo = base64;
          // this.$set(this.misPrecios, "CustomLogoPath", base64);
          this.misPrecios.CustomLogoPath = base64;
          this.idImg++;
        }, { once: true });
          
        reader.readAsDataURL(file); 
      }
    },
    GenerarPDF() {
      var obj = new Object();
      obj.colorSeleccionado = this.colorCode;
      obj.porcentajeProductos = this.misPrecios.porcentajeProductos;
      obj.porcentajeReprocesos = this.misPrecios.porcentajeReprocesos;
      obj.costeExtra = this.misPrecios.costeExtra;
      obj.Observaciones = this.misPrecios.Observaciones;
      obj.Empresa = this.misPrecios.Empresa;
      obj.correoContacto = this.misPrecios.correoContacto;
      obj.telefono = this.misPrecios.telefono;
      obj.calle = this.misPrecios.calle;
      obj.cp = this.misPrecios.cp;
      obj.poblacion = this.misPrecios.poblacion;
      obj.ciudad = this.misPrecios.ciudad;
      obj.pais = this.misPrecios.pais;
      obj.logo = this.misPrecios.logo;
      obj.CustomLogo = this.misPrecios.CustomLogo;
      obj.CustomLogoPath = this.misPrecios.CustomLogoPath;
      return obj;
    },
    Imagen(url) {
      if (url != null) this.misPrecios.customImg = url;
    },
  },
};
</script>
<style lang="scss">
$body-font-family: 'Cairo';
$title-font: 'Outfit';

.centerImg {
  margin: 0 auto;
}

.containerToolbacrPresupuesto {
  .v-toolbar__content {
     width: 100%;
     display: flex;
     justify-content: center;
  }
  .floatingBack {
    position: absolute;
    margin-left: 15px;
    top: 4px;
    left: 0px;
    font-size: 1rem !important;
    max-height: 30px !important;
  }
}

.centerContainer
{
  display: flex;
  justify-content: center;
  align-items: center;
}
.presupuestoBody {
  font-family: $body-font-family, sans-serif;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
  opacity: 1;
  letter-spacing: 0px;
  font-weight: normal;
  letter-spacing: 0 !important;


  .title1 {
    font-family: $title-font, sans-serif;
    font-size: 19px;
    line-height: 23px;
  }

  .subtitle1 {
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    font-size: 19px;
    line-height: 23px;
    font-weight: 300;
    letter-spacing: 0 !important;
  }

  .encabezado {
    font-size: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
    .v-btn {
      max-width: 320px;
      .v-btn__content {
        font-family: $title-font, sans-serif;
        font-size: 17px;
        line-height: 40px;
        font-weight: 500;
        text-transform: none !important;
        letter-spacing: 0 !important;
      }
    }
  }

  .inLineInput {
    display: flex;
    align-items: center;
  }
}




.compact-form-input {
  transform: scale(0.8);
  transform-origin: left;
}
.compact-form-input-2 {
  transform: scale(0.9);
  transform-origin: left;
}
.compact-row {
  height: 75px;
}
.subheader-style {
  margin-top: -15px;
}
.container {
  position: relative;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.dialog-zindex-presupuesto {
  z-index: 9000;
}
.text-subheader {
  font-size: 16px !important;
  margin-top: 5px;
}
.text-label {
  font-size: 16px !important;
}
.boton2 {
  font-size: 1.2rem !important;
}
.tituloModal2 {
  font-size: 28px !important;
}
</style>
