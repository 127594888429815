<template>
  <div>
    <v-fade-transition>
      <v-overlay v-if="cargando" absolute>
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>

    <v-row>
      <v-col sm="12" class="pt-0 pb-0">
        <span class="d-flex">
          <v-checkbox
            v-model="SeEnviaranMetrosLineales"
            hide-details="true"
            :label="$t('configuracion.DTF.Metros lineales')"
            color="primary darken-2 white--text"
            :disabled="SeEnviaranMetrosLineales ? true : false"
            class="mt-1 pt-1 mb-3"
          >
          </v-checkbox>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon class="mt-1">mdi-information</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("configuracion.DTF.Aviso metros") }}</span>
          </v-tooltip>
        </span>
        <span class="d-flex checkbox">
          <v-checkbox
            v-model="prendas"
            hide-details="true"
            :label="$t('configuracion.DTF.Prendas')"
            color="primary darken-2 white--text"
            :disabled="prendas ? true : false"
            class="mt-1 pt-1 mb-3"
          >
          </v-checkbox>
          
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon class="mt-1">mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("configuracion.DTF.Aviso Ancho DTF") }}</span>
        </v-tooltip>
        </span>
      </v-col>
      <v-col  sm="12" class="pt-0 pb-0 infoP">
        
      </v-col>
      <v-col v-if="SeEnviaranMetrosLineales" sm="12" class="pt-0 pb-0">
          <v-text-field
            v-model="unidadesAEnviar"            
            :label="$t('configuracion.DTF.Metros lineales')"
            hide-details="true"
            type="number"
            color="primary darken-2"
            min="1"
            filled
          ></v-text-field>
      </v-col>
      <v-col v-if="!SeEnviaranMetrosLineales" sm="12" class="pt-0 pb-0">
        <span class="text-subtitle-2">{{
          $t("configuracion.Seleccione un tamaño de la lista")
        }}</span>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{
            $t( "configuracion.El tamaño máximo para esta combinación de tallas es")+ " " + (actualMaxValidSize ? actualMaxValidSize.text : $t("configuracion.No hay tamaños válidos"))
          }}</span>
        </v-tooltip>
          <v-select
            v-model="tamaño"
            :items="tamaños"
            filled
            :label="$t('configuracion.DTF.Tamaño')"
            color="primary darken-2"
            prepend-inner-icon="mdi-water-outline"
            hide-details="true"
          >
          </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DTF",
  props: {
    cantidad: Number,
    zona: Object,
    id: Number
  },
  data() {
    return {
      SeEnviaranMetrosLineales: true,
      prendas: false,
      unidadesAEnviar: 1,
      tamaños: this.tamanosInicial(),
      tamaño: "A8",
      cargando: false,
      ancho: 0,
      alto: 0,
    };
  },
  created() {
    this.actualGetMedidasByTamano();
    this.calcularPrecio();
    this.tamaño = this.actualMaxValidSize?.text ?? "A8";

  },
  computed: {
    ...mapGetters("carrito", ["carrito", "hasPackUno", "medidasMaximas"]),
    ...mapGetters("modelo", ["productos", "maxValidSize", "isReprocesosStm", "modelInfoFromCatalog"]),
    ...mapGetters("config", ["isProveedor", "getMedidasByTamano", "allSizes", "key"]),
    actualMaxValidSize() {
      if (this.carrito.productos.length == 0) return null;
      let listTamText = this.tamaños.map(t => t.value);
      let customSizes = Object.keys(this.allSizes).filter(s => listTamText.includes(s)).map(s => {
        return {
          ...this.allSizes[s],
          text: s
        }
      })
      let result = this.maxValidSize(customSizes, this.zona.Posicion);
      return result
    }
  },
  methods: {
    ...mapActions("carrito", ["calcularPrecios"]),
    ...mapActions("modelo", ["addAlert"]),
    checkparametro: function () {
      if (
        this.SeEnviaranMetrosLineales == true &&
        parseInt(this.unidadesAEnviar) <= 0
      ) {
        return false;
      }
      return this.cantidad > 0 || this.prendas > 0;
    },
    tamanoInicial: function () {
      if (
        this.modelo != null &&
        this.modelo.info != null &&
        !this.isReprocesosStm
      )
        return "A4";
      else return "A5";
    },
    tamanosInicial: function () {
      if (
        this.modelo != null &&
        this.modelo.info != null &&
        !this.isReprocesosStm
      )
        return [
          { text: "A3", value: "A3" },
          { text: "A4", value: "A4" },
          { text: "A5", value: "A5" },
          { text: "A6", value: "A6" },
          { text: "A7", value: "A7" },
          { text: "A8", value: "A8" },
          //{ text: this.$t("configuracion.DTF.Metros lineales"), value: "AML" },
        ];
      else
        return [
          { text: "A3", value: "A3" },
          { text: "A4", value: "A4" },
          { text: "A5", value: "A5" },
          { text: "A6", value: "A6" },
          { text: "A7", value: "A7" },
          { text: "A8", value: "A8" },
        ];
    },
    calcularPrecio: async function () {
      
      let parametros = {
        // Generales
        tecnica: "DTF",
        codigo: this.modelInfoFromCatalog.code,
        unidades: this.cantidad,
        posicion: this.zona.Posicion,
        zona: this.zona.Zona,
        isRepeticion: false,

        atributos: "",

        // Identificador
        id: this.id
      };

      
      if (!this.SeEnviaranMetrosLineales) {
        let excedido = this.medidasMaximas(this.zona.Posicion, this.ancho, this.alto)

        if (excedido && excedido.length > 0) {
          this.addAlert(
          { 
          title: this.$t("alert.errorTamEstampacionTitleDialog") + ' (' + this.$t("Tecnicas.TRANSFER DIGITAL (DTF)") + ")",
          listProduct: excedido,
          code: "errorTamEstampacion",
          tamEstX: this.ancho,
          tamEstY: this.alto,
          zone: this.zona,
          tamEstampacion: this.tamaños.find(t => t.value == this.tamaño)?.text
          }
        );
        }
        parametros.atributos =
          '[{"isProveedor":"' + this.isProveedor + '","tamano":"' + this.tamaño +
          '","esPackUno":"' + (this.id == 0 ? this.hasPackUno : false) + '","maxWidth":"' + this.ancho + '","maxHeight":"' + this.alto +
          '"}]';
      } else {
        parametros.tamano = this.unidadesAEnviar;
        parametros.atributos =
          '[{"isProveedor":"' +
          this.isProveedor +
          '","tamano":"' +
          "AML" +
          '","haydiseno":"' +
          true +
          '","MetrosLineales":"' +
          this.unidadesAEnviar +
          '","esPackUno":"' + (this.id == 0 ? this.hasPackUno : false) +
          '"}]';
      }
      this.cargando = true;
      try {
        // console.log("🚀 ~ file: DTF.vue:238 ~ parametros:", parametros)
        await this.calcularPrecios(parametros);
      } catch (error) {
        if(error != 'errorColorBanned')
          {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
      }
      this.cargando = false;
    },
    actualGetMedidasByTamano: function () {
      let tamano = this.tamaño;
      let result = this.getMedidasByTamano(tamano)
      this.ancho = result.Ancho;
      this.alto = result.Alto;
    },
  },
  watch: {
    SeEnviaranMetrosLineales: function () {
      this.prendas = !this.SeEnviaranMetrosLineales;
      if (this.checkparametro()) this.calcularPrecio();
    },
    prendas: function () {
      this.SeEnviaranMetrosLineales = !this.prendas;
      if (this.checkparametro()) this.calcularPrecio();
    },
    modelo: function () {
      if (this.checkparametro()) this.calcularPrecio();
    },
    tamaño: function () {
      this.actualGetMedidasByTamano();
      if (this.checkparametro()) this.calcularPrecio();
    },
    zona: function () {
      if (this.checkparametro()) this.calcularPrecio();
    },
    unidadesAEnviar: function () {
      if (this.checkparametro()) this.calcularPrecio();
    },
    cantidad: function () {
      this.prendas = this.cantidad;
      if (this.checkparametro()) this.calcularPrecio();
    },
    isProveedor: function () {
      if (this.checkparametro()) this.calcularPrecio();
    },
    key: function () {
      if (this.checkparametro()) this.calcularPrecio();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.checkbox {
  margin-top: -20px;
  width: 100%;
}
.infoP {
  margin-bottom: -25px;
}
</style>
