import _ from 'lodash'
import i18n from '@/i18n.js';

export default {
  carrito: state => {
    return _.cloneDeep(state.carrito);
  },
  preciosPrendas: state => {
    return state.carrito.productos.reduce(function (acc, val) { return acc + parseFloat(val.total); }, 0)

  },
  preciosReprocesos: state => {
    return state.carrito.reprocesos.reduce(function (acc, val) { return acc + parseFloat(val.total); }, 0)
  },
  totalUnidades: (state) => {
    return state.carrito.cantidad
  },
  precioUnitario: (state, getters) => {
    let precioUnitario = ((getters.preciosPrendas + getters.preciosReprocesos) / state.carrito.cantidad).toFixed(4)
    return isNaN(precioUnitario) ? 0 : precioUnitario;
  },
  hasSerigrafiaOTransfer: state => {
    if (state.carrito.nreprocesos.filter(r => r.t == "Serigrafia" || r.t == "Transfer").length)
      return true;
    return false;
  },
  hasPackUno: (state, getters, rootState, rootGetters) => {
    if (state.carrito.productos.some(r => r.PACK == 1 || r.pack == 1) && !rootGetters["modelo/isReprocesosStm"])
      return true;
    return false;
  },
  actualModelo: (state) => state.carrito.modelo,
  sobrecosteCantidadExacta: state => state.sobrecosteCantidadExacta,
  loadingCarrito: (state) => state.loading > 0,
  numReprocesos: (state) => state.carrito.reprocesos.length,
  numReprocesosSerigrafiaOrTransfer: (state) => state.carrito.reprocesos.filter(r => r.tecnica == "Serigrafia" || r.tecnica == "Transfer").length,
  medidasMaximas: (state, getter, rootState) => (posicion, xasis, yaxis) => {
    // console.log("🚀 ~ file: CarritoGetters.js:48 ~ tamañosTallas.forEach ~ xasis", xasis)
    // console.log("🚀 ~ file: CarritoGetters.js:48 ~ tamañosTallas.forEach ~ yaxis", yaxis)
    var tallas = []; // tallas en el carrito
    state.carrito.productos.forEach((element) => {
      tallas.push(element.talla);
    });

    // console.log("🚀 ~ file: CarritoGetters.js:36 ~ tallas", tallas)
    var tamañosTallas = []; // tamaños por talla y zona actual

    tallas.forEach(talla => {
      let actualSize = rootState.modelo.modelInfo.tallasInfo.find(t => t.Talla == talla)
      tamañosTallas.push(actualSize.Posiciones.find(p => p.Posicion == posicion))
    })

    // console.log("🚀 ~ file: CarritoGetters.js:42 ~ tamañosTallas", tamañosTallas)
    var response = [];
    tamañosTallas.forEach((element) => {

      // console.log("🚀 ~ file: CarritoGetters.js:48 ~ tamañosTallas.forEach ~ element.Ancho < xasis", element.Ancho < xasis)
      // console.log("🚀 ~ file: CarritoGetters.js:48 ~ tamañosTallas.forEach ~ element.Alto < yaxis", element.Alto < yaxis)
      if (element.Alto < yaxis || element.Ancho < xasis) {
        response.push(element);
      }
    });
    // console.log("🚀 ~ file: CarritoGetters.js:46 ~ response", response)

    return response;
  },
  calcularPlanchado: state => state.calcularPlanchado,
  nextAvalibleId: state => Math.max(state.carrito.reprocesos.map(r => r.id), 0) + 1,
  quantityByColor: state => {
    return state.carrito.productos.reduce((result, producto) => {
      const color = producto.color;
      const cantidad = parseInt(producto.cantidad);

      if (!result[color]) {
        // Si el color no existe en el objeto resultante, se agrega como nueva propiedad
        result[color] = cantidad;
      } else {
        // Si el color ya existe, se suma la cantidad
        result[color] += cantidad;
      }

      return result;
    }, {});
  },
  quantityByColorAndProduct: state => {
    return state.carrito.productos.reduce((result, producto) => {
      const color = producto.color;
      const cantidad = parseInt(producto.cantidad);

      if (!result[color]) {
        // Si el color no existe en el objeto resultante, se agrega como nueva propiedad
        result[color] = [];
      }



      result[color].push({ quantity: cantidad, size: producto.talla });

      return result;
    }, {});
  },
  manipuladosPrices: state => state.carrito.reprocesos.find(r => r.t == "Manipulados" || r.isManipulado)?.prices,


  comercialCart: (state, getter, rootState) => rootState.config.userOfClient ? state.comercialCart : [],
  countComercialCart: state => state.comercialCart.length,
  clientCart: state => state.clientCart,
  countClientCart: state => state.clientCart.length,
  totalClientCart: state => {
    return state.clientCart.reduce((total, articulo) => {
      return total + articulo.total;
    }, 0)
      .toFixed(2);
  },

  // Carrito que usar dependiendo si es un comercial o un cliente normal
  actualCart: (state, getter, rootState, rootGetters) => rootGetters['config/activeComercial'] ?
    state.comercialCart ?? [] : state.clientCart ?? [],
  totalActualCart: (state, getter) => getter.actualCart
    .reduce((total, reproceso) => {
      return total + reproceso.total;
    }, 0)
    .toFixed(2),

  completeCart: state => state.completeCart,
  lastCart: state => state.lastCart,
  colorCode: state => state.carrito?.colorCode,
  savedDesign: state => state.savedDesign,
  sortColorAvalibleAndWithQuantity: (state, getters, rootState, rootGetters) => {
    // Ordenamos las bolas de colores
    let colorAvalible = rootGetters['modelo/colorAvalible'];
    let quantityByColor = getters.quantityByColor;
    let colorAvalibleAndWithQuantity = [];
    colorAvalible.forEach(color => {
      let quantity = quantityByColor[color.code];
      if (quantity) {
        colorAvalibleAndWithQuantity.push({ ...color, quantity: quantity });
      }
    });
    colorAvalibleAndWithQuantity.sort((a, b) => {
      return b.order - a.order;
    });
    return colorAvalibleAndWithQuantity;
  },
  actualListDesign: state => state.actualDesign,
  filteredListDesignInCart: state => {
    return state.actualDesign.filter(design => {
      return state.carrito.reprocesos.some(r => r.id == design.id && r.zona == design.zona);
    });
  },
  getDesignByIdAndZone: (state, getter) => (id, posicion) => {
    return getter.filteredListDesignInCart.find(design => design.id == id && design.posicion == posicion);
  },
  numDesignForEachPosition: (state, getter) => {
    return getter.filteredListDesignInCart.reduce((numDesign, design) => {
      numDesign[design.zona] = (numDesign[design.zona] || 0) + 1;
      return numDesign;
    }, {});
  },
  numDesignForEachPreviewRule: (state, getter, rootState, rootGetters) => {
    return getter.filteredListDesignInCart.reduce((numDesign, design) => {
      let idPreviewRule = rootGetters["modelo/zonasMappedByPosicion"]?.[design.posicion]?.PreviewRule;
      if (idPreviewRule)
        numDesign[idPreviewRule] = (numDesign[idPreviewRule] || 0) + 1;
      return numDesign;
    }, {});
  },
  previewGlobalDesign: (state, getter, rootState, rootGetters) => {
    let designPosition = null;
    let designPreviewRule = null;
    let numDesign = 0;
    Object.keys(getter.numDesignForEachPosition).forEach(item => {
      if (!designPosition || getter.numDesignForEachPosition[item] > numDesign) {
        designPosition = item;
        numDesign = getter.numDesignForEachPosition[item];
      }
    })

    Object.keys(getter.numDesignForEachPreviewRule).forEach(item => {
      if (!designPosition || getter.numDesignForEachPreviewRule[item] > numDesign) {
        designPreviewRule = item;
        numDesign = getter.numDesignForEachPreviewRule[item];
      }
    })

    const hasZoneDesign = designPosition && getter.numDesignForEachPosition[designPosition] > 0;
    const hasPreviewRule = designPreviewRule && getter.numDesignForEachPreviewRule[designPreviewRule] > 0
    let zonesFromSamePreviewRule = [];
    if (hasPreviewRule) {
      Object.keys(rootGetters["modelo/zonasMappedByPosicion"]).forEach(posicion => {
        let previewRule = rootGetters["modelo/zonasMappedByPosicion"][posicion].PreviewRule;
        if (previewRule == designPreviewRule)
          zonesFromSamePreviewRule.push(rootGetters["modelo/zonasMappedByPosicion"][posicion]);
      });
    }
    else if (hasZoneDesign) {
      zonesFromSamePreviewRule.push(rootGetters["modelo/zonas"].find(z => z.Zona == designPosition));
    }
    return {
      hasZoneDesign,
      hasPreviewRule,
      zones: [...zonesFromSamePreviewRule],
      designPreviewRule
    }
  },
  cartHasProducts: state => state.carrito?.productos?.length > 0 && state.carrito?.productos?.some(p => p.cantidad > 0),
  soloBlancoEnCarritoSublimacion: (state, getter, rootState, rootGetters) => state.carrito.productos.length >= 1 && state.carrito.productos.every(p => p.color == "01") && rootGetters['modelo/sublimacionSoloBlanco'],
  listColorInCart: state => state.carrito.productos.map(p => p.color),
  bocetosRepeticion: state => state.bocetosRepeticion.filter(
    // Comprobamos que id se encuentra en carrito.reprocesos
    b => state.carrito.reprocesos.some(r => r.id == b.id)
  ),
  shippingAddress: state => state.shippingAddress,
  hasErrorInCart: state => state.carrito?.reprocesos?.some(r => r.error) ?? false,
  sortedReprocesosByIdWithNotes: state => state.carrito?.reprocesos?.map(r => {
    // Si la técnica es manipulados, o planchado o cantidad exacta, no hacemos nada
    if (r.tecnica == "Manipulados" || r.tecnica == "Manipulacion" || r.tecnica == "Planchado" || r.tecnica == "CantidadExacta") {
      return r;
    }

    // Obtenemos params del reproceso y pasamos a json el string de atributos
    const params = r.params;
    const atributos = JSON.parse(params.atributos);

    // Atributos debe ser un array, y contener al menos un elemento
    if (!Array.isArray(atributos) || atributos.length === 0) {
      return r;
    }

    // Recuperamos el primer elemento
    const primerAtributo = atributos[0];

    let exclusionList = ['nreprocesos', 'prendas', 'esPrendaPackUno', 'esPackUno', 'isProveedor', 'felpa', 'tipo', 'isTransferSerigrafico', 'isTransferDigital', 'fondoOscuro'];

    // Comprobamos que el primer elemento existe, si existe, parseamos cada una de sus propiedades eliminando aquellas que sean false o tengan el valor 0
    if (primerAtributo) {
      const notes = Object.entries(primerAtributo).reduce(function (acc, [key, value]) {
        if (value && value > 0 && !exclusionList.some(exclusion => key.includes(exclusion))) {

          if (r.tecnica == "Impresion" && key.includes("idTamImpresion")) {
            acc.push({ key: "tamano", value: i18n.t("configuracion.ImpresionDirecta.Size" + value) });
          }
          else if (key == "tintaNegra") {
            acc.push({ specialkey: i18n.t('configuracion.Serigrafia.Usar tinta negra'), value: i18n.t("presupuesto.fichaReprocesos.params." + value) });
          }
          else if (typeof value == "boolean") {
            acc.push({ key, value: i18n.t("presupuesto.fichaReprocesos.params." + value) });
          }
          else if (key == "idTipoTinta") {
            acc.push({ key, value: i18n.t("configuracion.Tintas.Tinta" + value) });
          }
          else if (key == "maxWidth" || key == "maxHeight") {
            acc.push({ key, value: value + " mm" });
          }
          else if (key == "tamanocm2Digital" || key == "tamanoCm2Doming") {
            acc.push({ key, value: value + " cm2" });
          }
          else if (key == "typeTermograbado") {
            acc.push({ key, value: i18n.t("configuracion.CalculoPrecios.Termografia." + value) });
          }
          else {
            acc.push({ key, value });
          }
        }
        return acc;
      }, []);

      return {
        ...r,
        notes
      }
    }
  }).sort((a, b) => a.id - b.id),
};

