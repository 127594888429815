<template>
  <div>
    <v-dialog
      ref="cartDialog"
      v-model="localDialog"
      scrollable
      max-width="980px"
      class="dialog-z-index-general"
    >
      <v-card
        :loading="loading != 0 ? 'primary' : false"
        elevation="4"
        outlined
      >
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="cerrar">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- <v-toolbar-title>
                        {{ $t("cart.TitleModal") }}
                    </v-toolbar-title> -->
          <v-spacer></v-spacer>
          <h2 class="totalHeader">
            {{ $t("presupuesto.fichaReprocesos.Total") }}:
            {{ totalActualCart }}
            {{ currencySymbol }}
          </h2>
        </v-toolbar>

        <v-card-text>
          <div v-if="activeComercial" class="d-flex flex-column align-center">
            <div class="mt-3 d-flex flex-column align-center">
              <h5>
                {{ loggedClient.clientId }} -
                {{ loggedClient.clientName }}
              </h5>
              <h5>{{ userOfClient.label }}</h5>
            </div>
          </div>
        </v-card-text>

        <v-card-text class="body-dialog px-5">
          <v-row>
            <v-col>
              <v-container class="scroll-container overflow-y-auto">
                <template v-if="totalActualCart > 0">
                  <cart-element
                    :cart="actualCart"
                    :loading="loading != 0 || loadingCarrito"
                  ></cart-element>
                </template>
                <template v-else>
                  <div class="d-flex justify-center align-center loading-cart">
                    <v-icon class="icon-cart mr-3" large>
                      mdi-cart-outline
                    </v-icon>
                    <div>
                      <h2>{{ $t("cart.EmptyCart") }}</h2>
                      <br />
                      <h3>{{ $t("cart.SubheaderEmptyCart") }}</h3>
                    </div>
                  </div>
                </template>
              </v-container>
            </v-col>
            <v-divider vertical inset></v-divider>
            <v-col class="ml-2">
              <v-container class="scroll-container overflow-y-auto">
                <v-row>
                  <h3>{{ $t("cart.DatosExtra") }}</h3>
                </v-row>
                <v-row>
                  <v-col class="pb-0">
                    <h4>
                      {{ $t("presupuesto.Observaciones") }}
                    </h4>
                    <v-textarea
                      v-model="observaciones"
                      class="mt-1"
                      filled
                      dense
                      auto-grow
                      no-resize
                      rows="3"
                      counter="250"
                      :rules="[
                        observacionesValid ||
                          $t('cart.MaxInputObservaciones', { numCaracter: 250 })
                      ]"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pb-0">
                    <h4>{{ $t("cart.Referencia") }}</h4>
                    <v-textarea
                      v-model="referencia"
                      class="mt-1"
                      filled
                      dense
                      auto-grow
                      no-resize
                      rows="1"
                      counter="100"
                      :rules="[
                        referenceValid ||
                          $t('cart.MaxInputObservaciones', { numCaracter: 100 })
                      ]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h4>
                      {{ $t("cart.DireccionesEnvio") }}
                    </h4>
                    <v-radio-group
                      v-model="direcciones"
                      class="mt-0"
                      hide-details
                    >
                      <v-radio :value="false">
                        <template #label>
                          {{ $t("cart.DireccionesGuardadas") }}
                        </template>
                      </v-radio>
                      <v-radio :value="true">
                        <template #label>
                          {{ $t("cart.DireccionNuevaCliente") }}
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <template v-if="!direcciones">
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="selectedDireccion"
                        :items="shippingAddress"
                        :label="$t('presupuesto.DireccionCalle')"
                        filled
                        item-text="address"
                        item-value="codToken"
                        :loading="loading != 0 || loadingCarrito"
                      >
                        <template #item="{ item }">
                          <div class="mt-2 pa-1 px-0 w-100">
                            <strong>
                              {{ item.address }}
                            </strong>
                            <br />
                            <span>
                              {{ item.countrycode }}
                            </span>
                            <br />
                            <span>
                              {{
                                `${item.street} ${item.cp} ${item.city} ${item.state} ${item.country}`
                              }}
                            </span>
                            <br />
                            <v-divider />
                          </div>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <address-creator
                    v-model="customAddress"
                    :avalible.sync="avalibleCustomAddress"
                    :loading="loading != 0 || loadingCarrito"
                  />
                </template>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mt-3"></v-divider>

        <v-card-actions class="d-flex align-center">
          <v-btn
            class="white--text"
            :loading="loading != 0 || loadingCarrito"
            :disabled="loading != 0"
            dark
            @click="deleteCart"
          >
            {{ $t("cart.CleanCart") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            :loading="loading != 0 || loadingCarrito"
            :disabled="!avalibleConfirm"
            color="primary"
            @click="confirmAll"
          >
            {{ $t("cart.generateBudget") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import cartElement from "../basic/cartElement.vue";
import AddressCreator from "@/components/basic/AddressCreator.vue";

export default {
  components: {
    cartElement,
    AddressCreator
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localDialog: this.dialog,
      loading: 0,
      observaciones: "",
      referencia: "",
      direcciones: false,
      selectedDireccion: null,
      customAddress: {
        street: "",
        cp: "",
        city: "",
        country: "Spain",
        state: "",
        statecode: "",
        district: "",
        email: "",
        phoneNumber: "",
        addresstype: "S"
      },
      avalibleCustomAddress: false
    };
  },
  computed: {
    ...mapGetters("config", [
      "loggedClient",
      "userOfClient",
      "comercialToken",
      "translateTecnica",
      "activeComercial"
    ]),
    ...mapGetters("carrito", [
      "actualCart",
      "countClientCart",
      "totalActualCart",
      "shippingAddress",
      "loadingCarrito"
    ]),
    ...mapGetters("modelo", ["currencySymbol"]),
    avalibleConfirm() {
      let customAddress =
        (!this.direcciones && this.selectedDireccion) ||
        (this.direcciones && this.avalibleCustomAddress);

      return (
        this.actualCart.length > 0 &&
        customAddress &&
        this.loading == 0 &&
        this.observacionesValid &&
        this.referenceValid
      );
    },
    observacionesValid() {
      return this.observaciones.length <= 250;
    },
    referenceValid() {
      return this.referencia.length <= 100;
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal != this.localDialog) this.localDialog = newVal;

      if (newVal) {
        this.fetchAddress();
      }
    },
    localDialog(newVal) {
      if (newVal != this.dialog) {
        this.$emit("cerrar");
      }

      if (newVal && this.localDialog) {
        this.fetchActualCart();
      }

      if (newVal) {
        this.$nextTick(() => {
          this.$refs.cartDialog.showScroll();
        });
      }
    },
    comercialToken(newVal) {
      if (newVal && this.localDialog) {
        this.fetchActualCart();
      }
    },
    shippingAddress(newVal) {
      if (newVal.length > 0 && !this.direcciones) {
        // Buscamos la dirección actual
        const search = newVal.find(d => d.codToken == this.selectedDireccion);

        if (!search) {
          this.selectedDireccion =
            newVal.find(a => a.isDefault)?.codToken ?? newVal[0].codToken;
        }
      }
    }
  },
  methods: {
    ...mapActions("carrito", [
      "fetchActualCart",
      "deleteActualCart",
      "confirmActualCart",
      "fetchAddress"
    ]),
    cerrar: function () {
      this.localDialog = 0;
      this.$emit("cerrar");
    },
    async deleteCart() {
      this.loading++;
      await this.deleteActualCart();
      this.loading--;
      this.cerrar();
    },
    async confirmAll() {
      try {
        this.loading++;
        await this.confirmActualCart({
          observaciones: this.observaciones,
          referencia: this.referencia,
          direcciones: this.direcciones,
          addressCode: this.selectedDireccion,
          customAddress: this.customAddress
        });
        this.observaciones = "";
        this.referencia = "";
        this.cerrar();
        parent.postMessage({ type: "reloadCart" }, "*");
      } finally {
        this.loading--;
      }
    }
  }
};
</script>

<style lang="scss">
.max-width-fit-content {
  max-width: fit-content;
}

.title-list {
  font-size: 1.05rem;
}

.active-panel {
  box-shadow:
    0px 3px 1px -2px var(--v-primary-darken2),
    0px 2px 2px 0px var(--v-primary-darken2),
    0px 1px 5px 0px var(--v-primary-darken2);
}

.body-dialog {
  max-width: 100%;
}

.totalHeader {
  text-shadow: 0 0 1px black;
}

.scroll-container {
  max-height: 600px;
  height: 100%;
}

.loading-cart {
  background-color: #f8f8f8;
  height: 100%;
  padding: 15px;
}
</style>
