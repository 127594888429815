<template>
  <v-container fluid class="py-0 px-1">
    <v-row v-if="sublimacionSoloBlanco" class="d-flex justify-center">
      <div class="alertSublimacionBlanco">
        <v-alert border="bottom" elevation="8" text type="warning" dense>
          <b>{{ $t("configuracion.Sublimacion.sublimacionBlanco") }}</b>
          <br />
          <b>
            {{ $t("configuracion.Sublimacion.sublimacionSoloBlanco") }}
          </b>
        </v-alert>
      </div>
    </v-row>
    <v-row v-if="onlyOneColor" class="d-flex justify-center">
      <div class="alertSublimacionBlanco">
        <v-alert border="bottom" elevation="8" text type="warning" dense>
          <b>{{ $t("configuracion.onlyOneColor") }}</b>
          <br />
          <b>
            {{ $t("configuracion.onlyOneColorDesc") }}
          </b>
        </v-alert>
      </div>
    </v-row>
    <v-row>
      <v-col class="pa-0" cols="12" :md="showSizeColumn ? 6 : 7">
        <div class="texttable title-table-header d-flex align-center">
          <span>{{ $t("general.Colores") }}</span>
        </div>
        <div class="color-list d-flex justify-start flex-wrap mt-1 py-2">
          <ColorBall
            v-for="actColor in colorAvalible"
            :key="actColor.code"
            circle-class=""
            :hexcode="actColor.url ? actColor.url : actColor.hexcode"
            :selected="actualColor == actColor.code"
            :quantity="getQuantityByColor(actColor.code)"
            :disabled="
              loading > 0 ||
              (soloBlancoEnCarritoSublimacion && actColor.code != '01') ||
              (actColor.code == '01' &&
                sublimacionSoloBlanco &&
                !soloBlancoEnCarritoSublimacion &&
                totalUnidades > 0) ||
                (onlyOneColor && actColor.code != actualColor && totalUnidades > 0)
            "
            tooltip
            :label="actColor.code"
            @changeColor="changeColor(actColor.code)"
          >
            <span>
              <u>{{ $t(`COLORS.${actColor.code}`) }}</u>
            </span>
            <template v-if="getQuantityByColor(actColor.code) != 0">
              <div
                v-for="sizeQuantity in quantityByColorAndProduct[actColor.code]"
                :key="sizeQuantity.size"
              >
                <span>
                  {{ $t(`SIZES.${sizeQuantity.size}`) }}:
                  {{ sizeQuantity.quantity }}
                </span>
              </div>
            </template>
          </ColorBall>
        </div>
      </v-col>
      <v-col class="pa-0" :cols="showSizeColumn ? 6 : 5">
        <!-- <v-fade-transition>
          <v-overlay v-if="cargando" absolute>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-overlay>
        </v-fade-transition> -->

        <v-data-table
          v-if="actualItemList"
          :id="`productos-${actualColor}`"
          :headers="computedHead"
          :items="actualItemList"
          :items-per-page="actualItemList.length"
          hide-default-footer
          class="table-products"
        >
          <template #item.SIZE="{ item }">
            <b class="size-text">{{ $t(`SIZES.${item.SIZE}`) }}</b>
          </template>

          <template #item.QUANTITY="{ item }">
            <div class="d-flex align-center max-width-input">
              <product-input
                input-classes="text-body"
                :value="getQuantity(item)"
                :product="item"
                @input="quantity => updateQuantity(item, quantity)"
                @keyup.enter="updateQuantityField"
                @keyup.right="tryMoveNextColor"
                @keyup.left="tryMovePreviousColor"
              ></product-input>
              <!-- <v-text-field
                class="text-body"
                :label="$t('general.Cantidad')"
                :value="getQuantity(item)"
                type="number"
                min="0"
                @input="quantity => updateQuantity(item, quantity)"
                @keyup.enter="updateQuantityField"
                @keyup.right="tryMoveNextColor"
                @keyup.left="tryMovePreviousColor"
              ></v-text-field> -->
            </div>
          </template>

          <!-- <template v-slot:item.STOCK="{ item }">
           <span ><span v-show="item.STOCK.hasMore">+</span>{{ item.STOCK.stock }}</span>
          </template> -->

          <template #item.MEDIDAS="{ item }">
            <small
              v-for="(zona, key) in item.MEDIDAS"
              :key="key"
              class="text-body text-size-zones"
            >
              <u class="font-weight-bold">
                {{ $t("Zonas." + getZoneCodeById(zona.Posicion)) }}
              </u>
              :
              <span class="font-weight-light">
                &nbsp;&nbsp;&nbsp;&nbsp;{{ zona.Ancho }}X{{ zona.Alto }}mm
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </small>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-end pt-0 pb-3 pr-6">
         <cotizador-btn
            sm
            :disabled="disabledClearBtn"
            content-class="w-100"
            :on="{
              click: deleteCart
            }"
          >
            {{ $t("cart.CleanCart") }}
          </cotizador-btn>
           <cotizador-btn
            sm
            success
            :disabled="loading > 0 || totalUnidades == 0"
            content-class="w-100"
            class="ml-2"
            :on="{
              click: emitClose
            }"
          >
            {{ $t("general.Aceptar") }}
          </cotizador-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { dynamicPrice, numProductByType } from "../../utils/priceUtils";

import ColorBall from "../basic/ColorBall.vue";
import productInput from "@/components/basic/productInput.vue";
import CotizadorBtn from "@/components/basic/CotizadorBtn.vue";

export default {
  components: { ColorBall, productInput, CotizadorBtn },
  props: {},
  data() {
    return {
      loading: 0,
      actualColor: null,

      debouncedProductQuantity: {},
      headers: [
        {
          text: this.$t("general.Talla"),
          align: "start",
          sortable: false,
          value: "SIZE",
          class: "texttable title-table-header"
        },
        {
          text: this.$t("general.Cantidad"),
          // align: "start",
          sortable: false,
          value: "QUANTITY",
          class: "texttable title-table-header pl-8"
        }
        // {
        //   text: this.$t("general.Stock"),
        //   // align: "start",
        //   sortable: false,
        //   value: "STOCK",
        //   class: "texttable",
        // },
        // {
        //   text: this.$t("general.Tamaño"),
        //   align: "start",
        //   sortable: false,
        //   value: "MEASURES",
        //   class: "texttable"
        // },
        // {
        //   text: this.$t("general.MedidasImpresion"),
        //   align: "start",
        //   sortable: false,
        //   value: "MEDIDAS",
        //   class: "texttable title-table-header"
        // }
      ]
    };
  },
  computed: {
    ...mapGetters("carrito", [
      "carrito",
      "quantityByColor",
      "quantityByColorAndProduct",
      "soloBlancoEnCarritoSublimacion",
      "totalUnidades",
      "loadingCarrito",
    ]),
    ...mapGetters("modelo", [
      "productos",
      "modelPrice",
      "waitExtranet",
      "getProductosByModeloColor",
      "zonas",
      "colorAvalible",
      "currency",
      "hasRangePrices",
      "modelInfoFromCatalog",
      "modelCode",
      "sublimacionSoloBlanco",
      "onlyOneColor"
    ]),
    ...mapGetters('config', ['colorSelected']),
    actualItemList() {
      return this.getProductosByModeloColor(this.actualColor)
    },
    showSizeColumn() {
      return this.actualItemList.length > 1;
    },
    computedHead() {
      if(!this.showSizeColumn) {
        return this.headers.filter(h => h.value != "SIZE");
      }

      if(this.isOnlyInk) {
        // Cambiamos el texto de la columna SIZE a por el de inks
        return this.headers.map(h => {
          if(h.value == "SIZE") {
            return {
              ...h,
              text: this.$t('general.inks')
            }
          }

          return h;
        });
      }

      return this.headers;
    },
    isOnlyInk() {
      let listSizes = new Set(this.actualItemList.map(p => p.SIZE));

      const tallasTintas = ["TA", "TN"]

      return [...listSizes].every(s => tallasTintas.includes(s));
    },
    disabledClearBtn() {
      return this.loading != 0 || this.loadingCarrito || this.totalUnidades == 0;
    },
  },
  watch: {
    colorAvalible: function (newVal) {
      if (newVal.length == 0) this.actualColor = null;
      else if(newVal.some(c => c.code == this.actualColor)) return;
      else this.actualColor = newVal[0].code;
    },
    colorSelected: function (newValue, oldVal) {
      if (newValue == oldVal) return;

      if (newValue) {
        this.changeColor(newValue)
      }
    },
  },
  async created() {
    await this.updateProductosModeloColor();
    // await this.waitExtranet;
    if (this.colorAvalible.length > 0)
      this.actualColor = this.colorAvalible[0].code;
  },
  methods: {
    ...mapActions("carrito", ["addProducto", "deleteProducto", "deleteAllProducts"]),
    updateProductosModeloColor: async function (modelo, color) {
      if (modelo == null) modelo = this.modelo;
      if (color == null) color = this.color;
      if (modelo == null || color == null) return;
      // this.productosModeloColor = this.productos.find(
      //   (p) =>  p.modelo == modelo.id && p.color == color.CODE
      // )?.productos;

      if (
        this.productosModeloColor == null ||
        this.productosModeloColor.length == 0
      ) {
        this.productosModeloColor = this.getProductosByModeloColor(
          this.actualColor
        ).find(p => p.color == color);
      }

      if (
        this.productosModeloColor != null &&
        this.productosModeloColor.length > 0
      )
        return;

      // await this.fetchProductos({
      //   modelo: modelo.id,
      //   color: color.CODE,
      //   key: this.key,
      // });
      this.productosModeloColor = this.productos.find(
        p => p.modelo == modelo.id && p.color == color.CODE
      )?.productos;
    },
    getQuantityByColor(color) {
      return this.quantityByColor?.[color] ?? 0;
    },
    getQuantity: function (producto) {
      let productCart = this.carrito.productos?.find(
        p => p.id == producto.CODE || p.id == producto.id
      );

      if (!productCart || !productCart.cantidad) return 0;
      else return parseInt(productCart.cantidad);
    },
    updateQuantityField: function (event) {
      event.preventDefault();
      this.dialog = false;
    },
    tryMoveNextColor: function (event) {
      event.preventDefault();

      if (this.loading > 0) return;

      for (let index = 0; index < this.colorAvalible.length; index++) {
        const color = this.colorAvalible[index];
        if (color.code === this.actualColor) {
          const newIndex = index + 1;
          if (newIndex < this.colorAvalible.length) {
            this.changeColor(this.colorAvalible[newIndex].code);
            return;
          } else {
            this.changeColor(this.colorAvalible[0].code);
            return;
          }
        }
      }
    },
    tryMovePreviousColor: function (event) {
      event.preventDefault();

      if (this.loading) return;

      for (let index = 0; index < this.colorAvalible.length; index++) {
        const color = this.colorAvalible[index];
        if (color.code === this.actualColor) {
          const newIndex = index - 1;
          if (newIndex < this.colorAvalible.length) {
            this.changeColor(this.colorAvalible[newIndex].code);
            return;
          } else {
            this.changeColor(this.colorAvalible[0].code);
            return;
          }
        }
      }
    },
    updateQuantity: async function (producto, cantidad) {
      let product = producto.CODE ?? producto.code ?? producto;
      if (!this.debouncedProductQuantity[product]) this.loading++;
      clearTimeout(this.debouncedProductQuantity[product]);
      this.debouncedProductQuantity[product] = setTimeout(async () => {
        await this.waitExtranet;
        let productCart = this.carrito.productos.find(
          p => p.id == producto.CODE || p.id == producto.id || p.id == producto
        );

        let moq = 1;

        if (product) {
          moq = this.modelInfoFromCatalog.products[product]?.MOQ;
        }

        if (cantidad < moq && cantidad != 0) {
          cantidad = moq * Math.floor(cantidad / moq);
          // cantidad = moq * Math.ceil(cantidad / moq);
          this.addAlert({
            title: this.$t("alert.errorMinUnitTitleDialog", {
              modelo: this.modelCode
            }),
            code: "errorNoMoq",
            minUnit: moq ?? 0,
            productCode: producto.CODE
          });
        }

        if (cantidad == 0 && productCart == null) return;

        // Proceso de actualización de precios
        const pricesStm = this.modelInfoFromCatalog.originalShop
          ? this.modelInfoFromCatalog.originalShop == "STA"
          : this.modelInfoFromCatalog.shop == "STA";

        if (
          pricesStm &&
          this.hasRangePrices &&
          !this.modelInfoFromCatalog.products[product].OUTLET
        ) {
          let cartList = [];

          // console.log("🚀 ~ file: App.vue:433 ~ this.carrito.productos:", this.carrito.productos)
          cartList = this.carrito.productos.filter(prod => !productCart || (prod.id != producto.CODE && prod.id != producto.id && prod.id != producto)).map(prod => {
            return {
              model: this.modelCode,
              product: prod.id,
              quantity: Number(prod.cantidad)
            };
          });
          // console.log("🚀 ~ file: Productos.vue:340 ~ cartList:", cartList)

          // Guardamos los productos del carrito para recalcular
          let actualCarritoProductos = [...this.carrito.productos];

          let selectedAmounts = {};
          selectedAmounts[product] = Number(cantidad);

          let quantityGrouped = numProductByType(
            this.modelInfoFromCatalog,
            this.modelPrice.prices,
            cartList,
            selectedAmounts
          );

          // Obtenemos el reango actual
          let actualRange = this.modelPrice.prices.RANGEPRICE[product];

          // Obtenemos la key actual
          let actualRangeKey = [
            actualRange.RANGES.COLOR,
            actualRange.RANGES.SIZEGROUP
          ].toString();
          // console.log("🚀 ~ file: Productos.vue:324 ~ actualRangeKey:", actualRangeKey)

          let actualQuantity = quantityGrouped.get(actualRangeKey);
          if (cantidad == 0 && productCart != null) {
            // Delete
            await this.deleteProducto(productCart);
          } else if (cantidad > 0) {
            // console.log("🚀 ~ file: Productos.vue:331 ~ actualQuantity:", actualQuantity)
            let price = this.calculatePrice(producto, actualQuantity);
            let params = {
              producto: producto,
              cantidad: cantidad,
              precio: price,
              color: this.actualColor
            };
            await this.addProducto(params);
          }

          actualCarritoProductos.forEach(async p => {
            await this.deleteProducto(p.id);
            let cantidadP = p.id == producto.CODE || p.id == producto.id || p.id == producto ? cantidad : p.cantidad;

            if (cantidadP > 0) {
              let productCodeToSearch = p.id;
              product = this.modelInfoFromCatalog.products[productCodeToSearch];
              product.CODE = productCodeToSearch;
              actualRange = this.modelPrice.prices.RANGEPRICE[p.id];
              actualRangeKey = [
                actualRange.RANGES.COLOR,
                actualRange.RANGES.SIZEGROUP
              ].toString();
              actualQuantity = quantityGrouped.get(actualRangeKey);

              let price = this.calculatePrice(p.id, actualQuantity);
              let params = {
                producto: product,
                cantidad: cantidadP,
                precio: price,
                color: p.color
              };
              // console.log("🚀 ~ file: Productos.vue:357 ~ params:", params)
              await this.addProducto(params); 
            }
          });
        } else {
          if (cantidad == 0 && productCart != null) {
            // Delete
            await this.deleteProducto(productCart);
          } else if(cantidad > 0) {
            // Add or Update
            let price = this.calculatePrice(producto, cantidad);
            let params = {
              producto: producto,
              cantidad: cantidad,
              precio: price,
              color: this.actualColor
            };
            await this.addProducto(params);
          }
        }

        producto.QUANTITY = parseInt(cantidad);

        this.$emit("changeColor", this.actualColor);
        this.loading--;
        this.debouncedProductQuantity[product] = null;
        // console.timeEnd("updateQuantityDebounced");
      }, 1000);
    },
    changeColor(colorCode) {

      if (!colorCode) return;

      if (
        this.loadingPrices > 0 ||
        (this.soloBlancoEnCarritoSublimacion && colorCode != "01") ||
        (colorCode == "01" &&
          this.sublimacionSoloBlanco &&
          !this.soloBlancoEnCarritoSublimacion &&
          this.totalUnidades > 0)
      )
        return;
      this.actualColor = colorCode;
      this.$emit("changeColor", colorCode);
    },
    getZoneCodeById(zone) {
      if (this.zonas.length == 0) return null;

      let search = this.zonas.find(z => z.Posicion == zone);

      if (search == null)
        return this.zonas[0].Zona.toUpperCase().replace("_", " ");

      if (!search.Zona) {
        console.error("Zona sin identificador de Zona");
      }
      return search.Zona?.toUpperCase()?.replace("_", " ");
    },
    calculatePrice: function (producto, cantidad) {
      // Comprobamos si nos encontramos en stamina o no
      let NumProductSameType = parseInt(cantidad);

      // Si nos encontramos en stamina, debemos juntar la cantidad de aquellos productos con los mismos precios

      let aux = dynamicPrice(
        this.modelPrice.prices,
        this.currency,
        this.modelInfoFromCatalog,
        producto.CODE ?? producto.code ?? producto,
        NumProductSameType,
        "en",
        this.modelPrice.extra.priceNoIncr,
        this.modelPrice.extra.aplicaPack,
        this.modelPrice.extra.hasRangePrices
      );
      let calculatedPrice = parseFloat(aux);

      return calculatedPrice;
    },
    async deleteCart() {
      if (this.disabledClearBtn)
        return;

        this.loading++;
      await this.deleteAllProducts();
      this.loading--;
    },
    emitClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.table-products {
  overflow-x: hidden;
  overflow-y: auto;

  thead,
  tbody {
    display: block;
  }

  tbody {
    max-height: 336px !important;
    overflow-y: scroll;
  }
  td,
  th {
    padding: 0px !important;
    padding-left: 15px !important;
    border-bottom: 0px !important;
  }

  td.text-start {
     height: 38px !important;
  }

  tr:hover {
    background: inherit !important;
  }

  .size-text {
    color: var(--Color-Black, #121212);
    font-family: $heading-font-family;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
