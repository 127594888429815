import state from './ModeloState.js'
import mutations from './ModeloMutations.js'
import actions from './ModeloActions.js'
import getters from './ModeloGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
