import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import i18n from './i18n'
import router from './router'
// import PerfectScrollbar from 'vue2-perfect-scrollbar'
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

Vue.config.productionTip = false

Vue.filter('filtrarPrecios', function (value) {
  var decimales = 4;
  if (!value) return 0.00;
  var signo = (value >= 0 ? 1 : -1);
    value = value * signo;
    if (decimales === 0) //con 0 decimales
        return signo * Math.round(value);
    // round(x * 10 ^ decimales)
    value = value.toString().split('e');
    value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + decimales) : decimales)));
    // x * 10 ^ (-decimales)
    value = value.toString().split('e');
    return signo * (value[0] + 'e' + (value[1] ? (+value[1] - decimales) : -decimales));
})
// Vue.use(PerfectScrollbar)


new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
