<template>
  <v-data-table
    dense
    :headers="headers"
    :items="productosTabla"
    item-key="Referencia"
    :hide-default-footer="true"
    class=""
  >
    <template #item.actions="{ item }">
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: {
    headers: {
      type: Array,
      default: new Array(),
      cargando: false,
    },
    productosTabla: {
      type: Array,
      default: new Array(),
      cargando: false,
    },
    Referencia: {
      type: String,
      default: "Id",
      cargando: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    deleteItem(item) {
      this.$emit("deleteItem", item);
    },
  },
};
</script>
