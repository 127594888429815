<template>
  <v-expansion-panels class="componentCartElement" hover  :disabled="loading">
    <v-expansion-panel
      v-for="reproceso in cart"
      :id="'reproceso_expansion_' + reproceso.id"
      :key="reproceso.id"
      active-class="active-panel"
      hover
      :disabled="loading"
      @change="updateTimeoutHeight(reproceso.id)"
    >
      <v-expansion-panel-header>
        <div class="font-size-17">
          <b>[{{ reproceso.id }}]</b>
          <span class="ml-2">{{ getListModelCode(reproceso) }}</span>
        </div>
        <b class="max-width-fit-content mr-2 font-size-17">
            {{ correct2Decimals(reproceso.total) }}{{ currencySymbol }}
        </b>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col>
            <v-card :disabled="loading">
              <v-card-title class="title-list py-1 text-wrap">
                <b>{{ $t("presupuesto.fichaReprocesos.Productos").toUpperCase() }}</b><v-spacer></v-spacer>
                {{ correct2Decimals(calculateTotal(reproceso.productos))}}{{ currencySymbol }}
                </v-card-title>
              <v-divider></v-divider>
              <v-list>
                <v-list-item
                  v-for="producto in productWithCorrectRound(reproceso.productos)"
                  :key="producto.id"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap"
                      ><b>[{{ producto.reference }}]</b>
                      {{ producto.description }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="font-size-17 text-wrap"
                      >{{ producto.quantity }}x{{ producto.price
                      }}{{ currencySymbol }} = {{ producto.total
                      }}{{ currencySymbol }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="reproceso.manipulados && reproceso.manipulados.articulos && reproceso.manipulados.articulos != 0"
          >
            <v-card :disabled="loading">
              <v-card-title class="title-list py-1"
                ><b>{{ $t("configuracion.ReprocesosGenerales.Manipulados").toUpperCase() }}</b><v-spacer></v-spacer>
                {{ calculateTotal(reproceso.manipulados.articulos)
                }}{{ currencySymbol }}</v-card-title
              >
              <v-divider></v-divider>
              <v-list>
                <v-list-item
                  v-for="articulo in reproceso.manipulados.articulos"
                  :key="articulo.id"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap">
                      <b>[{{ articulo.id }}] {{ articulo.reference }}</b> -
                      {{ articulo.description }}</v-list-item-title
                    >
                    <v-list-item-subtitle class="font-size-17"
                      >{{ articulo.quantity }}x{{ correct4Decimals(articulo.price)
                      }}{{ currencySymbol }} = {{ correct2Decimals(articulo.total)
                      }}{{ currencySymbol }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col v-if="reproceso.etiquetas && reproceso.etiquetas.length != 0">
            <v-card :disabled="loading">
              <v-card-title class="title-list py-1 text-wrap"
                ><b>{{ $t("configuracion.ReprocesosGenerales.Etiquetas").toUpperCase() }}</b><v-spacer></v-spacer>
                {{ calculateTotal(reproceso.etiquetas)
                }}{{ currencySymbol }}</v-card-title
              >
              <v-divider></v-divider>
              <v-list>
                <v-list-item
                  v-for="etiqueta in reproceso.etiquetas"
                  :key="etiqueta.id"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap"
                      ><b>{{ etiqueta.tecnica }}</b> {{ correct2Decimals(etiqueta.total)
                      }}{{ currencySymbol }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card :disabled="loading">
              <v-card-title class="title-list py-1"
                ><b>{{ $t("presupuesto.fichaReprocesos.PrecioReprocesos").toUpperCase() }}</b><v-spacer></v-spacer>
                {{ calculateReprocesosOnly(reproceso.reprocesos)
                }}{{ currencySymbol }}
            </v-card-title>
              <v-divider></v-divider>
              <v-list>
                <v-list-item v-for="r in reproceso.reprocesos" :key="r.id">
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap"
                      ><b
                        >{{ $t(`Zonas.${r.zona.replace("_", " ")}`) }} -
                        {{ translateTecnica(r.tecnica) }}:</b
                      >
                      {{ correct2Decimals(r.total) }}{{ currencySymbol }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <div class="mt-5">
          <v-spacer></v-spacer>
          <v-btn
            :loading="loadingPrint[reproceso.id] || loading"
            :disable="loadingPrint[reproceso.id] || loadingDelete[reproceso.id] || loading"
            class="mr-3"
            outlined
            
            @click="printReprocesoLocal(reproceso)"
          >
            <v-icon left  size="30" class="mr-2">mdi-arrow-down-bold-circle</v-icon> <span>{{ $t("general.Presupuesto")}}</span>
          </v-btn>
          <v-btn
            v-if="!disableDelete"
            :loading="loadingDelete[reproceso.id] || loading"
            :disable="loadingPrint[reproceso.id] || loadingDelete[reproceso.id] || loading"
            dark
            @click="deleteReprocesoLocal(reproceso)"
          >
            <v-icon left size="30" class="mr-2">mdi-delete-circle</v-icon> <span>{{ $t("configuracion.ReprocesosGenerales.Eliminar") }}</span>
          </v-btn>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    cart: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    },
  data() {
    return {
        loadingPrint: {},
        loadingDelete: {},
    };
  },
  computed: {
    ...mapGetters("config", ["translateTecnica", "clientCartAvalible"]),
    ...mapGetters("modelo", ["currencySymbol"]),
  },
  methods: {
      ...mapActions("carrito", ["printReproceso", "printReprocesoClient", "deleteLineComercialCart", "deleteLineClientCart"]),
    ...mapActions('config', ['updateHeightFrame']),
    productWithCorrectRound(value) {
      return value.map((producto) => {
        return {
          ...producto,
          total: this.correct2Decimals(producto.total),
          price: this.correct4Decimals(producto.price),
        };
      });
    },
    correct2Decimals(value) {
      return value.toLocaleString(this.$i18n, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    correct4Decimals(value) {
      return value.toLocaleString(this.$i18n, { minimumFractionDigits: 2, maximumFractionDigits: 4 });
    },
      updateTimeoutHeight(reprocesoId)
    {
      setTimeout(() => {
        this.updateHeightFrame();
        if (reprocesoId)
        {
          const element = this.$el.querySelector('#reproceso_expansion_' + reprocesoId);
          if (element) {
            element.scrollIntoView(
              {
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest'
              }
            );
          }
        }
      }, 350);
    },
      calculateReprocesosOnly(reprocesos) {
        return reprocesos.reduce((total1, r) => {
          let price = r.articulos.reduce((total, articulo) => {
            return total + articulo.total;
          }, 0);
            return total1 + price
          }, 0 );
    },
    calculateTotal: function (articulos) {
      return this.correct2Decimals(articulos
        .reduce((total, articulo) => {
          return total + articulo.total;
        }, 0));
    },
    getListModelCode: function (reproceso) {
      let max = 2;
      return reproceso.modelos
        .map((modelo) => {
          // Si se han añadido 3 modelos, se indica unos puntos suspensivos y no se escriben más
          if (max < 0) return;
          if (max-- <= 0) return "...";
          return modelo.modelCode + " - " + modelo.nombre;
        })
        .join(", ");
    },
    async printReprocesoLocal(reproceso) {
      this.loadingPrint[reproceso.id] = true;
      this.$emit("loadingPrint", true);
        this.loadingPrint = { ...this.loadingPrint };

        let printFunction = this.clientCartAvalible ? this.printReprocesoClient(reproceso.id) : this.printReproceso(reproceso.id);

      printFunction.finally(() => {
        this.loadingPrint[reproceso.id] = false;
        this.$emit("loadingPrint", false);
      });
    },
    async deleteReprocesoLocal(reproceso) {
      this.loadingDelete[reproceso.id] = true;
      this.$emit("loadingDelete", true);
        this.loadingDelete = { ...this.loadingDelete };

        let deleteFunction = this.clientCartAvalible ? this.deleteLineClientCart(reproceso.id) : this.deleteLineComercialCart(reproceso.id);

      deleteFunction.finally(() => {
        this.loadingDelete[reproceso.id] = false;
        this.$emit("loadingDelete", false);
      });
    },
  },
};
</script>

<style>
.max-width-fit-content
{
  max-width: fit-content;
}

.title-list {
  font-size: 1.05rem;
}

.active-panel{
  box-shadow: 0px 3px 1px -2px #265a28, 0px 2px 2px 0px #265a28, 0px 1px 5px 0px #265a28;
}

#carrito > div.item.shark-2 > div > div.clientCartExpansionPanel > div > div > div > div {
  padding: 0 !important;
}

.container.body-dialog .col {
  max-width: 441px;
}
</style>