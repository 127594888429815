
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import carrito from "./carrito/Carrito";
import modelo from "./modelo/Modelo";
import config from "./config/Config"

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    carrito,
    modelo,
    config
  }
})
