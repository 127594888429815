<template>
  <v-data-table
    dense
    :headers="headers"
    :items="productosTabla"
    item-key="Referencia"
    :hide-default-footer="true"
    class=""
  ></v-data-table>
</template>
<script>
export default {
  props: {
    headers: {
      type: Array,
      default: new Array(),
      cargando: false,
    },
    productosTabla: {
      type: Array,
      default: new Array(),
      cargando: false,
    },
    Referencia: {
      type: String,
      default: "Id",
      cargando: false,
    },
  },
};
</script>
<style scoped lang="scss">
.v-data-table::v-deep th {
  font-size: 16px !important;
}
.v-data-table::v-deep td {
  font-size: 14px !important;
}
</style>
