import _ from "lodash";

export default {
  SET_CODIGOS_MODELOS(state, payload) {
    state.codigosModelos = payload;
  },
  SET_CATALOG_INFO(state, payload) {
    state.catalogInfo = payload;
    state.codigosModelos = Object.keys(state.catalogInfo)
  },
  SET_COLOR_FONDO(state, payload) {
    state.colorFondo = payload;
  },

  SET_PRODUCTOS_COLOR(state, payload) {
    let modelo = payload.modelo;
    let color = payload.color;

    var producto = {
      modelo: modelo,
      color: color,
      productos: payload.producto,
    };
    let productoExistente = state.productos.find(
      (p) => p.modelo == modelo && p.color == color
    );
    if (productoExistente !== undefined) {
      Object.assign(productoExistente, producto);
    } else state.productos.push(producto);
  },
  ADD_MODELO(state, payload) {
    payload = _.mapKeys(payload, (v, k) => _.startCase(k));
    state.modelos.unshift(payload);
  },
  UPDATE_MODELO(state, payload) {
    payload = _.mapKeys(payload, (v, k) => _.startCase(k));
    const modelos = state.modelos.find((u) => u.Id === payload.Id);
    Object.assign(modelos, payload);
  },
  DELETE_MODELO(state, payload) {
    payload = _.mapKeys(payload, (v, k) => _.startCase(k));
    state.modelos.Items = state.modelos.Items.filter(
      (u) => u.Id !== payload.Id
    );
  },

  GET_TAMANOS_TAMANOS_TRANSFERESPECIALES_BYCALIDAD(state, calidad) {
    state;
    calidad;
  },
  GET_TAMANOS_TAMANOS_TRANSFERETIQUETAS(state, calidad) {
    state;
    calidad;
  },
  SET_MANIPULADOS(state, manipulados) {
    manipulados;
    state;
  },
  UPDATE_IMAGEN_MODELO(state, payload) {
    if (
      payload != null &&
      payload.modelo != null &&
      payload.modelo.id == state.modelo.id
    ) {
      if (!payload.color) {
        for (var i = 0; i < state.modelo.zonas.length; i++) {
          if (state.modelo.zonas[i][1] == payload.zona[1]) {
            state.modelo.zonas[i][2] = payload.zona[2];
            break;
          }
        }
      } else {
        for (var j = 0; j < state.modelo.productos.length; j++) {
          if (state.modelo.productos[j].color == payload.color.CODE) {
            for (var z = 0; z < state.modelo.productos[j].zonas.length; z++) {
              if (
                state.modelo.productos[j].zonas[z].POSITION_DESCRIPTION ==
                payload.zona[1]
              ) {
                state.modelo.productos[j].zonas[z].URL_IMAGEN = payload.zona[2];
                break;
              }
            }
            break;
          }
        }
        for (var f = 0; f < state.modelo.zonas.length; f++) {
          if (state.modelo.zonas[f][1] == payload.zona[1]) {
            state.modelo.zonas[f][2] = payload.zona[2];
            break;
          }
        }
      }
    }
  },
  UPDATE_MODELS_PRICES(state, payload) {
    state.modelInfo = payload;
  },
  SET_WAIT_EXTRANET(state, payload) {
    state.waitExtranet = payload;
  },
  ADD_LOADING(state) {
    state.loading++;
  },
  REMOVE_LOADING(state) {
    state.loading--;

    if (state.loading < 0) {
      state.loading = 0;
    }
  },
  ADD_ALERT(state, payload) {
    state.actualAlert = payload;
  }
};
