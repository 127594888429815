
export default {
    isDisableCustomPrices: () => process.env.VUE_APP_DISABLE_CUSTOM_PRICE === 'true',
    comercialLoginAvalible() {
        if (typeof process.env.VUE_APP_COMERCIAL_LOGIN === "boolean") {
            return process.env.VUE_APP_COMERCIAL_LOGIN;
        }

        if (typeof process.env.VUE_APP_COMERCIAL_LOGIN === "string") {
            return process.env.VUE_APP_COMERCIAL_LOGIN.toLowerCase() === "true";
        }

        return false;
    },
    clientCartAvalible() {
        if (typeof process.env.VUE_APP_CLIENT_CART === "boolean") {
            return process.env.VUE_APP_CLIENT_CART;
        }

        if (typeof process.env.VUE_APP_CLIENT_CART === "string") {
            return process.env.VUE_APP_CLIENT_CART.toLowerCase() === "true";
        }

        return false;
    },
    isPreciosProveedoresActive: () => process.env.VUE_APP_PRECIOS_PROVEEDORES === 'true',
    defaultPdfConfig: (state, getters, rootState) => {

        return {
            dialog: false,
            colorSeleccionado: rootState.carrito.carrito.colorCode,
            porcentajeProductos: 0.0,
            porcentajeReprocesos: 0.0,
            costeExtra: 0.0,
            Observaciones: "",
            Empresa: "",
            correoContacto: "",
            telefono: "",
            calle: "",
            cp: "",
            poblacion: "",
            ciudad: "",
            pais: "",
            customImg: null,
            logo: (rootState.modelo.modelInfo.originalShop == "STM" || rootState.modelo.modelInfo.originalShop == "STA") 
                ? "https://static.gorfactory.es/b2b/Calculadora/logo_stamina.png"
                : "https://static.gorfactory.es/b2b/Calculadora/logo_roly.png" 
        };

    },
    isProveedor: (state) => state.isProveedor && process.env.VUE_APP_PRECIOS_PROVEEDORES === 'true',
    allSizes: (state) => state.allSizes,
    getMedidasByTamano: (state) => (tamano) => {
        return state.allSizes[tamano ?? "A3"]
    },
    actualLanguage: (state) => {
        return state.locale
    },
    actualLanguageRegion: (state) => {
        if (state.locale == "es")
            return "es-ES";
        else if (state.locale == "en")
            return "en-GB";
        else if (state.locale == "fr")
            return "fr-FR";
        else if (state.locale == "de")
            return "de-DE";
        else if (state.locale == "it")
            return "it-IT";
        else if (state.locale == "pt")
            return "pt-PT";
        else if (state.locale == "ru")
            return "ru-RU";
        else if (state.locale == "fi")
            return "fi-FI";
        else if (state.locale == "ro")
            return "ro-RO";
        else if (state.locale == "nl")
            return "nl-NL";
        else if (state.locale == "pl")
            return "pl-PL";
        else if (state.locale == "gr" || state.locale == "el")
            return "el-GR";
        else if (state.locale == "lv")
            return "lv-LV";
        else if (state.locale == "cz" || state.locale == "cs")
            return "cs-CZ";
        else if (state.locale == "sl" || state.locale == "si")
            return "si-SL";
        else
            return state.locale
    },
    tecnicaMapExtraParam: () => {
        return new Map([
            ["Serigrafia", "Serigrafia"],
            ["TransferTintas", "Transfer"],
            ["Impresion", "ImpresionDirecta"],
            ["Manipulacion", "Manipulados"],
            ["SublimacionParcial", "Sublimacion"],
            ["Bordados", "Bordado"],
            ["SerigrafiaEtiquetas", "Etiquetas"],
            ["TransferNumeros", "Números"],
            ["ImpresionVinilo", "ImpresionVinilica"],
            ["SerigrafiaNonWoven", "SerigrafiaNonWoven"],
            ["TransferEspeciales", "TransferEspeciales"],
            ["Digital", "Digital"],
            ["Laser", "Laser"],
            ["Tampografia", "Tampografia"],
            ["Termografia", "Termograbado"],
            ["BordadoStm", "Bordados"],
            ["CuatricomiaStm", "Cuatricomia"],
            ["DTF", "DTF"],
            ["Planchado", "Planchado"],// No existe
            ["Doming", "Doming"],

            // Nuevos roly
            ["SublimacionTotal", "Sublimacion"],
            ["SublimacionTotalMedidas", "Sublimacion"],
            ["TransferEtiquetas", "Etiquetas"],
            ["TransferNumeroDigitos", "Números"],

            // Nuevos stamina
            ["SerigrafiaStm", "Serigrafia"],
            ["SerigrafiaStmSombreros", "Serigrafia"],
            ["SublimacionStm", "Sublimacion"],
            ["SublimacionStmSombreros", "Sublimacion"],
            ["SublimacionStmTazas", "Sublimacion"],
            ["TransferDigitalStm", "Transfer"],
            ["TransferStm", "Transfer"],
        ]);
    },
    getTecnicaMapExtraParam: (state, getter) => (tecnica) => {
        return getter.tecnicaMapExtraParam.get(tecnica) ?? tecnica;
    },
    translateTecnica: () => function (tecnica) {
        if (!tecnica) return;
        if (tecnica.includes('Sublimacion'))
            return this.$t('Tecnicas.SUBLIMACIÓN');
        else if (tecnica.includes('Serigrafia'))
            return this.$t("Tecnicas.SERIGRAFÍA");
        else if (tecnica.includes('TransferEspeciales'))
            return this.$t("Tecnicas.TRANSFER ESPECIALES");
        else if (tecnica.includes('TransferNumeros'))
            return this.$t("Tecnicas.NUMEROS-TRANSFER");
        else if (tecnica.includes('Transfer'))
            return this.$t("Tecnicas.TRANSFER");
        else if (tecnica.includes('ImpresionVinilo'))
            return this.$t("Tecnicas.IMPRESIÓN VINILO");
        else if (tecnica.includes('ImpresionDirecta') || tecnica.includes('Impresion'))
            return this.$t("Tecnicas.IMPRESIÓN DIRECTA");
        else if (tecnica.includes("Bordado"))
            return this.$t("Tecnicas.BORDADOS");
        else if (tecnica.includes("TransferNumeros"))
            return this.$t("Tecnicas.NÚMEROS");
        else if (tecnica.includes("SerigrafiaNonWoven"))
            return this.$t("Tecnicas.BOLSAS NON WOVEN");
        else if (tecnica.includes("Digital"))
            return this.$t("Tecnicas.DIGITAL")
        else if (tecnica.includes("Laser"))
            return this.$t("Tecnicas.LASER")
        else if (tecnica.includes('DTFNumeros'))
            return this.$t("Tecnicas.NUMEROS-DTF");
        else if (tecnica.includes("DTF"))
            return this.$t("Tecnicas.TRANSFER DIGITAL (DTF)");
        else if (tecnica.includes("Tampografia"))
            return this.$t("Tecnicas.TAMPOGRAFÍA")
        else if (tecnica.includes("Termografia"))
            return this.$t("Tecnicas.TERMOGRABADO");
        else if (tecnica.includes("DomingStm"))
            return this.$t("Tecnicas.DOMING");
        else if (tecnica.includes("CuatricomiaStm"))
            return this.$t("Tecnicas.CUATRICOMIA");
        else if (tecnica.includes("Planchado"))
            return this.$t("Tecnicas.PLANCHADO");
        else if (tecnica.includes("Manipulados") || tecnica.includes("Manipulacion"))
            return this.$t("Tecnicas.MANIPULADOS");
        else
            return "";

    },
    comercialInfo: (state) => state.comercial,
    comercialToken: (state) => state.comercial?.authToken,
    userList: (state) => state.userList ? Object.values(state.userList) : [],
    loggedClient: (state) => state.loggedClient,
    userOfClient: (state) => state.userOfClient,
    key: (state) => state.key,
    resetCalculadora: (state) => state.resetCalculadora,
    isModeIntranetActive: (state, getters) => getters.comercialLoginAvalible && getters.comercialToken && getters.comercialToken != "",
    colorSelected: state => state.colorSelected,

    activeComercial: (state, getters) => getters.loggedClient && getters.userOfClient && getters.comercialToken,
    activeClient: (state, getters) => !getters.activeComercial && getters.clientCartAvalible,
}