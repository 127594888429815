<template>
  <div class="btt-layout" :class="colorClass">

    <v-btn v-if="success" :disabled="disabled" v-bind="bind" text class="cotizador-btn-success" v-on="on">
    <slot> Button </slot>
    </v-btn>
    <v-btn v-else :disabled="disabled" v-bind="bind" text class="cotizador-btn " :class="{animated}" v-on="on">
    <slot> Button </slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "CotizadorBtn",
  components: {},
  filters: {},
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    bind: {
      type: Object,
      default: () => (null),
    },
    on: {
      type: Object,
      default: () => (null),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({}),
  computed: {
    colorClass: function () {
      return "btt-default";
    },
  },
  mounted: function () {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.cotizador-btn {
  border: 1px solid black;
  border-radius: 25px;
  cursor: pointer;
  pointer-events: all;

  &.animated {
    animation: blink-primary 2s linear infinite;
  }
}

.cotizador-btn-success {
  border: 1px solid var(--v-primary-base);
  background-color: var(--v-primary-base);
  border-radius: 25px;
}

@keyframes blink-primary {
  0%, 100% {background-color:white;}
  50% {background-color: var(--v-primary-base);}
}
</style>
