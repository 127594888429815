import _ from "lodash";
import { noImgUrl } from "@/utils/imageUtils";

export default {
  codigosModelos: (state) => {
    return _.cloneDeep(state.codigosModelos);
  },
  modelos: (state) => {
    return _.cloneDeep(state.modelos);
  },
  productos: (state) => {
    return _.cloneDeep(state.productos);
  },
  getModeloById: (state) => (id) => {
    const modelo = state.modelos.find((modelo) => modelo.id === id.id);
    return _.cloneDeep(modelo);
  },
  getProductosByModeloColor: (state, getters, rootState, rootGetter) => (colorCode) => {
    if (getters.modelInfoFromCatalog?.products == null)
      return [];

    const listSizes = Object.keys(getters.modelInfoFromCatalog.sizes);

    const listProductsCodes = Object.keys(getters.modelInfoFromCatalog.products);

    let listSizesByColor = [];

    listSizes.forEach(sizeCodeId => {
      const catalogInfoSize = getters.modelInfoFromCatalog.sizes[sizeCodeId];
      let actualSizeInfo = {
        size: sizeCodeId,
        length: catalogInfoSize?.L,
        weight: catalogInfoSize?.WEIGHT,
        volumen: catalogInfoSize?.V,
        width: catalogInfoSize?.W,
      };

      let productCode = listProductsCodes.find(p => getters.modelInfoFromCatalog.products[p].SIZE == sizeCodeId && getters.modelInfoFromCatalog.products[p].COLOR == colorCode);
      if (productCode) {
        // let stock = state.modelInfo.stock.find(s => s.id == productCode)?.storeStock
        listSizesByColor.push({
          CODE: productCode,
          // SIZENAME: actualSizeMedidas.TallaName,
          // MEDIDAS: [...actualSizeMedidas.Posiciones],
          MEASURES: actualSizeInfo?.measures,
          ...getters.modelInfoFromCatalog?.products?.[productCode],
          // STOCK: { ...stock?.[0] },
          ORDER: rootGetter['config/catalogSizes'][sizeCodeId].ORDEN,
        })
      }
    })

    return _.orderBy(listSizesByColor, "ORDER", "asc");
  },
  key: (state) => {
    return _.cloneDeep(state.key);
  },
  modelPrice: state => _.cloneDeep(state.modelInfo),
  hasRangePrices: state => state.modelInfo?.extra?.hasRangePrices ?? false,
  waitExtranet: (state) => state.waitExtranet,
  loadingModelo: (state) => state.loading > 0,
  incrementoPrendasExactas: (state) => state.modelInfo?.infoReprocesos?.IncrementoPrendasExactas ?? false,
  zonas: (state) => state.modelInfo?.filteredZones.sort(z => z.Posicion) ?? [],
  zonasMappedByPosicion: (state) => state.modelInfo?.filteredZones.reduce((resultado, zona) => {
    resultado[zona.Posicion] = { ...zona };
    return resultado;
  }, {}) ?? {},
  zonasDescription: (state, getters) => {
    let setDescription = [];

    if (!state.modelInfo?.tallasInfo || state.modelInfo.tallasInfo.length <= 0)
      return setDescription;

    if (!getters.modelInfoFromCatalog || getters.modelInfoFromCatalog.originalShop == "GOR")
      return setDescription;

    state.modelInfo.tallasInfo[0]?.Posiciones.forEach(p => {
      setDescription.push({
        Posicion: p.Posicion,
        Descripcion: p.Descripcion,
        Alto: p.Alto,
        Ancho: p.Ancho
      })
    })

    return setDescription;
  },
  isStaminaModel: (state, getters) => {
    return getters.modelInfoFromCatalog.originalShop
      ? getters.modelInfoFromCatalog.originalShop == "STA"
      : getters.modelInfoFromCatalog.shop == "STA";
  },
  actualAlert: state => state.actualAlert,
  getFilteredTecnicas: state => zone => state.modelInfo?.filteredZones?.find(z => z.Posicion == zone),
  getFilteredTecnicasExtra: (state, getters) => (zone, tecnicas) => getters.getFilteredTecnicas(zone)?.Tecnicas?.Extra?.[tecnicas],
  getBannedColorListByZoneAndTecnicas: (state, getters) => (zone, tecnicas) => {
    console.log("🚀 ~ tecnicas:", tecnicas)
    console.log("🚀 ~ zone:", zone)
    return getters.getFilteredTecnicasExtra(zone, tecnicas)?.BannedColors ?? [];
  },

  actualColorInfo: (state, getters, rootState, rootGetters) => state.modelInfo?.colorInfo?.find(c => c.code == rootGetters['config/colorSelected']),
  transferStmTypeAvalible: (state, getters) => zone => getters.getFilteredTecnicasExtra(zone, "Transfer")?.AvaliblesTransfer,

  // transferStmInfo: (state, getters) => zone => getters.getFilteredTecnicasExtra(zone, "Transfer"),
  // termograbadoStmTypeAvalible: (state, getters) => zone => getters.getFilteredTecnicasExtra(zone, "Termografia"),
  stmMaxColor: (state, getters) => (zone, tecnica) => getters.getFilteredTecnicasExtra(zone, tecnica)?.MaxColor,
  // domingExtraInfo: (state, getters) => zone => getters.getFilteredTecnicasExtra(zone, "Doming"),
  sublimacionStmInfo: (state, getters) => zone => getters.getFilteredTecnicasExtra(zone, "Sublimacion"),
  sublimacionSoloBlanco: (state) => state.modelInfo?.filteredZones?.length > 0 && (state.modelInfo?.filteredZones[0]?.Tecnicas?.Extra?.Sublimacion?.SoloBlanco ?? false),
  onlyOneColor: (state) => state.modelInfo?.infoReprocesos?.OnlyOneColor ?? false,
  // serigrafiaStmInfo: (state, getters) => zone => getters.getFilteredTecnicasExtra(zone, "Serigrafia"),
  zonesFondoByZone: (state, getters) => zone => getters.getFilteredTecnicasExtra(zone, "ZonasFondo"),


  maxValidSize: (state, getters, rootState, rootGetters) => ({ avalibleSizes, zona, tecnica }) => {
    let maxValue = null;
    let minValue = null;

    avalibleSizes.forEach(tam => {
      let isBigger;
      // console.log("🚀 ~ file: ModeloGetters.js:154 ~ tam:", tam)

      if (tam.cm2) {
        isBigger = !maxValue || (tam.cm2 >= maxValue.cm2);
      } else {
        isBigger = !maxValue || (tam.Ancho >= maxValue.Ancho && tam.Alto >= maxValue.Alto);
      }

      let numExcededSize;
      if (tam.cm2) {
        numExcededSize = rootGetters["carrito/medidasMaximas"]({
          posicion: zona,
          cm2: tam.cm2,
          tecnica
        });
      } else {
        numExcededSize = rootGetters["carrito/medidasMaximas"]({
          posicion: zona,
          xaxis: tam.Ancho,
          yaxis: tam.Alto,
          tecnica
        });
      }

      if (numExcededSize == null) return;

      if (isBigger && numExcededSize.length == 0) {
      // console.log("🚀 ~ file: ModeloGetters.js:146 ~ tam:", tam)
        maxValue = tam;
      }

      if (!minValue || (tam.cm2 ? tam.cm2 < minValue.cm2 : (tam.Ancho * tam.Alto) < (minValue.Ancho * minValue.Alto))) {
        // console.log("MINIMOOOOOOOO", tam);
        minValue = tam;
      }
    });

    if (!maxValue && !minValue) return null;

    // console.log("🚀 ~ file: ModeloGetters.js:155 ~ maxValue:", !maxValue)
    if (!maxValue) {
      maxValue = minValue;
    }

    let cm2MaxSize = rootGetters["carrito/unidadMasPequeña"]({ posicion: zona, tecnica });

    if (cm2MaxSize == null) return null;

    return {
      ...maxValue,
      cm2MaxSize: cm2MaxSize.Ancho + "x" + cm2MaxSize.Alto
    };
  },
  currency: (state) => {
    if (state.modelInfo?.extra?.currency == "USD" || state.modelInfo?.extra?.currency == "$")
      return "USD";
    else if (state.modelInfo?.extra?.currency == "EUR" || state.modelInfo?.extra?.currency == "€")
      return "EUR";
    else if (state.modelInfo?.extra?.currency == "GBP" || state.modelInfo?.extra?.currency == "£")
      return "GBP";
    else
      return "EUR";
  },
  currencySymbol: (state) => {
    if (state.modelInfo?.extra?.currency == "USD" || state.modelInfo?.extra?.currency == "$")
      return "$";
    else if (state.modelInfo?.extra?.currency == "EUR" || state.modelInfo?.extra?.currency == "€")
      return "€";
    else if (state.modelInfo?.extra?.currency == "GBP" || state.modelInfo?.extra?.currency == "£")
      return "£";
    else
      return "€";
  },
  // Getter para comprobar si tiene canonDigital
  hasCanonDigital: (state, getters) => getters.modelInfoFromCatalog?.canonDigital ?? false,
  // colorAvalible: (state) => (state.modelInfo?.colorInfo ?? []).sort((a, b) => a.orden - b.orden),
  colorAvalible: (state, getters, rootState, rootGetter) => {
    console.log("🚀 ~ getters.modelInfoFromCatalog?.colors:", getters.modelInfoFromCatalog?.colors)
    const listColorCatalog = rootGetter['config/catalogColors'];
    if (!getters.modelInfoFromCatalog?.colors || !listColorCatalog) {
      return [];
    }

    const listColorCodes = Object.keys(getters.modelInfoFromCatalog?.colors);

    let colorList = listColorCodes.map(c => {
      const actualColor = listColorCatalog[c]

      let resultObj = {
        code: c,
        orden: actualColor.ORDEN,
        hexcode: actualColor.HEXCODE,
      }

      if (actualColor.URL) {
        resultObj.url = actualColor.URL
      }

      return resultObj;
    })

    return colorList.sort((a, b) => a.orden - b.orden);
  },
  isReprocesosStm: (state) => state.modelInfo?.infoReprocesos?.ProductoPromocional ?? false,
  contieneElastano: (state) => state.modelInfo?.infoReprocesos?.ContieneElastano ?? false, // Para forzar la selección de tinta
  tipoPrendaSublimacion: (state) => state.modelInfo?.infoReprocesos?.SublimacionTipoPrendaId ?? null, // Para seleccionar entre sublimación total o parcial
  forzarTipoTinta: (state) => state.modelInfo?.infoReprocesos?.ForzarTipoTinta ?? false,
  modelInfoFromCatalog: (state, getters, rootState, rootGetters) => state.catalogInfo[rootGetters['carrito/carrito'].modelo],
  modelCode: (state, getters) => getters.modelInfoFromCatalog?.code ?? '',
  modelSlug: (state, getters) => getters.modelInfoFromCatalog?.slug ?? '',
  listColorFondo: (state) => state.colorFondo ?? [],

  hasManipulados: (state) => state.modelInfo?.filteredZones?.some(z => z.Tecnicas.Manipulados) ?? false,
  hasTransfer: (state) => state.modelInfo?.filteredZones?.some(z => z.Tecnicas.Transfer) ?? false,
  hasDtf: (state) => state.modelInfo?.filteredZones?.some(z => z.Tecnicas.Dft) ?? false,
  hasSerigrafia: (state) => state.modelInfo?.filteredZones?.some(z => z.Tecnicas.Serigrafia) ?? false,
  disableTecnicasButton: (state, getters) => getters.modelInfoFromCatalog?.family == "GO" ?? false,
  isFireResistant: (state) => state.modelInfo?.infoReprocesos?.PrendaIgnifuga ?? false, // Para mostrar el aviso en el cotizador
  computedImgUrl: (state, getters) => (zona, color) => {
    let basicImgZone = noImgUrl;
    let hasZone = getters.zonas.some(z => z.Zona == zona.Zona);

    if (getters.modelInfoFromCatalog && color && hasZone) {
      if (getters.modelInfoFromCatalog.image_print && getters.modelInfoFromCatalog.image_print.length > 0) {
        let actualZona = getters.modelInfoFromCatalog.image_print.find(
          (u) => u.typeInt == zona.Posicion && color == u.color
        );

        if (actualZona) return actualZona.url;
      }

      console.warn(`Combinación Color/Zona (${color}/${zona.Zona}) sin imagen válida intentamos usar la imagen de vista`)

      let actualImage = getters.computedImgViewUrl(zona, color);

      if (actualImage) return actualImage;

      console.error(`Combinación Color/Zona (${color}/${zona.Zona}) sin imagen válida ni para zona de la calculadora, ni para vista de la web`)
    }
    else if (getters.modelInfoFromCatalog && hasZone) {
      if (getters.modelInfoFromCatalog.image_print && getters.modelInfoFromCatalog.image_print.length > 0) {
        let actualZona = getters.modelInfoFromCatalog.image_print.find(
          (u) => u.typeInt == zona.Posicion
        );

        if (actualZona) return actualZona.url;
      }

      let actualImage = getters.computedImgViewUrl(zona);

      if (actualImage) return actualImage;
    }

    return basicImgZone;
  },
  computedImgViewUrl: (state, getters) => (zona, color = null) => {
    if (!getters.modelInfoFromCatalog) return null;

    if (color) {
      if (getters.modelInfoFromCatalog.image_views && getters.modelInfoFromCatalog.image_views.length > 0) {
        let actualZona = getters.modelInfoFromCatalog.image_views.find(
          (u) => u.index == zona.Posicion && color == u.color
        );

        if (actualZona) return actualZona.url;
      }
    }
    else {
      if (getters.modelInfoFromCatalog.image_views && getters.modelInfoFromCatalog.image_views.length > 0) {
        let actualZona = getters.modelInfoFromCatalog.image_views.find(
          (u) => u.index == zona.Posicion
        );

        if (actualZona) return actualZona.url;
      }
    }

    return null;
  },
  newComputedImgUrl: (state, getters) => (zona, color = null, skipMarcado = false) => {
    console.log("🚀 ~ color:", color)
    console.log("🚀 ~ zona:", zona)
    // A la hora de mostrar una imagen, primero intentaremos mostrar la imagen de print con el índice a 1, si no lo encontramos, lo intentaremos con el indice a 0, y como último recurso, mostraremos la imagen de vista, si nada de esto funciona, mostramos la imagen por defecto
    let basicImgZone = noImgUrl;
    let hasZone = getters.zonas.some(z => z.Zona == zona.Zona);

    if (!hasZone) return basicImgZone;

    let hasPrintImages = getters.modelInfoFromCatalog.image_print && getters.modelInfoFromCatalog.image_print.length > 0;

    if (hasPrintImages) {
      let actualZona = getters.modelInfoFromCatalog.image_print.find(
        (u) => u.typeInt == zona.Posicion && (!color || color == u.color) && (!skipMarcado ? u.index == 1 : u.index == 0)
      );

      if (actualZona) return actualZona.url;

      if (!skipMarcado) {
        // Intentamos encontrar la imagen sin cuadrado
        actualZona = getters.modelInfoFromCatalog.image_print.find(
          (u) => u.typeInt == zona.Posicion && (!color || color == u.color) && u.index == 0
        );

        if (actualZona) return actualZona.url;
      }
    }

    console.warn(`Combinación Color/Zona (${color}/${zona.Zona}) sin imagen válida intentamos usar la imagen de vista`)
    let hasViewsImages = getters.modelInfoFromCatalog.image_views && getters.modelInfoFromCatalog.image_views.length > 0;

    if (hasViewsImages) {
      let actualZona = getters.modelInfoFromCatalog.image_views.find(
        (u) => u.index == zona.Posicion && (!color || color == u.color)
      );

      if (actualZona) return actualZona.url;

      console.error(`Combinación Color/Zona (${color}/${zona.Zona}) sin imagen válida ni para zona de la calculadora, ni para vista de la web`)
    }

    if (hasPrintImages && skipMarcado) {
      let actualZona = getters.modelInfoFromCatalog.image_print.find(
        (u) => u.typeInt == zona.Posicion && (!color || color == u.color) && u.index == 1
      );

      if (actualZona) return actualZona.url;
    }

    return basicImgZone;
  },
  numMaximunZones: (state) => state.modelInfo?.infoReprocesos?.NumeroMaxZonas ?? 8,
  tecnicaRecomendada: (state) => {
    if (state.modelInfo?.infoReprocesos?.TecnicaRecomendada == "DFT")
      return "DTF";
    else if (state.modelInfo?.infoReprocesos?.TecnicaRecomendada?.toUpperCase() == "SERIGRAFIA")
      return "SERIGRAFÍA";
    else 
      return state.modelInfo?.infoReprocesos?.TecnicaRecomendada;
  },
  advertenciaSerigrafia: (state) => {
    return state.modelInfo?.infoReprocesos?.AdvertenciaSerigrafia;
  },
  tieneSublimacionTotal: (state, getters) => {
    return getters.modelInfoFromCatalog?.code == "BR9004" || getters.modelInfoFromCatalog?.code == 'CP9001' || getters.tipoPrendaSublimacion == 8
  },
  idModel3D: (state, getters) => getters.modelInfoFromCatalog?.id3dModel,
  hasModel3D: (state, getters) => process.env.VUE_APP_ACTIVE_3D == 'true' && getters.idModel3D > 0,
};
