<template>
  <div>
    <v-fade-transition>
      <v-overlay v-if="cargando" absolute>
        <v-progress-circular indeterminate color="gray"></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
    <v-row v-if="avalibleTransferStmType.length > 1" class="mb-3">
      <v-col sm="12" class="mt-0 py-0">
        <!-- Selector tipo transfer (serigrafico o digital) -->
        <v-select
          v-model="typeTransferStm"
          :items="avalibleTransferStmType"
          filled
          :label="$t('configuracion.CalculoPrecios.tipoTransfer')"
          color="primary darken-2"
          prepend-inner-icon="mdi-cog"
          hide-details="true"
          class="mt-0 py-0"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="avalibleTermograbadoType.length > 1" class="mb-3">
      <v-col sm="12" class="mt-0 py-0 mb-3">
        <!-- Selector tipo termograbado -->
        <v-select
          v-model="typeTermograbado"
          :items="avalibleTermograbadoType"
          filled
          :label="$t('configuracion.CalculoPrecios.tipoTermograbado')"
          color="primary darken-2"
          prepend-inner-icon="mdi-cog"
          hide-details="true"
          class="mt-0 py-0"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="hasColorSelect" class="mb-3">
      <v-col sm="12" class="mt-0 py-0 mb-3">
        <!-- Selector de colores -->
        <v-select
          v-if="!isSerigrafiaGajos"
          v-model="colores"
          :items="coloresDisponibles"
          filled
          :label="$t('configuracion.CalculoPrecios.Colores')"
          color="primary darken-2"
          prepend-inner-icon="mdi-format-color-fill"
          hide-details="true"
          class="mt-0 py-0"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="isSerigrafiaGajos || isTransferGajos" class="mb-3">
      <v-col sm="12" class="mt-0 py-0 mb-3">
        <!-- Selector de gajos para serigrafia gajos -->
        <v-select
          v-model="gajos"
          :items="numGajosDisponibles"
          filled
          :label="$t('configuracion.CalculoPrecios.Gajos')"
          color="primary darken-2"
          prepend-inner-icon="mdi-format-color-fill"
          hide-details="true"
          class="mt-0 py-0"
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="isSublimacionSoloBlanco" sm="12" class="mt-0 py-0 mb-3">
      <!-- Aviso en caso de que solo se pueda hacer sublimación con el color blanco (o zona blanca en su defecto) -->
      <v-alert border="bottom" elevation="8" text type="warning" dense>
        {{ $t("configuracion.Sublimacion.sublimacionSoloBlanco") }}
      </v-alert>
    </div>
    <v-row v-if="hasCarasSelect" class="mb-3">
      <v-col sm="12" class="mt-0 py-0">
        <!-- Selector de número de caras -->
        <v-select
          v-model="caras"
          :items="carasarr"
          filled
          :label="$t('configuracion.CalculoPrecios.caras')"
          color="primary darken-2"
          prepend-inner-icon="mdi-bowl"
          hide-details="true"
          class="mt-0 py-0"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="hasPuntadasInput" class="mb-3">
      <v-col sm="12" class="mt-0 py-0">
        <!-- Input para el número de puntadas -->
        <v-text-field
          v-model="puntadas"
          filled
          type="number"
          :label="$t('configuracion.CalculoPrecios.puntadas')"
          color="primary darken-2"
          prepend-inner-icon="mdi-tshirt-crew"
          hide-details="true"
          class="mt-0 py-0"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="tecnica == 'Digital'" class="mb-3">
      <v-col sm="12" class="mt-0 py-0 mb-3 pr-0 pl-0">
        <span class="text-subtitle-2">
          {{ $t("configuracion.Seleccione un tamaño de la lista") }}
        </span>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>
            {{
              $t(
                "configuracion.El tamaño máximo para esta combinación de tallas es"
              ) +
              " " +
              maxDigitalTam +
              " cm2"
            }}
          </span>
        </v-tooltip>
        <v-select
          v-model="digitalTamCode"
          :items="digitalTam"
          item-text="label"
          item-value="code"
          filled
          :label="$t('configuracion.CalculoPrecios.tamaño')"
          color="primary darken-2"
          prepend-inner-icon="mdi-crop-free"
          hide-details="true"
          class="mt-0 py-0"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="tecnica == 'DomingStm'" class="mb-3">
      <v-col sm="12" class="mt-0 py-0 mb-3 pr-0 pl-0">
        <v-row class="mb-3">
          <v-select
            v-model="domingTamCode"
            :items="avalibleSizeDoming"
            item-text="label"
            item-value="code"
            filled
            :label="$t('configuracion.CalculoPrecios.tamaño')"
            color="primary darken-2"
            prepend-inner-icon="mdi-crop-free"
            hide-details="true"
            class="mt-0 py-0"
          ></v-select>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="
        (tecnica == 'SublimacionStm' &&
          avalibleSublimacion &&
          !isSublimacionTazas &&
          !modelCode.startsWith('GO') &&
          numCarasSublimacion == 0) ||
        (tecnica == 'TransferStm' && typeTransferStm == 'TransferDigitalStm')
      "
      class="mb-3"
    >
      <v-col sm="12" class="mt-0 py-0 mb-3">
        <v-row class="px-3 mt-0">
          <v-text-field
            v-model="tamaño"
            :disabled="tecnica == 'SublimacionStm' && isSublimacionBloquedCm2"
            filled
            type="number"
            min="1"
            :max="maxSizeForSublimacion?.Area ?? 0"
            :label="$t('configuracion.CalculoPrecios.tamaño')"
            color="primary darken-2"
            prepend-inner-icon="mdi-tshirt-crew"
            hide-details="true"
            class="mt-0 py-0 mb-3"
            @input="updateSizeCm2($event, 'Area')"
          ></v-text-field>
        </v-row>

        <v-row>
          <v-col class="mt-0 py-0">
            <v-text-field
              v-model="altoTamanoCm2"
              :disabled="isSublimacionBloquedCm2"
              filled
              type="number"
              suffix="mm"
              min="1"
              :max="
                tecnica == 'SublimacionStm'
                  ? maxSizeForSublimacion.Alto
                  : maxSizeForTransfer.Alto
              "
              :label="$t('configuracion.Sublimacion.Alto')"
              color="primary darken-2"
              prepend-inner-icon="mdi-swap-vertical"
              hide-details="true"
              class="mt-0 py-0 mb-3"
              @input="updateSizeCm2($event, 'Alto')"
            />
          </v-col>
          <v-col class="mt-0 py-0">
            <v-text-field
              v-model="anchoTamanoCm2"
              :disabled="isSublimacionBloquedCm2"
              filled
              type="number"
              suffix="mm"
              min="1"
              :max="
                tecnica == 'SublimacionStm'
                  ? maxSizeForSublimacion.Ancho
                  : maxSizeForTransfer.Ancho
              "
              :label="$t('configuracion.Sublimacion.Ancho')"
              color="primary darken-2"
              prepend-inner-icon="mdi-swap-horizontal"
              hide-details="true"
              class="mt-0 py-0 mb-3"
              @input="updateSizeCm2($event, 'Ancho')"
            />
          </v-col>
        </v-row>
        <v-alert
          v-if="
            !isBlanco &&
            (tecnica == 'SublimacionStm' || tecnica == 'Sublimacion')
          "
          class="mx-5"
          border="top"
          color="red"
          elevation="8"
          text
          type="error"
          dense
        >
          {{ $t("alert.alertSublimacionZonaNoBlanca") }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row
      v-if="tecnica == 'SublimacionStm' && !avalibleSublimacion"
      class="mb-3 mt-0"
    >
      <v-col sm="12" class="mt-0 py-0 mb-3">
        <v-alert border="bottom" elevation="8" text type="error" dense>
          {{ $t("configuracion.Sublimacion.sublimacionBlanco") }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row
      v-if="
        tecnica != 'SublimacionStm' ||
        (tecnica == 'SublimacionStm' && avalibleSublimacion)
      "
      class="mb-3 mt-0"
    >
      <v-col sm="12" class="mt-0 py-0 mb-3">
        <template v-if="tecnica != 'Laser'">
          <span class="subtitle-2">
            {{ $t("configuracion.Otros parámetros") }}
          </span>
          <br />
          <check-repeticion
            v-if="
              tecnica != 'TransferStm' ||
              (tecnica == 'TransferStm' &&
                typeTransferStm == 'TransferSerigraficoStm')
            "
            v-model="repeticion"
            :id-zone="id"
          />
          <span
            v-if="tecnica == 'SerigrafiaStm' && recomiendaBaseSerigrafia"
            class="d-flex"
          >
            <v-checkbox
              v-model="dobleBaseSerigrafia"
              :error="!dobleBaseSerigrafia"
              :label="$t('configuracion.Serigrafia.CheckBasePrendasOscuras')"
              color="primary darken-2 white--text"
              class="mt-0 py-0 mb-3 warning-text"
              hide-details="true"
            ></v-checkbox>
          </span>
          <div v-if="tecnica == 'BordadoStm'" class="d-flex">
            <v-checkbox
              v-model="cortahilos"
              :label="$t('configuracion.Bordado.cortahilos')"
              color="primary darken-2 white--text"
              class="mt-0 py-0 mb-3"
              hide-details="true"
            ></v-checkbox>
          </div>
          <div
            v-if="tecnica == 'Tampografia' && !isSerigrafiaTampografiaParasol"
          >
            <v-select
              v-model="selectedTinta"
              :items="tintasSerigrafia"
              filled
              :label="$t('configuracion.Tintas.Tipo de tinta')"
              color="primary darken-2"
              prepend-inner-icon="mdi-water-outline"
              class="pb-0 pt-0"
              hide-details="true"
            ></v-select>
          </div>
        </template>
        <div v-if="hasPersonalizacionNombreANombre">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" class="d-flex" v-on="on">
                <!-- Check para la personalización nombre a nombre -->
                <generic-check
                  v-model="nombreANombre"
                  :checkbox-label="$t('general.NombreANombre')"
                />
              </div>
            </template>
            <span>
              {{ $t("general.NombreANombreDescripcion") }}
            </span>
          </v-tooltip>
        </div>
        <div v-if="hasAmbasTiras">
          <generic-check
            v-model="ambasTiras"
            :checkbox-label="$t('configuracion.CalculoPrecios.EN_AMBAS_TIRAS')"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CheckRepeticion from "@/components/basic/CheckRepeticion.vue";
import GenericCheck from "@/components/basic/GenericCheck.vue";

export default {
  name: "CalculoPrecio",
  components: {
    CheckRepeticion,
    GenericCheck
  },
  props: {
    cantidad: Number,
    color: Object,
    zona: Object,
    maxColores: {
      type: Number,
      default: 8
    },
    tecnica: {
      type: String,
      default: ""
    },
    id: Number
  },
  data() {
    return {
      colores: 1,
      repeticion: false,
      cargando: false,
      puntadas: 1,
      caras: 1,
      digitalTam: [],
      digitalTamCode: "",

      // Sublimación
      tamaño: 1,
      altoTamanoCm2: 0,
      anchoTamanoCm2: 0,

      debounceCalcularPrecio: null,
      typeTransferStm: undefined,
      domingTamCode: "",
      dobleBaseSerigrafia: false,
      typeTermograbado: null,
      tintasSerigrafia: [],
      selectedTinta: null,
      gajos: 1,
      transferDigitalSelectedSize: null,
      cortahilos: false,
      nombreANombre: false,
      ambasTiras: false
    };
  },
  computed: {
    ...mapGetters("carrito", ["carrito"]),
    ...mapGetters("modelo", [
      "transferStmTypeAvalible",
      "stmMaxColor",
      "sublimacionStmInfo",
      "zonesFondoByZone",
      "modelPrice",
      "modelInfoFromCatalog",
      "modelCode",

      "getFilteredTecnicasExtra"
    ]),
    ...mapGetters("config", ["isProveedor", "key"]),
    hasAmbasTiras() {
      let isSublimacionNormal =
        this.avalibleSublimacion &&
        !this.isSublimacionTazas &&
        this.numCarasSublimacion == 0 &&
        !this.isSublimacionSombreros;
      return (
        isSublimacionNormal &&
        this.sublimacionStmInfo(this.zona.Posicion)?.EN_AMBAS_TIRAS
      );
    },
    // Propiedad que indica si debemos poner el componente de personalización nombre a nombre
    hasPersonalizacionNombreANombre() {
      let isTransferDigital =
        this.tecnica == "TransferStm" &&
        this.typeTransferStm == "TransferDigitalStm";

      let isSublimacionNormal =
        this.avalibleSublimacion &&
        !this.isSublimacionTazas &&
        this.numCarasSublimacion == 0 &&
        !this.isSublimacionSombreros;

      let isLaser = this.tecnica == "Laser";

      let isDigital = this.tecnica == "Digital";

      return (
        isTransferDigital ||
        this.isSublimacionTazas ||
        isSublimacionNormal ||
        isLaser ||
        isDigital
      );
    },
    maxSizeForTransfer() {
      if (
        this.tecnica != "TransferStm" ||
        !this.zona ||
        this.typeTransferStm != "TransferDigitalStm"
      )
        return [];

      let actualInfo = this.getFilteredTecnicasExtra(
        this.zona.Posicion,
        "Transfer"
      );

      return {
        Area: actualInfo.TamanoCm2,
        Alto: Math.round(actualInfo.Height),
        Ancho: Math.round(actualInfo.Width)
      };
    },
    // Propiedad con los tipos de transfer que tenemos disponibles
    avalibleTransferStmType() {
      if (this.tecnica != "TransferStm" || !this.zona) return [];

      return this.transferStmTypeAvalible(this.zona.Posicion).map(type => {
        return {
          text: this.$t("configuracion.CalculoPrecios." + type),
          value: type
        };
      });
    },
    // Propiedad con los tipos de termograbado que tenemos disponible
    avalibleTermograbadoType() {
      if (this.tecnica != "Termografia" || !this.zona) return [];

      return Object.keys(
        this.getFilteredTecnicasExtra(this.zona.Posicion, "Termograbado")
      ).map(type => {
        return {
          text: this.$t("configuracion.CalculoPrecios.Termografia." + type),
          value: type
        };
      });
    },
    avalibleSizeDoming() {
      if (this.tecnica != "DomingStm" || !this.zona) return [];

      const listSizes = this.getFilteredTecnicasExtra(
        this.zona.Posicion,
        "Doming"
      )?.Sizes;

      if (listSizes == null) return [];

      return Object.keys(listSizes)?.map(item => {
        return {
          value: listSizes[item],
          label: `${listSizes[item]} cm2`,
          code: item
        };
      });
    },
    hasColorSelect() {
      return (
        !this.hasPuntadasInput &&
        this.tecnica != "Laser" &&
        this.tecnica != "Digital" &&
        this.tecnica != "SublimacionStm" &&
        this.tecnica != "DomingStm" &&
        !(
          this.tecnica == "TransferStm" &&
          this.typeTransferStm == "TransferDigitalStm"
        ) &&
        this.tecnica != "Termografia" &&
        !this.isSerigrafiaGajos
      );
    },
    hasCarasSelect() {
      return this.tecnica == "SublimacionStm" && this.numCarasSublimacion > 1;
    },
    hasPuntadasInput() {
      return this.tecnica == "BordadoStm";
    },
    carasarr() {
      if (this.numCarasSublimacion == 0) {
        return [
          {
            text: "1",
            value: 1
          },
          {
            text: "2",
            value: 2
          }
        ];
      }

      if (this.isSublimacionTazas) {
        // Solo el número de caras especificado
        return [
          {
            text: `${this.numCarasSublimacion}`,
            value: Number(this.numCarasSublimacion)
          }
        ];
      }

      let result = [];
      let index = 0;
      while (index < this.numCarasSublimacion) {
        result.push({
          text: (index + 1).toString(),
          value: index + 1
        });
        index++;
      }

      return result;
    },
    avalibleSublimacion() {
      if (this.tecnica != "SublimacionStm") return false;

      let infoZonasFondo = this.zonesFondoByZone(this.zona.Posicion);

      if (this.color && this.color.code == "01") return true;

      let hasSublimacionByColorAndZone =
        infoZonasFondo &&
        infoZonasFondo.AvalibleColors &&
        infoZonasFondo.AvalibleColors.length > 0;

      if (hasSublimacionByColorAndZone && this.color && this.color.code) {
        let actualColor = infoZonasFondo.AvalibleColors.find(
          c => c.Color == this.color.code
        );

        return actualColor?.isBlanco ?? false;
      }

      return false;
    },
    actualFondo() {
      return this.zonesFondoByZone(this.zona.Posicion)?.AvalibleColors;
    },
    isBlanco() {
      let fondoBlanco = false;
      const soloBlancoEnCarrito =
        this.carrito.productos.length >= 1 &&
        this.carrito.productos.every(p => p.color == "01");

      if (soloBlancoEnCarrito) {
        // Si solo hay blanco en el carrito no es necesario mirar más
        fondoBlanco = true;
      } else if (this.actualFondo) {
        // Si no todos los productos son blancos, buscamos si tiene definido un fondo especial
        fondoBlanco =
          this.carrito.productos.length >= 1 &&
          this.carrito.productos.every(p => {
            if (p.color == "01") return true;

            let actualFondoProduct = this.actualFondo.find(
              c => c.Color == p.color
            );
            return actualFondoProduct && actualFondoProduct.isBlanco;
          });
      }

      return fondoBlanco;
    },
    numProductosBlancos() {
      let numBlancos = 0;

      numBlancos = this.carrito.productos.reduce(
        (total, p) =>
          p.color == "01" ||
          (this.zonesFondoByZone(this.zona.Posicion)?.AvalibleColors.find(
            c => c.Color == p.color
          )?.isBlanco ??
            false)
            ? total + Number.parseInt(p.cantidad)
            : total,
        0
      );

      return numBlancos;
    },
    recomiendaBaseSerigrafia() {
      if (this.tecnica != "SerigrafiaStm") return false;

      let serigrafiaInfo = this.getFilteredTecnicasExtra(
        this.zona.Posicion,
        "Serigrafia"
      );

      if (!serigrafiaInfo) return false;

      return serigrafiaInfo?.AplicarBase ?? false;
    },
    numCarasSublimacion() {
      if (this.tecnica != "SublimacionStm" || !this.avalibleSublimacion)
        return 0;

      let sublimacionInfo = this.sublimacionStmInfo(this.zona.Posicion);

      if (!sublimacionInfo) return 0;

      return sublimacionInfo?.NumCaras ?? 0;
    },
    isSublimacionSoloBlanco() {
      if (this.tecnica != "SublimacionStm" || !this.avalibleSublimacion)
        return false;

      let sublimacionInfo = this.sublimacionStmInfo(this.zona.Posicion);

      if (!sublimacionInfo) return false;

      return sublimacionInfo?.SoloBlanco ?? false;
    },
    isSublimacionSombreros() {
      if (this.tecnica != "SublimacionStm" || !this.avalibleSublimacion)
        return false;

      let sublimacionInfo = this.sublimacionStmInfo(this.zona.Posicion);

      if (!sublimacionInfo) return false;

      return sublimacionInfo?.Type == "Sombreros" ?? false;
    },
    isSublimacionTazas() {
      if (this.tecnica != "SublimacionStm" || !this.avalibleSublimacion)
        return false;

      let sublimacionInfo = this.sublimacionStmInfo(this.zona.Posicion);

      if (!sublimacionInfo) return false;

      return sublimacionInfo?.Type == "Tazas" ?? false;
    },
    isSublimacionBloquedCm2() {
      if (this.tecnica != "SublimacionStm" || !this.avalibleSublimacion)
        return false;

      let sublimacionInfo = this.sublimacionStmInfo(this.zona.Posicion);

      if (!sublimacionInfo) return false;

      return sublimacionInfo?.calculateByUnits ?? false;
    },
    isSerigrafiaSombreros() {
      if (this.tecnica != "SerigrafiaStm") return false;

      let serigrafiaInfo = this.getFilteredTecnicasExtra(
        this.zona.Posicion,
        "Serigrafia"
      );

      if (!serigrafiaInfo) return false;

      return serigrafiaInfo?.Type == "Sombreros" ?? false;
    },
    isSerigrafiaTampografiaParasol() {
      if (this.tecnica != "SerigrafiaStm" && this.tecnica != "Tampografia")
        return false;
      let serigrafiaInfo = this.getFilteredTecnicasExtra(
        this.zona.Posicion,
        "Serigrafia"
      );

      if (!serigrafiaInfo) return false;

      return serigrafiaInfo?.Type == "Parasoles" ?? false;
    },
    isSerigrafiaGajos() {
      if (this.tecnica != "SerigrafiaStm") return false;

      let serigrafiaInfo = this.getFilteredTecnicasExtra(
        this.zona.Posicion,
        "Serigrafia"
      );

      if (!serigrafiaInfo) return false;

      return serigrafiaInfo?.Type == "Gajos" ?? false;
    },
    isTransferGajos() {
      if (this.tecnica != "TransferStm") return false;

      let transferInfo = this.getFilteredTecnicasExtra(
        this.zona.Posicion,
        "Transfer"
      );

      if (!transferInfo) return false;

      return transferInfo?.Type == "Gajos" ?? false;
    },
    maxNumGajos() {
      if (!this.isSerigrafiaGajos && !this.isTransferGajos) return 0;

      let tecnica = this.isSerigrafiaGajos ? "Serigrafia" : "Transfer";

      return (
        this.getFilteredTecnicasExtra(this.zona.Posicion, tecnica)?.NumGajos ??
        0
      );
    },
    numGajosDisponibles() {
      let gajos = [];
      for (let i = 1; i <= this.maxNumGajos; i++) {
        gajos.push(i);
      }
      return gajos;
    },
    coloresDisponibles: function () {
      if (!this.hasColorSelect) return [];

      var coloresmaximos = this.maxColores;

      if (this.tecnica == "SerigrafiaStm") {
        let num = this.stmMaxColor(this.zona.Posicion, "Serigrafia");
        if (num) coloresmaximos = parseInt(num);
      } else if (this.tecnica == "Termografia") {
        let listType = this.getFilteredTecnicasExtra(
          this.zona.Posicion,
          "Termografia"
        );
        if (listType)
          coloresmaximos = parseInt(listType[this.typeTermograbado].MaxColor);
      } else if (this.tecnica == "Tampografia") {
        if (this.selectedTinta == 1) coloresmaximos = 1;
        else {
          let num = this.stmMaxColor(this.zona.Posicion, this.tecnica);
          if (num) coloresmaximos = parseInt(num);
        }
      } else {
        let num = this.stmMaxColor(
          this.zona.Posicion,
          this.tecnica.replace("Stm", "")
        );
        if (num) coloresmaximos = parseInt(num);
      }

      let colores = [];
      for (let i = 1; i <= coloresmaximos; i++) {
        colores.push(i);
      }
      return colores;
    },
    digitalTamValue() {
      if (this.tecnica != "Digital") return 0;
      if (this.digitalTamCode)
        return this.digitalTam.find(item => item.code == this.digitalTamCode)
          ?.value;
      return 0;
    },
    maxSizeForSublimacion() {
      if (
        this.tecnica == "SublimacionStm" &&
        this.avalibleSublimacion &&
        !this.isSublimacionTazas &&
        // !this.modelCode.startsWith("GO") &&
        this.numCarasSublimacion == 0
      ) {
        var listTallas = this.carrito.productos.map(p => p.talla);

        var listProduct = this.modelPrice.tallasInfo.filter(t =>
          listTallas.includes(t.Talla)
        );

        var productosSelect = [];

        listProduct.forEach(x => {
          productosSelect.push(
            x.Posiciones.find(
              p => this.zona && p.Posicion == this.zona.Posicion
            )
          );
        });

        // Obtener el área más pequeña
        let smallestArea = Infinity;
        let ancho = 0;
        let alto = 0;
        productosSelect.forEach(producto => {
          if (ancho == 0 || alto == 0) {
            ancho = producto.Ancho;
            alto = producto.Alto;
          }

          const area = producto.Ancho * producto.Alto;
          if (area < smallestArea) {
            smallestArea = area;
            ancho = producto.Ancho;
            alto = producto.Alto;
          }
        });

        let sublimacionInfo = this.sublimacionStmInfo(this.zona.Posicion);

        let tamanoCm2 = sublimacionInfo.TamanoCm2 * 100;

        if (
          sublimacionInfo &&
          ((sublimacionInfo.TamanoCm2 && tamanoCm2 < smallestArea) ||
            (sublimacionInfo.Height && sublimacionInfo.Width))
        ) {
          smallestArea = tamanoCm2;

          let alturaObjetivo = 0;

          let anchoObjetivo = 0;

          if (!sublimacionInfo.Height || !sublimacionInfo.Width) {
            alturaObjetivo = Math.min(
              Math.sqrt((smallestArea * alto) / ancho),
              alto
            );

            // Calcula la anchura usando el valor objetivo de la altura
            anchoObjetivo = smallestArea / alturaObjetivo;
          } else {
            alturaObjetivo = sublimacionInfo.Height;
            anchoObjetivo = sublimacionInfo.Width;
          }

          return {
            Area: smallestArea / 100,
            Alto: Math.round(alturaObjetivo),
            Ancho: Math.round(anchoObjetivo)
          };
        }

        // Convertir el área a centímetros
        return {
          Area: smallestArea / 100,
          Alto: alto,
          Ancho: ancho
        };
      }

      return null;
    },
    maxDigitalTam() {
      if (this.tecnica != "Digital") return null;
      var listTallas = this.carrito.productos.map(p => p.talla);

      var listProduct = this.modelPrice.tallasInfo.filter(t =>
        listTallas.includes(t.Talla)
      );

      var productosSelect = [];

      listProduct.forEach(x => {
        productosSelect.push(
          x.Posiciones.find(p => this.zona && p.Posicion == this.zona.Posicion)
        );
      });

      let maxDigital = null;
      let anterior = null;
      this.digitalTam.forEach(t => {
        if (
          (!maxDigital || maxDigital < t.value) &&
          productosSelect.every(
            p =>
              (anterior == null ||
                (p.Ancho / 10) * (p.Alto / 10) > anterior.value) &&
              (p.Ancho / 10) * (p.Alto / 10) <= t.value
          )
        )
          maxDigital = t.value;
        anterior = t;
      });

      return maxDigital;
    }
  },
  watch: {
    cortahilos: "chequeoTamaño",
    gajos: "chequeoTamaño",
    colores: "chequeoTamaño",
    caras: "chequeoTamaño",
    puntadas: "chequeoTamaño",
    tamaño: "chequeoTamaño",
    cantidad: "chequeoTamaño",
    repeticion: "chequeoTamaño",
    nombreANombre: "chequeoTamaño",
    ambasTiras: "chequeoTamaño",
    maxSizeForTransfer: function (newVal) {
      if (
        this.tecnica == "TransferStm" &&
        this.typeTransferStm == "TransferDigitalStm"
      ) {
        this.tamaño = newVal.Area ?? 1;
        this.anchoTamanoCm2 = newVal.Ancho ?? 1;
        this.altoTamanoCm2 = newVal.Alto ?? 1;
      }
    },
    maxSizeForSublimacion: function (newVal, oldVal) {
      if (newVal && oldVal) {
        let sameArea = newVal.Area == oldVal.Area;
        let sameAlto = newVal.Alto == oldVal.Alto;
        let sameAncho = newVal.Ancho == oldVal.Ancho;
        if (sameArea && sameAlto && sameAncho) return;
      }

      if (
        this.tecnica == "SublimacionStm" &&
        this.avalibleSublimacion &&
        !this.isSublimacionTazas &&
        // !this.modelCode.startsWith("GO") &&
        this.numCarasSublimacion == 0
      ) {
        this.tamaño = newVal.Area ?? 1;
        this.anchoTamanoCm2 = newVal.Ancho ?? 1;
        this.altoTamanoCm2 = newVal.Alto ?? 1;
      }
    },
    selectedTinta: function (newVal, oldVal) {
      if (newVal == oldVal) return;

      if (newVal == 1) this.colores = 1;

      this.chequeoTamaño();
    },
    numProductosBlancos: {
      handler: function (newVal) {
        if (this.tecnica == "SublimacionStm") {
          if (newVal > 0) {
            this.chequeoTamaño();
          } else {
            this.deleteReproceso({
              id: this.id,
              modelo: this.modelInfoFromCatalog.code,
              zona: this.zona.Zona
            });
          }
        }
      },
      immediate: true
    },
    modelo: async function () {
      await this.loadDigitalTam();
      await this.chequeoTamaño();
    },
    tecnica: async function (newVal) {
      if (newVal == "Digital") {
        await this.loadDigitalTam();
        this.digitalTamCode = this.digitalTam.find(
          t => t.value == this.maxDigitalTam
        )?.code;
      }
      await this.chequeoTamaño();

      if (
        newVal == "SublimacionStm" &&
        this.avalibleSublimacion &&
        !this.isSublimacionTazas &&
        // !this.modelCode.startsWith("GO") &&
        this.numCarasSublimacion == 0
      ) {
        this.tamaño = this.maxSizeForSublimacion.Area ?? 1;
        this.anchoTamanoCm2 = this.maxSizeForSublimacion.Ancho ?? 1;
        this.altoTamanoCm2 = this.maxSizeForSublimacion.Alto ?? 1;
      } else if (
        this.tecnica == "TransferStm" &&
        this.typeTransferStm == "TransferDigitalStm"
      ) {
        this.tamaño = this.maxSizeForTransfer.Area ?? 1;
        this.anchoTamanoCm2 = this.maxSizeForTransfer.Ancho ?? 1;
        this.altoTamanoCm2 = this.maxSizeForTransfer.Alto ?? 1;
      }
    },
    carasarr: function (newVal) {
      if (!newVal.some(c => c.value == this.caras) && newVal.length > 0) {
        this.caras = this.carasarr[0].value;
      }
    },
    zona: async function () {
      await this.loadDigitalTam();
      await this.chequeoTamaño();
    },
    dobleBaseSerigrafia: "chequeoTamaño",
    isProveedor: function () {
      this.chequeoTamaño();
    },
    digitalTamCode: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.chequeoTamaño();
        // this.calcularPrecio();
      }
    },
    avalibleTransferStmType: function (newVal) {
      if (
        (!this.typeTransferStm && newVal.length > 0) ||
        newVal.some(t => t.value != this.typeTransferStm)
      ) {
        this.typeTransferStm = newVal[0]?.value;
      }
    },
    avalibleTermograbadoType: function (newVal) {
      if (
        (!this.typeTermograbado && newVal.length > 0) ||
        newVal.some(t => t.value != this.typeTermograbado)
      ) {
        this.typeTermograbado = newVal[0]?.value;
      }
    },
    typeTransferStm(newVal) {
      this.colores = 1;
      this.chequeoTamaño();

      if (newVal == "TransferDigitalStm") {
        this.tamaño = this.maxSizeForTransfer.Area ?? 1;
        this.anchoTamanoCm2 = this.maxSizeForTransfer.Ancho ?? 1;
        this.altoTamanoCm2 = this.maxSizeForTransfer.Alto ?? 1;
      }
    },
    transferDigitalSelectedSize() {
      this.chequeoTamaño();
    },
    typeTermograbado() {
      this.colores = 1;
      this.chequeoTamaño();
    },
    key() {
      this.colores = 1;
      this.chequeoTamaño();
    },
    domingTamCode() {
      this.chequeoTamaño();
    },
    avalibleSizeDoming: {
      handler(newVal, oldVal) {
        // Checking if the value of the domingTamCode is in the array of domingTams. If it is not, it
        // sets the domingTamCode to the first value in the array.
        if (newVal != oldVal) {
          let result = newVal.find(v => v.code == this.domingTamCode);
          if (!result && newVal.length > 0) this.domingTamCode = newVal[0].code;
        }
      },
      immediate: true
    }
  },
  async created() {
    if (this.tecnica && this.tecnica == "Digital") {
      await this.loadDigitalTam();
      this.digitalTamCode = this.digitalTam.find(
        t => t.value == this.maxDigitalTam
      )?.code;
    } else if (
      this.tecnica &&
      this.recnica == "DomingStm" &&
      this.avalibleSizeDoming?.length > 0
    ) {
      this.domingTamCode = this.avalibleSizeDoming[0].code;
    }
    if (this.avalibleTransferStmType?.length > 0) {
      this.typeTransferStm = this.avalibleTransferStmType[0]?.value;
    }
    if (this.avalibleTermograbadoType?.length > 0) {
      this.typeTermograbado = this.avalibleTermograbadoType[0]?.value;
    }
    if (
      this.tecnica == "SublimacionStm" &&
      this.avalibleSublimacion &&
      !this.isSublimacionTazas &&
      // !this.modelCode.startsWith("GO") &&
      this.numCarasSublimacion == 0
    ) {
      this.tamaño = this.maxSizeForSublimacion.Area ?? 1;
      this.anchoTamanoCm2 = this.maxSizeForSublimacion.Ancho ?? 1;
      this.altoTamanoCm2 = this.maxSizeForSublimacion.Alto ?? 1;
    } else if (
      this.tecnica == "TransferStm" &&
      this.typeTransferStm == "TransferDigitalStm"
    ) {
      this.tamaño = this.maxSizeForTransfer.Area ?? 1;
      this.anchoTamanoCm2 = this.maxSizeForTransfer.Ancho ?? 1;
      this.altoTamanoCm2 = this.maxSizeForTransfer.Alto ?? 1;
    }

    this.tintasSerigrafia = this.tintasDisponibles();

    this.selectedTinta = 5;

    await this.chequeoTamaño();
  },
  methods: {
    ...mapActions("carrito", ["calcularPrecios", "deleteReproceso"]),
    ...mapActions("modelo", ["fetchSizeDigital", "addAlert"]),
    updateSizeCm2(value, type) {
      let maxSizeTecnica =
        this.tecnica == "SublimacionStm"
          ? this.maxSizeForSublimacion
          : this.maxSizeForTransfer;
      // Si es de type Area, actualizamos el alto y ancho, siempre que no supere el maximo
      if (type == "Area" && value <= maxSizeTecnica.Area) {
        // Pasamos de cm2 a mm2
        value *= 100;

        const alturaObjetivo = Math.min(
          Math.sqrt((value * maxSizeTecnica.Alto) / maxSizeTecnica.Ancho),
          maxSizeTecnica.Alto
        );

        // Calcula la anchura usando el valor objetivo de la altura
        const anchoObjetivo = value / alturaObjetivo;

        // Redondea los valores al entero más cercano
        this.altoTamanoCm2 = Math.round(alturaObjetivo);
        this.anchoTamanoCm2 = Math.round(anchoObjetivo);

        this.tamaño = Math.round(value / 100);
      } else if (type == "Alto" && value <= maxSizeTecnica.Alto) {
        // Calculamos el area y comprobamos que no nos pasemos del máximo
        const area = (value * this.anchoTamanoCm2) / 100;

        if (area <= maxSizeTecnica.Area) {
          this.tamaño = area;
          this.altoTamanoCm2 = value;
        } else {
          this.updateSizeCm2(maxSizeTecnica.Area, "Area");
        }
      } else if (type == "Ancho" && value <= maxSizeTecnica.Ancho) {
        // Calculamos el area y comprobamos que no nos pasemos del máximo
        const area = (value * this.altoTamanoCm2) / 100;

        if (area <= maxSizeTecnica.Area) {
          this.tamaño = area;
          this.anchoTamanoCm2 = value;
        } else {
          this.updateSizeCm2(maxSizeTecnica.Area, "Area");
        }
      } else {
        this.updateSizeCm2(maxSizeTecnica.Area, "Area");
      }
    },
    tintasDisponibles() {
      return [
        {
          text: this.$t("configuracion.Tintas.Tinta5"),
          value: 5
        },
        {
          text: this.$t("configuracion.Tintas.Tintas especiales STM"),
          value: 1
        }
      ];
    },
    async loadDigitalTam() {
      if (this.tecnica && this.tecnica == "Digital") {
        let result = await this.fetchSizeDigital({
          model: this.modelCode,
          posicion: this.zona.Posicion
        });
        if (result.data.tamanos)
          this.digitalTam = result.data.tamanos.map(item => {
            return {
              value: item.value,
              label: `- ${item.value} cm2`,
              code: item.id
            };
          });
      }
    },
    calcularPrecioActual: async function () {
      if (this.cantidad == 0) return;

      let generic_sobrecoste = [];

      // Si es sublimación debemos asegurarnos de que se permite la sublimación. Si no es sublimación esta condición no debe afectar
      let notSublimacionAvalible =
        this.tecnica == "SublimacionStm" && !this.avalibleSublimacion;

      if (notSublimacionAvalible) return;

      if (this.debounceCalcularPrecio) {
        clearTimeout(this.debounceCalcularPrecio);
      }

      this.debounceCalcularPrecio = setTimeout(async () => {
        let atributos = [
          {
            isProveedor: this.isProveedor
          }
        ];

        if (
          this.tecnica == "SublimacionStm" &&
          this.avalibleSublimacion &&
          !this.isSublimacionTazas &&
          // !this.modelCode.startsWith("GO") &&
          this.numCarasSublimacion == 0
        ) {
          atributos[0].tamanoCm2 = this.tamaño;
        }

        if (this.tecnica == "Digital") {
          atributos[0].tamanocm2Digital = this.digitalTamCode
            ? this.digitalTamCode
            : this.digitalTam[0]?.code;
        }

        if (this.tecnica == "DomingStm") {
          atributos[0].tamanoCm2Doming = this.domingTamCode
            ? this.domingTamCode
            : this.avalibleSizeDoming[0]?.code;
        }

        if (
          this.tecnica == "SublimacionStm" &&
          this.avalibleSublimacion &&
          (this.isSublimacionTazas || this.numCarasSublimacion > 0)
        ) {
          atributos[0].caras = this.caras;
        }

        if (
          this.tecnica != "BordadoStm" &&
          this.tecnica != "Laser" &&
          this.tecnica != "Digital" &&
          this.tecnica != "SublimacionStm" &&
          this.tecnica != "DomingStm" &&
          !(
            this.tecnica == "TransferStm" &&
            this.typeTransferStm == "TransferDigitalStm"
          )
        ) {
          atributos[0].colores = this.colores;
        }

        if (this.tecnica == "BordadoStm") {
          atributos[0].puntadas = this.puntadas;
          atributos[0].cortahilos = this.cortahilos;
        }

        if (this.tecnica == "SerigrafiaStm") {
          atributos[0].tipo = "S";
          atributos[0].dobleBaseSerigrafia = this.recomiendaBaseSerigrafia
            ? this.dobleBaseSerigrafia
            : false;

          if (this.isSerigrafiaGajos) {
            atributos[0].gajos = this.gajos;
          }
        }

        if (this.tecnica == "Tampografia") {
          atributos[0].idTipoTinta = this.selectedTinta;
        }

        if (this.tecnica == "TransferStm") {
          if (this.isTransferGajos) {
            atributos[0].gajos = this.gajos;
          }

          if (this.typeTransferStm == "TransferDigitalStm") {
            atributos[0].isTransferDigital = true;

            atributos[0].maxHeight = this.altoTamanoCm2;
            atributos[0].maxWidth = this.anchoTamanoCm2;
          } else if (this.typeTransferStm == "TransferSerigraficoStm") {
            atributos[0].fondoOscuro = false;
            atributos[0].isTransferSerigrafico = true;
            if (!this.isBlanco) {
              atributos[0].fondoOscuro = true;
              atributos[0].colores++;
            }
          }
        }

        if (this.tecnica == "Termografia") {
          atributos[0].typeTermograbado = this.typeTermograbado;
        }

        if (this.hasPersonalizacionNombreANombre) {
          atributos[0].nombreANombre = this.nombreANombre;
        }

        if (this.ambasTiras) {
          generic_sobrecoste.push("EN_AMBAS_TIRAS");
        }

        if (generic_sobrecoste.length > 0) {
          atributos[0].generic_sobrecoste = JSON.stringify(generic_sobrecoste);
        }

        let parametros = {
          // Generales
          tecnica: this.tecnica,
          posicion: this.zona.Posicion,
          zona: this.zona.Zona,
          codigo: this.modelCode,
          isRepeticion: this.repeticion,
          unidades:
            this.tecnica == "SublimacionStm"
              ? this.numProductosBlancos
              : this.cantidad,

          // Especificos
          atributos: JSON.stringify(atributos),
          type: this.tecnica == "TransferStm" ? this.typeTransferStm : "",

          // identificador
          id: this.id
        };

        this.cargando = true;
        try {
          await this.calcularPrecios(parametros);
        } catch (error) {
          if (error != "errorColorBanned") {
            console.error(error);
            alert(this.$t("general.Error al calcular precios"));
          }
        }

        this.cargando = false;
      }, 1000);
    },
    chequeoTamaño: async function () {
      let notMinUnit = false;
      if (
        (this.tecnica == "SublimacionStm" && this.isSublimacionSombreros) ||
        (this.tecnica == "SerigrafiaStm" && this.isSerigrafiaSombreros)
      ) {
        let tecnicaInfo = null;

        if (this.tecnica == "SublimacionStm") {
          tecnicaInfo = this.sublimacionStmInfo(this.zona.Posicion);
        } else if (this.tecnica == "SerigrafiaStm") {
          tecnicaInfo = this.getFilteredTecnicasExtra(
            this.zona.Posicion,
            "Serigrafia"
          );
        }

        if (this.cantidad < tecnicaInfo?.MinUnit ?? 0) {
          this.addAlert({
            title: this.$t("alert.errorMinUnitTitleDialog", {
              modelo: this.modelCode
            }),
            code: "errorMinUnit",
            minUnit: tecnicaInfo?.MinUnit ?? 0
          });

          notMinUnit = true;
        }
      }

      if (this.maxSizeForSublimacion && !notMinUnit) {
        var listTallas = this.carrito.productos.map(p => p.talla);

        var listProduct = this.modelPrice.tallasInfo.filter(t =>
          listTallas.includes(t.Talla)
        );

        var productosSelect = [];
        listProduct.forEach(x => {
          productosSelect.push(
            x.Posiciones.find(
              p => this.zona && p.Posicion == this.zona.Posicion
            )
          );
        });

        let checkSublimacionSize =
          this.tecnica == "SublimacionStm" &&
          this.avalibleSublimacion &&
          !this.isSublimacionTazas &&
          !this.modelCode.startsWith("GO") &&
          this.numCarasSublimacion == 0 &&
          this.tamaño > this.maxSizeForSublimacion.Area;

        let minSize = productosSelect.filter(
          p =>
            (p.Ancho / 10) * (p.Alto / 10) <
            (checkSublimacionSize ? this.tamaño : this.digitalTamValue)
        );

        if ((minSize && minSize.length > 0) || checkSublimacionSize) {
          this.addAlert({
            title:
              this.$t("alert.errorTamEstampacionTitleDialog") +
              " (" +
              (checkSublimacionSize
                ? this.$t("Tecnicas.SUBLIMACIÓN")
                : this.$t("Tecnicas.DIGITAL")) +
              ")",
            listProduct: minSize,
            code: "errorTamEstampacion",
            zone: this.zona,
            tamEstampacion: checkSublimacionSize
              ? this.tamaño
              : this.digitalTamValue
          });
        } else {
          if (!notMinUnit) await this.calcularPrecioActual();
        }
      } else if (!notMinUnit) await this.calcularPrecioActual();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
