import axios from "axios";

// Función encargada de calcular el coste por cantidad exacta. El elemento payload debe conteer el campo listReprocesos
export const CalcularPrecioCantidadesExactas = ({ payload, divisa, isProveedor, language }) => {
    return axios.post(`${process.env.VUE_APP_API_REPROCESOS}/Reprocesos/CalcularCantidadesExactas`, payload, {
        params: {
            divisa,
            isProveedor,
            language
        },
        headers: {
            "Content-Type": "text/json",
        },
    })
};


// Función para carlcular una técnica cualquiera
export const CalcularTecnica = (payload) => {
    return axios
        .get(`${process.env.VUE_APP_API_REPROCESOS}/Reprocesos/CalcularPrecios`, {
            params: payload,
        });
};

// Función para recuperar toda la información de un modelo
export const FetchModelo = ({ modelCode, reprocesosKey, language }) => {
    if (!modelCode || !reprocesosKey) return Promise.reject(new Error(`Missing parameters in FetchModelo function`));

    return axios
        .get(`${process.env.VUE_APP_API_REPROCESOS}/Info/Modelo/${modelCode}`, {
            params: {
                modelCode,
                Key: reprocesosKey,
                language
            },
        });
}

// Función para recuperar el catalogo completo de Azure
export const FetchCatalog = () => {
    const blob = process.env.VUE_APP_BLOB_URL;
    return axios
        .get(`${process.env.VUE_APP_STATIC_IMG_URL}/${blob}/Catalog/catalog_info.json`)
}

// Función para recuperar el fondo de los distintos colores
export const FetchFondoColor = () => {
    return axios.get(`${process.env.VUE_APP_API_REPROCESOS}/Info/Colors`)
}

// Función para obtener las manipulaciones del modelo actual
export const FetchManipulados = ({ modelCode, language }) => {
    if (!modelCode) return Promise.reject(new Error(`Missing parameters in FetchManipulados function`));

    if (!language) language = "es-ES";

    return axios.get(`${process.env.VUE_APP_API_REPROCESOS}/Info/Modelo/${modelCode}/Manipulados`, {
        params: {
            language
        }
    })
}

// Función para obtener los tamaños para sublimación dado un modelo
export const FetchSublimacionSizes = ({ modelCode }) => {
    if (!modelCode) return Promise.reject(new Error(`Missing parameters in FetchSublimacionSizes function`));

    return axios.get(`${process.env.VUE_APP_API_REPROCESOS}/Info/Sublimacion/Size`,
        {
            params: {
                modelCode
            }
        })
}

// Función para obtener los tamaños para números DTF
export const FetchDtfNumerosSizes = () => {
    return axios.get(`${process.env.VUE_APP_API_REPROCESOS}/Info/DtfNumeros/Size`)
}

// Función para obtener el tamaño para numeros transfer
export const FetchTransferNumerosSizes = () => {
    return axios
        .get(
            `${process.env.VUE_APP_API_REPROCESOS}/Info/TransferNumeros/Size`
        );
}
